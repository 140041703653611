/********************************************************************************/

[css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"] {

              display: flex;
              gap: var(--space--14);
              justify-content: center;
              padding: var(--space--20) var(--space--8);
              align-items: center;
            }

@media (max-width: 959px) {

[css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"][css~="bbjhekzovchsyr"] {
                flex-direction: column
            }
              }

/********************************************************************************/

[css~="cpgjrisbhziyix"][css~="cpgjrisbhziyix"][css~="cpgjrisbhziyix"][css~="cpgjrisbhziyix"][css~="cpgjrisbhziyix"][css~="cpgjrisbhziyix"] {

                display: flex;
                flex-direction: column;
                gap: var(--space--4);
                max-width: var(--width--prose);
                align-items: center;
              }

/********************************************************************************/

[css~="czyptiizlkxpqp"][css~="czyptiizlkxpqp"][css~="czyptiizlkxpqp"][css~="czyptiizlkxpqp"][css~="czyptiizlkxpqp"][css~="czyptiizlkxpqp"] {

                  font-size: var(--font-size--5xl);
                  line-height: var(--line-height--5xl);
                  font-weight: var(--font-weight--black);
                  align-items: center;
                }

/********************************************************************************/

[css~="ehdvveaepuwtcb"][css~="ehdvveaepuwtcb"][css~="ehdvveaepuwtcb"][css~="ehdvveaepuwtcb"][css~="ehdvveaepuwtcb"][css~="ehdvveaepuwtcb"] {

                  font-size: var(--font-size--lg);
                  line-height: var(--line-height--lg);
                  font-weight: var(--font-weight--bold);
                }

/********************************************************************************/

[css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"] {

                  display: flex;
                  flex-direction: column;
                  gap: var(--space--4);
                }

[css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"] > * {
                    display: flex;
                    gap: var(--space--4);
                  }

[css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"] > * > * {
                      flex: 1;
                    }

@media (max-width: 459px) {

[css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"][css~="glgpauxiirvurc"] > * {
                      flex-direction: column
                  }
                    }

/********************************************************************************/

[css~="eesaeziazzgdgz"][css~="eesaeziazzgdgz"][css~="eesaeziazzgdgz"][css~="eesaeziazzgdgz"][css~="eesaeziazzgdgz"][css~="eesaeziazzgdgz"] {

                      align-items: center;
                    }

/********************************************************************************/

[css~="depsgdkvuhrsdy"][css~="depsgdkvuhrsdy"][css~="depsgdkvuhrsdy"][css~="depsgdkvuhrsdy"][css~="depsgdkvuhrsdy"][css~="depsgdkvuhrsdy"] {

                max-width: var(--width--3xl);
              }

/********************************************************************************/

[css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"] {

                  background-color: transparent;
                  border: var(--border-width--1) solid var(--color--zinc--200);
                }

@media (prefers-color-scheme: dark) {

[css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"][css~="docjppbhwtqdxf"] {
                    border-color: var(--color--zinc--700)
                }
                  }

/********************************************************************************/

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] {

              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] {
                background-color: var(--color--zinc--800)
            }
              }

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] {
              padding: var(--space--32) var(--space--8);
              display: flex;
              justify-content: center;
}

@media (max-width: 1149px) {

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] {
                flex-direction: column;
                align-items: center;
                gap: var(--space--14)
            }
              }

@media (min-width: 1150px) {

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] {
                gap: var(--space--8)
            }
              }

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] > * {
                flex: 1;
                max-width: var(--width--sm);
                display: flex;
                flex-direction: column;
                gap: var(--space--4);
                align-items: center;
                text-align: center;
              }

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] > * > :first-child {
                  display: flex;
                  gap: var(--space--4);
                  align-items: center;
                }

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] > * > :first-child > :first-child {
                    border-radius: var(--border-radius--circle);
                    width: var(--space--10);
                    height: var(--space--10);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: var(--font-size--xl);
                    line-height: var(--line-height--xl);
                  }

[css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"][css~="biadxaqmpkdtaz"] > * > :first-child > :last-child {
                    font-size: var(--font-size--3xl);
                    line-height: var(--line-height--3xl);
                    font-weight: var(--font-weight--black);
                    text-align: left;
                  }

/********************************************************************************/

[css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"] {

                    color: var(--color--violet--700);
                    background-color: var(--color--violet--200);
                  }

@media (prefers-color-scheme: dark) {

[css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"][css~="ecrluralbzuted"] {
                      color: var(--color--violet--200);
                      background-color: var(--color--violet--700)
                  }
                    }

/********************************************************************************/

[css~="gcqvhqmelxiayo"][css~="gcqvhqmelxiayo"][css~="gcqvhqmelxiayo"][css~="gcqvhqmelxiayo"][css~="gcqvhqmelxiayo"][css~="gcqvhqmelxiayo"] {

                      margin-left: var(--space--0-5);
                    }

/********************************************************************************/

[css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"] {

                    color: var(--color--sky--700);
                    background-color: var(--color--sky--200);
                  }

@media (prefers-color-scheme: dark) {

[css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"][css~="gkdkprjnejqwtv"] {
                      color: var(--color--sky--200);
                      background-color: var(--color--sky--700)
                  }
                    }

/********************************************************************************/

[css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"] {

                    color: var(--color--green--700);
                    background-color: var(--color--green--200);
                  }

@media (prefers-color-scheme: dark) {

[css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"][css~="glhkdquqwepvnb"] {
                      color: var(--color--green--200);
                      background-color: var(--color--green--700)
                  }
                    }

/********************************************************************************/

[css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"] {

              display: flex;
              gap: var(--space--14);
              justify-content: center;
              padding: var(--space--32) var(--space--8);
              align-items: center;
            }

@media (max-width: 889px) {

[css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"] {
                flex-direction: column
            }
              }

@media (min-width: 890px) {

[css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"][css~="hiydrouysvdgob"] {
                flex-direction: row-reverse
            }
              }

/********************************************************************************/

[css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"] {

              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

[css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"] {
                background-color: var(--color--zinc--800)
            }
              }

[css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"] {
              display: flex;
              justify-content: center;
              padding: var(--space--32) var(--space--8);
              align-items: center;
}

@media (max-width: 889px) {

[css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"] {
                flex-direction: column;
                gap: var(--space--14)
            }
              }

@media (min-width: 890px) {

[css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"][css~="fdagvslrazffiv"] {
                gap: var(--space--24)
            }
              }

/********************************************************************************/

[css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"] {

              display: flex;
              gap: var(--space--14);
              justify-content: center;
              padding: var(--space--36) var(--space--8);
              align-items: center;
            }

@media (max-width: 859px) {

[css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"] {
                flex-direction: column
            }
              }

@media (min-width: 860px) {

[css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"][css~="zxkukrvkvxqwf"] {
                flex-direction: row-reverse
            }
              }

/********************************************************************************/

[css~="boedkhfjxaivgm"][css~="boedkhfjxaivgm"][css~="boedkhfjxaivgm"][css~="boedkhfjxaivgm"][css~="boedkhfjxaivgm"][css~="boedkhfjxaivgm"] {

                    font-size: var(--font-size--base);
                    line-height: var(--line-height--base);
                  }

/********************************************************************************/

[css~="ctpszigvoxxzmb"][css~="ctpszigvoxxzmb"][css~="ctpszigvoxxzmb"][css~="ctpszigvoxxzmb"][css~="ctpszigvoxxzmb"][css~="ctpszigvoxxzmb"] {

                flex: 1;
                max-width: calc(min(var(--width--xl), 100%));
              }

/********************************************************************************/

[css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"] {

              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

[css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"] {
                background-color: var(--color--zinc--800);
            }
              }

[css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"][css~="hebopawyrmwzpy"] {
              display: flex;
              flex-direction: column;
              padding: var(--space--32) var(--space--8);
              align-items: center;
              gap: var(--space--14);
}

/********************************************************************************/

[css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"] {

                display: flex;
                gap: var(--space--14);
                flex-direction: column;
              }

[css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"] > a {
                  display: flex;
                  gap: var(--space--4);
                  align-items: center;
                  max-width: var(--width--sm);
                }

[css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"] > a > img {
                    background-color: transparent;
                    width: var(--space--24);
                    border-radius: var(--border-radius--circle);
                  }

[css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"][css~="fhpqjmwbrxekpt"] > a > p {
                    flex: 1;
                  }

/********************************************************************************/

[css~="higdipuhzhinbf"][css~="higdipuhzhinbf"][css~="higdipuhzhinbf"][css~="higdipuhzhinbf"][css~="higdipuhzhinbf"][css~="higdipuhzhinbf"] {

                  font-size: var(--font-size--lg);
                  line-height: var(--line-height--lg);
                  font-weight: var(--font-weight--bold);
                  text-align: center;
                  margin-bottom: var(--space---12);
                }

/********************************************************************************/

[css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"] {

              font-size: var(--font-size--xl);
              line-height: var(--line-height--xl);
              font-weight: var(--font-weight--bold);
              display: flex;
              gap: var(--space--8);
              justify-content: center;
              padding: var(--space--36) var(--space--8);
              align-items: center;
            }

@media (max-width: 1159px) {

[css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"][css~="gvaevjxufsaxml"] {
                flex-direction: column
            }
              }

/********************************************************************************/

[css~="brfrlvqmcacxmm"][css~="brfrlvqmcacxmm"][css~="brfrlvqmcacxmm"][css~="brfrlvqmcacxmm"][css~="brfrlvqmcacxmm"][css~="brfrlvqmcacxmm"] {

                align-items: center;
              }

/********************************************************************************/

[css~="zeidhzyhlerui"][css~="zeidhzyhlerui"][css~="zeidhzyhlerui"][css~="zeidhzyhlerui"][css~="zeidhzyhlerui"][css~="zeidhzyhlerui"] {

              display: flex;
              flex-direction: column;
              gap: var(--space--4);
            }

/********************************************************************************/

[css~="haevstketltlsu"][css~="haevstketltlsu"][css~="haevstketltlsu"][css~="haevstketltlsu"][css~="haevstketltlsu"][css~="haevstketltlsu"] {

                  display: flex;
                }

/********************************************************************************/

[css~="sirlwrgxvqdfb"][css~="sirlwrgxvqdfb"][css~="sirlwrgxvqdfb"][css~="sirlwrgxvqdfb"][css~="sirlwrgxvqdfb"][css~="sirlwrgxvqdfb"] {

              display: flex;
              gap: var(--space--2);
              align-items: baseline;
            }

/********************************************************************************/

[css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"] {

                  padding-top: var(--space--2);
                  border-top: var(--border-width--1) solid
                    var(--color--zinc--200);
                }

@media (prefers-color-scheme: dark) {

[css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"] {
                    border-color: var(--color--zinc--700);
                }
                  }

[css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"][css~="heeljmfzervfpc"] {
                  display: flex;
                  gap: var(--space--2);
}

/********************************************************************************/

[css~="gzzksgmddhdplj"][css~="gzzksgmddhdplj"][css~="gzzksgmddhdplj"][css~="gzzksgmddhdplj"][css~="gzzksgmddhdplj"][css~="gzzksgmddhdplj"] {

                    flex: 1;
                    margin-top: var(--space--0-5);
                    display: flex;
                    flex-direction: column;
                    gap: var(--space--2);
                    min-width: var(--space--0);
                  }

/********************************************************************************/

[css~="fjtulsikdrcuaq"][css~="fjtulsikdrcuaq"][css~="fjtulsikdrcuaq"][css~="fjtulsikdrcuaq"][css~="fjtulsikdrcuaq"][css~="fjtulsikdrcuaq"] {

                          margin-right: var(--space--2);
                        }

/********************************************************************************/

[css~="frizmefofavpcy"][css~="frizmefofavpcy"][css~="frizmefofavpcy"][css~="frizmefofavpcy"][css~="frizmefofavpcy"][css~="frizmefofavpcy"] {

                          font-size: var(--font-size--xs);
                          line-height: var(--line-height--xs);
                          display: inline-flex;
                        }

/********************************************************************************/

[css~="dzyqwfuitclalk"][css~="dzyqwfuitclalk"][css~="dzyqwfuitclalk"][css~="dzyqwfuitclalk"][css~="dzyqwfuitclalk"][css~="dzyqwfuitclalk"] {

                        font-size: var(--font-size--xs);
                      }

/********************************************************************************/

[css~="fqyyillxzdopmq"][css~="fqyyillxzdopmq"][css~="fqyyillxzdopmq"][css~="fqyyillxzdopmq"][css~="fqyyillxzdopmq"][css~="fqyyillxzdopmq"] {

                      display: flex;
                      flex-wrap: wrap;
                      gap: var(--space--2);
                    }

/********************************************************************************/

[css~="gvtrtsdgdyoypy"][css~="gvtrtsdgdyoypy"][css~="gvtrtsdgdyoypy"][css~="gvtrtsdgdyoypy"][css~="gvtrtsdgdyoypy"][css~="gvtrtsdgdyoypy"] {

                        width: var(--space--28);
                        display: flex;
                        justify-content: flex-start;
                      }

/********************************************************************************/

[css~="crceuowebekqdo"][css~="crceuowebekqdo"][css~="crceuowebekqdo"][css~="crceuowebekqdo"][css~="crceuowebekqdo"][css~="crceuowebekqdo"] {

                                                                padding: var(
                                                                  --space--2
                                                                );
                                                                display: flex;
                                                                flex-direction: column;
                                                                gap: var(
                                                                  --space--4
                                                                );
                                                              }

/********************************************************************************/

[css~="ycioucdueluop"][css~="ycioucdueluop"][css~="ycioucdueluop"][css~="ycioucdueluop"][css~="ycioucdueluop"][css~="ycioucdueluop"] {

                                                                    font-weight: var(
                                                                      --font-weight--bold
                                                                    );
                                                                  }

/********************************************************************************/

[css~="bhhqlncfbvthde"][css~="bhhqlncfbvthde"][css~="bhhqlncfbvthde"][css~="bhhqlncfbvthde"][css~="bhhqlncfbvthde"][css~="bhhqlncfbvthde"] {

                  display: flex;
                  gap: var(--space--2);
                  align-items: baseline;
                }

/********************************************************************************/

[css~="caglsnvggpwbyp"][css~="caglsnvggpwbyp"][css~="caglsnvggpwbyp"][css~="caglsnvggpwbyp"][css~="caglsnvggpwbyp"][css~="caglsnvggpwbyp"] {

                      cursor: default;
                    }

/********************************************************************************/

[css~="biiyyupinuecd"][css~="biiyyupinuecd"][css~="biiyyupinuecd"][css~="biiyyupinuecd"][css~="biiyyupinuecd"][css~="biiyyupinuecd"] {

                      font-size: var(--font-size--xs);
                      line-height: var(--line-height--xs);
                    }

/********************************************************************************/

[css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"] {

                        display: flex;
                        flex-wrap: wrap;
                        -moz-column-gap: var(--space--4);
                             column-gap: var(--space--4);
                        row-gap: var(--space--0-5);
                      }

[css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"][css~="cfjooknypttrwl"] > * {
                          display: flex;
                          gap: var(--space--1);
                        }

/********************************************************************************/

[css~="eitprrfbszwoaj"][css~="eitprrfbszwoaj"][css~="eitprrfbszwoaj"][css~="eitprrfbszwoaj"][css~="eitprrfbszwoaj"][css~="eitprrfbszwoaj"] {

                                  font-size: var(--font-size--2xs);
                                  line-height: var(--line-height--2xs);
                                }

/********************************************************************************/

[css~="gqjqvrapvzbniy"][css~="gqjqvrapvzbniy"][css~="gqjqvrapvzbniy"][css~="gqjqvrapvzbniy"][css~="gqjqvrapvzbniy"][css~="gqjqvrapvzbniy"] {

              display: flex;
              flex-direction: column;
              gap: var(--space--2);
            }

/********************************************************************************/

[css~="cyiszhvqbsszh"][css~="cyiszhvqbsszh"][css~="cyiszhvqbsszh"][css~="cyiszhvqbsszh"][css~="cyiszhvqbsszh"][css~="cyiszhvqbsszh"] {

                    display: flex;
                    gap: var(--space--4);
                    align-items: center;
                  }

/********************************************************************************/

[css~="ijrbhesupuwkj"][css~="ijrbhesupuwkj"][css~="ijrbhesupuwkj"][css~="ijrbhesupuwkj"][css~="ijrbhesupuwkj"][css~="ijrbhesupuwkj"] {

                      flex: 1;
                    }

/********************************************************************************/

[css~="bwcgspztmbjnyq"][css~="bwcgspztmbjnyq"][css~="bwcgspztmbjnyq"][css~="bwcgspztmbjnyq"][css~="bwcgspztmbjnyq"][css~="bwcgspztmbjnyq"] {

                                max-width: 100%;
                                height: auto;
                              }

/********************************************************************************/

[css~="eeukgamvidcmbw"][css~="eeukgamvidcmbw"][css~="eeukgamvidcmbw"][css~="eeukgamvidcmbw"][css~="eeukgamvidcmbw"][css~="eeukgamvidcmbw"] {

                        padding: var(--space--2);
                      }

/********************************************************************************/

[css~="fediymltdfplbd"][css~="fediymltdfplbd"][css~="fediymltdfplbd"][css~="fediymltdfplbd"][css~="fediymltdfplbd"][css~="fediymltdfplbd"] {

                      padding: var(--space--2);
                      display: flex;
                      flex-direction: column;
                      gap: var(--space--2);
                    }

/********************************************************************************/

[css~="cjvtvdkxqyzaih"][css~="cjvtvdkxqyzaih"][css~="cjvtvdkxqyzaih"][css~="cjvtvdkxqyzaih"][css~="cjvtvdkxqyzaih"][css~="cjvtvdkxqyzaih"] {

                    margin-top: var(--space--0-5);
                  }

/********************************************************************************/

[css~="hlcsclfdzlhjkt"][css~="hlcsclfdzlhjkt"][css~="hlcsclfdzlhjkt"][css~="hlcsclfdzlhjkt"][css~="hlcsclfdzlhjkt"][css~="hlcsclfdzlhjkt"] {

                          width: var(--width);
                        }

/********************************************************************************/

[css~="bnenbvfgdxxjxt"][css~="bnenbvfgdxxjxt"][css~="bnenbvfgdxxjxt"][css~="bnenbvfgdxxjxt"][css~="bnenbvfgdxxjxt"][css~="bnenbvfgdxxjxt"] {

                  flex: 1;
                }

/********************************************************************************/

[css~="fvhxiljhscyxnq"][css~="fvhxiljhscyxnq"][css~="fvhxiljhscyxnq"][css~="fvhxiljhscyxnq"][css~="fvhxiljhscyxnq"][css~="fvhxiljhscyxnq"] {

                        display: flex;
                        gap: var(--space--2);
                      }

/********************************************************************************/

[css~="bmodgnkqflspeg"][css~="bmodgnkqflspeg"][css~="bmodgnkqflspeg"][css~="bmodgnkqflspeg"][css~="bmodgnkqflspeg"][css~="bmodgnkqflspeg"] {

                        display: flex;
                        gap: var(--space--2);
                        cursor: pointer;
                      }

/********************************************************************************/

[css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"] {

                    display: grid;
                  }

[css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"][css~="bmoaykarpmyvvd"] > * {
                      grid-area: 1 / 1;
                    }

/********************************************************************************/

[css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"] {

                      background: var(--color--zinc--100);
                    }

@media (prefers-color-scheme: dark) {

[css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"] {
                        background-color: var(--color--zinc--800);
                    }
                      }

[css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"][css~="faddbzitsewccp"] {
                      width: calc(var(--space--1) + 100% + var(--space--1));
                      height: calc(var(--space--6) + var(--space--0-5));
                      margin-left: var(--space---1);
                      margin-top: var(--space---1);
                      border-radius: var(--border-radius--md);
}

/********************************************************************************/

[css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"] {

                          background: var(--color--blue--100);
                        }

@media (prefers-color-scheme: dark) {

[css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"] {
                            background-color: var(--color--blue--950);
                        }
                          }

[css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"][css~="ddcnfsmkwkipub"] {
                          width: var(--width);
                          height: calc(var(--space--6) + var(--space--0-5));
                          margin-left: var(--space---1);
                          margin-top: var(--space---1);
                          border-radius: var(--border-radius--md);
}

/********************************************************************************/

[css~="cheqqagunvzurl"][css~="cheqqagunvzurl"][css~="cheqqagunvzurl"][css~="cheqqagunvzurl"][css~="cheqqagunvzurl"][css~="cheqqagunvzurl"] {

                      display: flex;
                      flex-direction: column;
                      gap: var(--space--2);
                    }

/********************************************************************************/

[css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"] {

                        font-size: var(--font-size--xs);
                        line-height: var(--line-height--xs);
                        padding-bottom: var(--space--2);
                        border-bottom: var(--border-width--1) solid
                          var(--color--zinc--300);
                      }

@media (prefers-color-scheme: dark) {

[css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"] {
                          border-color: var(--color--zinc--600);
                      }
                        }

[css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"][css~="fwxiwmdqwrkndi"] {
                        margin-bottom: var(--space--2);
                        display: flex;
                        -moz-column-gap: var(--space--4);
                             column-gap: var(--space--4);
                        row-gap: var(--space--1);
                        flex-wrap: wrap;
}

/********************************************************************************/

[css~="fxaywqpnorkido"][css~="fxaywqpnorkido"][css~="fxaywqpnorkido"][css~="fxaywqpnorkido"][css~="fxaywqpnorkido"][css~="fxaywqpnorkido"] {

                    font-size: var(--font-size--xs);
                    line-height: var(--line-height--xs);
                  }

/********************************************************************************/

[css~="fjpdspgknsophe"][css~="fjpdspgknsophe"][css~="fjpdspgknsophe"][css~="fjpdspgknsophe"][css~="fjpdspgknsophe"][css~="fjpdspgknsophe"] {

                        display: flex;
                        gap: var(--space--2);
                        align-items: center;
                      }

/********************************************************************************/

[css~="gusebuvscxbhs"][css~="gusebuvscxbhs"][css~="gusebuvscxbhs"][css~="gusebuvscxbhs"][css~="gusebuvscxbhs"][css~="gusebuvscxbhs"] {

                          display: flex;
                          gap: var(--space--2);
                          flex-wrap: wrap;
                          align-items: center;
                        }

/********************************************************************************/

[css~="ezkluoufgihdxu"][css~="ezkluoufgihdxu"][css~="ezkluoufgihdxu"][css~="ezkluoufgihdxu"][css~="ezkluoufgihdxu"][css~="ezkluoufgihdxu"] {

              margin: var(--space--4) var(--space--0);
            }

/********************************************************************************/

[css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"] {

                      background-color: var(--color--zinc--50);
                    }

@media (prefers-color-scheme: dark) {

[css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"] {
                        background-color: var(--color--zinc--900);
                    }
                      }

[css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"][css~="wdwbkrofghvfs"] {
                      padding: var(--space--2) var(--space--4);
                      border-radius: var(--border-radius--lg);
                      display: flex;
                      flex-direction: column;
                      gap: var(--space--2);
}

/********************************************************************************/

[css~="gcnataockpxedw"][css~="gcnataockpxedw"][css~="gcnataockpxedw"][css~="gcnataockpxedw"][css~="gcnataockpxedw"][css~="gcnataockpxedw"] {

        min-width: var(--space--0);
        display: flex;
        flex-direction: column;
        gap: var(--space--2);
      }

/********************************************************************************/

[css~="hhdbslcqpybbzo"][css~="hhdbslcqpybbzo"][css~="hhdbslcqpybbzo"][css~="hhdbslcqpybbzo"][css~="hhdbslcqpybbzo"][css~="hhdbslcqpybbzo"] > * {
            position: relative;
          }

/********************************************************************************/

[css~="fejhlykdfbrebj"][css~="fejhlykdfbrebj"][css~="fejhlykdfbrebj"][css~="fejhlykdfbrebj"][css~="fejhlykdfbrebj"][css~="fejhlykdfbrebj"] {

            font-size: var(--font-size--xs);
            line-height: var(--line-height--xs);
            display: flex;
            gap: var(--space--4);
            justify-content: space-between;
            align-items: baseline;
          }

/********************************************************************************/

[css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] {

              display: flex;
              gap: var(--space--1);
            }

[css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] .button {
                padding-bottom: var(--space--4);
                margin-bottom: var(--space---3);
              }

[css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] :checked + .button--transparent {
                background-color: var(--color--zinc--200);
              }

[css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] :focus-within + .button--transparent {
                background-color: var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {
                [css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] :checked + .button--transparent {
                  background-color: var(--color--zinc--700);
                }
                [css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"][css~="hadaeonotpjmkr"] :focus-within + .button--transparent {
                  background-color: var(--color--zinc--600);
                }
              }

/********************************************************************************/

[css~="bxjnutvlljqtbh"][css~="bxjnutvlljqtbh"][css~="bxjnutvlljqtbh"][css~="bxjnutvlljqtbh"][css~="bxjnutvlljqtbh"][css~="bxjnutvlljqtbh"] {

              display: flex;
              margin-right: var(--space---1);
            }

/********************************************************************************/

[css~="gjqfzmyackfnrp"][css~="gjqfzmyackfnrp"][css~="gjqfzmyackfnrp"][css~="gjqfzmyackfnrp"][css~="gjqfzmyackfnrp"][css~="gjqfzmyackfnrp"] {

                          max-height: 40vh;
                          overflow: auto;
                          display: flex;
                          flex-direction: column;
                          gap: var(--space--2);
                        }

/********************************************************************************/

[css~="efqhlwxrtcjsji"][css~="efqhlwxrtcjsji"][css~="efqhlwxrtcjsji"][css~="efqhlwxrtcjsji"][css~="efqhlwxrtcjsji"][css~="efqhlwxrtcjsji"] {

                          display: flex;
                          gap: var(--space--2);
                        }

/********************************************************************************/

[css~="exqpuqfrgmrslz"][css~="exqpuqfrgmrslz"][css~="exqpuqfrgmrslz"][css~="exqpuqfrgmrslz"][css~="exqpuqfrgmrslz"][css~="exqpuqfrgmrslz"] {

                                max-height: 40vh;
                                overflow: auto;
                                display: flex;
                                flex-direction: column;
                                gap: var(--space--2);
                              }

/********************************************************************************/

[css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"] {

            background-color: var(--color--zinc--200);
          }

@media (prefers-color-scheme: dark) {

[css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"] {
              background-color: var(--color--zinc--700);
          }
            }

[css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"][css~="eqhdetrjllfzzf"] {
            border-radius: var(--border-radius--lg);
}

/********************************************************************************/

[css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"] {

                display: grid;
              }

[css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"][css~="cwwzrujrpzuief"] > * {
                  grid-area: 1 / 1;
                }

/********************************************************************************/

[css~="fykzuvniplfxor"][css~="fykzuvniplfxor"][css~="fykzuvniplfxor"][css~="fykzuvniplfxor"][css~="fykzuvniplfxor"][css~="fykzuvniplfxor"] {

                    position: relative;
                  }

/********************************************************************************/

[css~="cuajsyacdfakao"][css~="cuajsyacdfakao"][css~="cuajsyacdfakao"][css~="cuajsyacdfakao"][css~="cuajsyacdfakao"][css~="cuajsyacdfakao"] {

                      width: var(--space--0);
                      height: var(--line-height--sm);
                      position: absolute;
                    }

/********************************************************************************/

[css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"] {

                      height: var(--height);
                      max-height: var(--space--64);
                    }

[css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"].drag {
                        background-color: var(--color--blue--200);
                      }

@media (prefers-color-scheme: dark) {

[css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"].drag {
                          background-color: var(--color--blue--900)
                      }
                        }

[css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"][css~="gsxmjjglnsurex"].content-editor--write--textarea--programmer-mode {
                        font-family: "JetBrains Mono Variable",
                          var(--font-family--monospace);
                        font-variant-ligatures: none;
                      }

/********************************************************************************/

[css~="qqrmwrgbzxcvh"][css~="qqrmwrgbzxcvh"][css~="qqrmwrgbzxcvh"][css~="qqrmwrgbzxcvh"][css~="qqrmwrgbzxcvh"][css~="qqrmwrgbzxcvh"] {

                          padding-right: var(--space--8);
                        }

/********************************************************************************/

[css~="bhzyvyoreoefda"][css~="bhzyvyoreoefda"][css~="bhzyvyoreoefda"][css~="bhzyvyoreoefda"][css~="bhzyvyoreoefda"][css~="bhzyvyoreoefda"] {
}

/********************************************************************************/

[css~="owpfzajzeixwx"][css~="owpfzajzeixwx"][css~="owpfzajzeixwx"][css~="owpfzajzeixwx"][css~="owpfzajzeixwx"][css~="owpfzajzeixwx"] {

                                      width: var(--space--56);
                                      max-height: var(--space--44);
                                      overflow: auto;
                                    }

/********************************************************************************/

[css~="fygqlicctlinrn"][css~="fygqlicctlinrn"][css~="fygqlicctlinrn"][css~="fygqlicctlinrn"][css~="fygqlicctlinrn"][css~="fygqlicctlinrn"] {

                                      width: var(--space--72);
                                      max-height: var(--space--44);
                                      overflow: auto;
                                    }

/********************************************************************************/

[css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"] {

                        position: relative;
                        justify-self: end;
                      }

[css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:hover,
                        [css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:focus-within {
                          background-color: var(--color--zinc--300);
                        }

[css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:active {
                          background-color: var(--color--zinc--400);
                        }

@media (prefers-color-scheme: dark) {
                          [css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:hover,
                          [css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:focus-within {
                            background-color: var(--color--zinc--600);
                          }
                          [css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"]:active {
                            background-color: var(--color--zinc--500);
                          }
                        }

[css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"][css~="gdobeswvmgoakp"] {
                        width: var(--font-size--2xl);
                        height: var(--font-size--2xl);
                        padding: var(--space--0);
                        border-radius: var(--border-radius--circle);
                        margin: var(--space--1);
                        align-items: center;
}

/********************************************************************************/

[css~="ftvxhjhfcrvokf"][css~="ftvxhjhfcrvokf"][css~="ftvxhjhfcrvokf"][css~="ftvxhjhfcrvokf"][css~="ftvxhjhfcrvokf"][css~="ftvxhjhfcrvokf"] {

              padding: var(--space--4);
              display: flex;
              justify-content: center;
              align-items: center;
              gap: var(--space--2);
            }

/********************************************************************************/

[css~="dezxlosdoltiil"][css~="dezxlosdoltiil"][css~="dezxlosdoltiil"][css~="dezxlosdoltiil"][css~="dezxlosdoltiil"][css~="dezxlosdoltiil"] {

              padding: var(--space--2) var(--space--4);
            }

/********************************************************************************/

[css~="bstzkmajkolcvm"][css~="bstzkmajkolcvm"][css~="bstzkmajkolcvm"][css~="bstzkmajkolcvm"][css~="bstzkmajkolcvm"][css~="bstzkmajkolcvm"] {

          font-size: var(--font-size--xs);
          line-height: var(--line-height--xs);
        }

/********************************************************************************/

[css~="cgadjpvbixhzla"][css~="cgadjpvbixhzla"][css~="cgadjpvbixhzla"][css~="cgadjpvbixhzla"][css~="cgadjpvbixhzla"][css~="cgadjpvbixhzla"] {

            margin-right: var(--space--1);
          }

/********************************************************************************/

[key="content-editor"] [css~="fygxnruwobarkv"][css~="fygxnruwobarkv"][css~="fygxnruwobarkv"][css~="fygxnruwobarkv"][css~="fygxnruwobarkv"][css~="fygxnruwobarkv"] {
            padding: var(--space--2);
          }

/********************************************************************************/

[key="content-editor"] [css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"] {
              padding-bottom: var(--space--2);
              border-bottom: var(--border-width--1) solid
                var(--color--zinc--300);
            }

@media (prefers-color-scheme: dark) {

[key="content-editor"] [css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"][css~="cmgxnxtdkfwnlc"] {
                border-color: var(--color--zinc--600)
            }
              }

/********************************************************************************/

[key="content-editor"] [css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"] {
                background-color: var(--color--zinc--50);
              }

@media (prefers-color-scheme: dark) {

[key="content-editor"] [css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"] {
                  background-color: var(--color--zinc--900);
              }
                }

[key="content-editor"] [css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"] {
                padding: var(--space--2) var(--space--4);
                border-radius: var(--border-radius--lg);
}

[css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"][css~="bnrhvsuthnmogq"] {
              display: flex;
              flex-direction: column;
              gap: var(--space--4);
}

/********************************************************************************/

[css~="dsmqrgriemncmy"][css~="dsmqrgriemncmy"][css~="dsmqrgriemncmy"][css~="dsmqrgriemncmy"][css~="dsmqrgriemncmy"][css~="dsmqrgriemncmy"] {

                display: flex;
                flex-wrap: wrap;
                -moz-column-gap: var(--space--10);
                     column-gap: var(--space--10);
                row-gap: var(--space--4);
              }

/********************************************************************************/

[css~="bgmywxouqqgdad"][css~="bgmywxouqqgdad"][css~="bgmywxouqqgdad"][css~="bgmywxouqqgdad"][css~="bgmywxouqqgdad"][css~="bgmywxouqqgdad"] {

                    display: flex;
                    gap: var(--space--8);
                  }

/********************************************************************************/

[css~="bleqqijoeankoz"][css~="bleqqijoeankoz"][css~="bleqqijoeankoz"][css~="bleqqijoeankoz"][css~="bleqqijoeankoz"][css~="bleqqijoeankoz"] {

                    display: flex;
                    gap: var(--space--2);
                    align-items: flex-start;
                  }

/********************************************************************************/

[css~="bqcheovkwqtsik"][css~="bqcheovkwqtsik"][css~="bqcheovkwqtsik"][css~="bqcheovkwqtsik"][css~="bqcheovkwqtsik"][css~="bqcheovkwqtsik"] {

                      display: flex;
                      gap: var(--space--2);
                      align-items: flex-start;
                    }

/********************************************************************************/

[css~="fpbogznjnjydas"][css~="fpbogznjnjydas"][css~="fpbogznjnjydas"][css~="fpbogznjnjydas"][css~="fpbogznjnjydas"][css~="fpbogznjnjydas"] {

                        width: var(--space--40);
                        margin: var(--space---2) var(--space--0);
                      }

/********************************************************************************/

[css~="dpgojzghrgdlcx"][css~="dpgojzghrgdlcx"][css~="dpgojzghrgdlcx"][css~="dpgojzghrgdlcx"][css~="dpgojzghrgdlcx"][css~="dpgojzghrgdlcx"] {

                  display: flex;
                  flex-direction: column;
                  gap: var(--space--2);
                }

/********************************************************************************/

[css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"] {

                    display: flex;
                    flex-direction: column;
                    gap: var(--space--2);
                  }

[css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"] [key^="poll-editor--option/"] {
                      display: flex;
                      gap: var(--space--2);
                      align-items: center;

                      transition-property: var(--transition-property--opacity);
                      transition-duration: var(--transition-duration--150);
                      transition-timing-function: var(
                        --transition-timing-function--in-out
                      );
                    }

[css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"] [key^="poll-editor--option/"].grabbed {
                        opacity: var(--opacity--50);
                      }

[css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"][css~="hhzxnwulcrrlhz"] [key^="poll-editor--option/"] [key="poll-editor--option--grab--handle"]:not(.disabled) {
                        cursor: grab;
                      }

/********************************************************************************/

[css~="gubotwcqbjepld"][css~="gubotwcqbjepld"][css~="gubotwcqbjepld"][css~="gubotwcqbjepld"][css~="gubotwcqbjepld"][css~="gubotwcqbjepld"] {

                    display: flex;
                    justify-content: center;
                  }

/********************************************************************************/

[css~="bkfqackanimjry"][css~="bkfqackanimjry"][css~="bkfqackanimjry"][css~="bkfqackanimjry"][css~="bkfqackanimjry"][css~="bkfqackanimjry"] {

                display: flex;
                gap: var(--space--2);
                align-items: center;
                flex-wrap: wrap;
              }

/********************************************************************************/

[css~="hcjybxolhrhydm"][css~="hcjybxolhrhydm"][css~="hcjybxolhrhydm"][css~="hcjybxolhrhydm"][css~="hcjybxolhrhydm"][css~="hcjybxolhrhydm"] {

                          display: flex;
                          justify-content: center;
                          align-items: center;
                          gap: var(--space--2);
                        }

/********************************************************************************/

@media (min-width: 900px) {

[css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"][css~="chniyrkcgypqbm"] {
                      display: none
                  }
                    }

/********************************************************************************/

[css~="grzsdkiqanfics"][css~="grzsdkiqanfics"][css~="grzsdkiqanfics"][css~="grzsdkiqanfics"][css~="grzsdkiqanfics"][css~="grzsdkiqanfics"] {

                    padding: var(--space--1) var(--space--0);
                  }

/********************************************************************************/

[css~="hfxqmmmqzoimdo"][css~="hfxqmmmqzoimdo"][css~="hfxqmmmqzoimdo"][css~="hfxqmmmqzoimdo"][css~="hfxqmmmqzoimdo"][css~="hfxqmmmqzoimdo"] {

            width: 100%;
            height: 100%;
            display: flex;
          }

/********************************************************************************/

[css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"] {

              display: flex;
              flex-direction: column;
            }

@media (max-width: 899px) {

[css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"] {
                flex: 1
            }
              }

@media (min-width: 900px) {

[css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"] {
                width: var(--width--sm);
                border-right: var(--border-width--1) solid
                  var(--color--zinc--200)
            }
                @media (prefers-color-scheme: dark) {

[css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"][css~="duqopfnhnssqzf"] {
                  border-color: var(--color--zinc--700)
            }
                }
              }

/********************************************************************************/

@media (max-width: 899px) {

[css~="ugelqjdpmovee"][css~="ugelqjdpmovee"][css~="ugelqjdpmovee"][css~="ugelqjdpmovee"][css~="ugelqjdpmovee"][css~="ugelqjdpmovee"] {
                    display: none
                }
                  }

/********************************************************************************/

[css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"] {

                background-color: var(--color--zinc--100);
              }

@media (prefers-color-scheme: dark) {

[css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"] {
                  background-color: var(--color--zinc--800)
              }
                }

[css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"] {
                max-height: 50%;
                overflow: auto;
                border-bottom: var(--border-width--1) solid
                  var(--color--zinc--200);
}

@media (prefers-color-scheme: dark) {

[css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"][css~="kaynwqusnlcou"] {
                  border-color: var(--color--zinc--700)
              }
                }

/********************************************************************************/

[css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"] {

                  margin: var(--space--4);
                }

@media (max-width: 899px) {

[css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"][css~="gyiqfgrqaawete"] {
                    display: flex;
                    justify-content: center
                }
                  }

/********************************************************************************/

[css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"] {

                    display: flex;
                    flex-direction: column;
                    gap: var(--space--2);
                  }

@media (max-width: 899px) {

[css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"][css~="mnbotzinfpupn"] {
                      flex: 1;
                      min-width: var(--width--0);
                      max-width: var(--width--prose)
                  }
                    }

/********************************************************************************/

[css~="higphonizrmiki"][css~="higphonizrmiki"][css~="higphonizrmiki"][css~="higphonizrmiki"][css~="higphonizrmiki"][css~="higphonizrmiki"] {

                      font-size: var(--font-size--xs);
                      line-height: var(--line-height--xs);
                      display: flex;
                      align-items: baseline;
                      -moz-column-gap: var(--space--4);
                           column-gap: var(--space--4);
                      row-gap: var(--space--2);
                      flex-wrap: wrap;
                    }

/********************************************************************************/

[css~="eiobqeveokneun"][css~="eiobqeveokneun"][css~="eiobqeveokneun"][css~="eiobqeveokneun"][css~="eiobqeveokneun"][css~="eiobqeveokneun"] {

                        font-size: var(--font-size--2xs);
                        line-height: var(--line-height--2xs);
                      }

/********************************************************************************/

[css~="pkvdmdimxycma"][css~="pkvdmdimxycma"][css~="pkvdmdimxycma"][css~="pkvdmdimxycma"][css~="pkvdmdimxycma"][css~="pkvdmdimxycma"] {

                      font-size: var(--font-size--xs);
                      line-height: var(--line-height--xs);
                      display: flex;
                      flex-direction: column;
                      gap: var(--space--2);
                    }

/********************************************************************************/

[css~="wnewgjqchbrms"][css~="wnewgjqchbrms"][css~="wnewgjqchbrms"][css~="wnewgjqchbrms"][css~="wnewgjqchbrms"][css~="wnewgjqchbrms"] {

                        display: flex;
                        -moz-column-gap: var(--space--4);
                             column-gap: var(--space--4);
                        row-gap: var(--space--2);
                        flex-wrap: wrap;
                      }

/********************************************************************************/

[css~="mludkmbxjkitn"][css~="mludkmbxjkitn"][css~="mludkmbxjkitn"][css~="mludkmbxjkitn"][css~="mludkmbxjkitn"][css~="mludkmbxjkitn"] {

                        display: flex;
                        flex-direction: column;
                        gap: var(--space--1);
                      }

/********************************************************************************/

[css~="gctvpywskfquyv"][css~="gctvpywskfquyv"][css~="gctvpywskfquyv"][css~="gctvpywskfquyv"][css~="gctvpywskfquyv"][css~="gctvpywskfquyv"] {

                          display: flex;
                          gap: var(--space--2);
                          align-items: center;
                        }

/********************************************************************************/

[css~="fwtwivvpubidvg"][css~="fwtwivvpubidvg"][css~="fwtwivvpubidvg"][css~="fwtwivvpubidvg"][css~="fwtwivvpubidvg"][css~="fwtwivvpubidvg"] {

                          display: flex;
                          flex-direction: column;
                          gap: var(--space--2);
                        }

/********************************************************************************/

[css~="ejmahwzgugrvek"][css~="ejmahwzgugrvek"][css~="ejmahwzgugrvek"][css~="ejmahwzgugrvek"][css~="ejmahwzgugrvek"][css~="ejmahwzgugrvek"] {

                              display: flex;
                              flex-wrap: wrap;
                              -moz-column-gap: var(--space--6);
                                   column-gap: var(--space--6);
                              row-gap: var(--space--2);
                            }

/********************************************************************************/

[css~="exrroegxbjkkva"][css~="exrroegxbjkkva"][css~="exrroegxbjkkva"][css~="exrroegxbjkkva"][css~="exrroegxbjkkva"][css~="exrroegxbjkkva"] {

                                    display: flex;
                                    flex-wrap: wrap;
                                    -moz-column-gap: var(--space--6);
                                         column-gap: var(--space--6);
                                    row-gap: var(--space--2);
                                  }

/********************************************************************************/

[css~="hguxpyjslnvkch"][css~="hguxpyjslnvkch"][css~="hguxpyjslnvkch"][css~="hguxpyjslnvkch"][css~="hguxpyjslnvkch"][css~="hguxpyjslnvkch"] {

                                          display: flex;
                                          gap: var(--space--2);
                                        }

/********************************************************************************/

[css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"] {

                            margin-top: var(--space--2);
                            display: flex;
                            gap: var(--space--4);
                          }

[css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"][css~="fugufcdyekqqcl"] > * {
                              flex: 1;
                            }

/********************************************************************************/

[css~="eeysxdgqtbhxew"][css~="eeysxdgqtbhxew"][css~="eeysxdgqtbhxew"][css~="eeysxdgqtbhxew"][css~="eeysxdgqtbhxew"][css~="eeysxdgqtbhxew"] {

                flex: 1;
                overflow: auto;
              }

/********************************************************************************/

[css~="dxuipyfflfydap"][css~="dxuipyfflfydap"][css~="dxuipyfflfydap"][css~="dxuipyfflfydap"][css~="dxuipyfflfydap"][css~="dxuipyfflfydap"] {

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                          }

/********************************************************************************/

[css~="fnknsjlzjrjblq"][css~="fnknsjlzjrjblq"][css~="fnknsjlzjrjblq"][css~="fnknsjlzjrjblq"][css~="fnknsjlzjrjblq"][css~="fnknsjlzjrjblq"] {

                                  display: flex;
                                  justify-content: center;
                                }

/********************************************************************************/

[css~="uxfrzrxeovpmz"][css~="uxfrzrxeovpmz"][css~="uxfrzrxeovpmz"][css~="uxfrzrxeovpmz"][css~="uxfrzrxeovpmz"][css~="uxfrzrxeovpmz"] {

                            margin-top: var(--space---2);
                          }

/********************************************************************************/

[css~="fimxeejeczlzhy"][css~="fimxeejeczlzhy"][css~="fimxeejeczlzhy"][css~="fimxeejeczlzhy"][css~="fimxeejeczlzhy"][css~="fimxeejeczlzhy"] {

                                    width: calc(
                                      var(--space--2) + 100% + var(--space--2)
                                    );
                                    padding: var(--space--3) var(--space--2);
                                    margin-left: var(--space---2);
                                    position: relative;
                                    align-items: center;
                                  }

/********************************************************************************/

@media (min-width: 900px) {

[css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] {
                                          color: var(--color--blue--50)
                                      }
                                          @media (prefers-color-scheme: dark) {

[css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] {
                                            color: var(--color--blue--100)
                                      }
                                          }
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled) {
                                            background-color: var(
                                              --color--blue--600
                                            );
                                          }
                                            [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):hover,
                                            [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):focus-within,
                                            [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled).hover {
                                              background-color: var(
                                                --color--blue--500
                                              );
                                            }
                                            [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):active {
                                              background-color: var(
                                                --color--blue--700
                                              );
                                            }
                                            @media (prefers-color-scheme: dark) {
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled) {
                                              background-color: var(
                                                --color--blue--800
                                              )
                                          }
                                              [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):hover,
                                              [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):focus-within,
                                              [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled).hover {
                                                background-color: var(
                                                  --color--blue--700
                                                );
                                              }
                                              [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:not(:disabled):not(.disabled):active {
                                                background-color: var(
                                                  --color--blue--900
                                                );
                                              }
                                            }
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:disabled,
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"].disabled {
                                            background-color: var(
                                              --color--blue--300
                                            );
                                          }
                                            @media (prefers-color-scheme: dark) {
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"]:disabled,
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"].disabled {
                                              background-color: var(
                                                --color--blue--500
                                              )
                                          }
                                            }
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] .strong {
                                            color: var(--color--blue--50);
                                          }
                                            @media (prefers-color-scheme: dark) {
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] .strong {
                                              color: var(--color--blue--100)
                                          }
                                            }
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] .secondary,
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] [class^="text--"] {
                                            color: var(--color--blue--100);
                                          }
                                            @media (prefers-color-scheme: dark) {
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] .secondary,
                                          [css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] [class^="text--"] {
                                              color: var(--color--blue--200)
                                          }
                                            }
                                        }

[css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"][css~="gtqfocdnjejayu"] + * {
                                          margin-bottom: var(--space--0);
                                        }

/********************************************************************************/

[css~="choqktljolsfan"][css~="choqktljolsfan"][css~="choqktljolsfan"][css~="choqktljolsfan"][css~="choqktljolsfan"][css~="choqktljolsfan"] {

                                      flex: 1;
                                      max-width: 100%;
                                    }

/********************************************************************************/

[css~="kueiurmevhofh"][css~="kueiurmevhofh"][css~="kueiurmevhofh"][css~="kueiurmevhofh"][css~="kueiurmevhofh"][css~="kueiurmevhofh"] {

                                      width: var(--space--4);
                                      display: flex;
                                      justify-content: flex-end;
                                    }

/********************************************************************************/

[css~="ccwvtkaaaqylvt"][css~="ccwvtkaaaqylvt"][css~="ccwvtkaaaqylvt"][css~="ccwvtkaaaqylvt"][css~="ccwvtkaaaqylvt"][css~="ccwvtkaaaqylvt"] {

                                                font-size: var(
                                                  --font-size--2xs
                                                );
                                                line-height: var(
                                                  --line-height--2xs
                                                );
                                              }

/********************************************************************************/

[css~="dpntlqygakpamh"][css~="dpntlqygakpamh"][css~="dpntlqygakpamh"][css~="dpntlqygakpamh"][css~="dpntlqygakpamh"][css~="dpntlqygakpamh"] {

                                  margin: var(--space---px) var(--space--0);
                                }

/********************************************************************************/

[css~="buuofpqjuixvkp"][css~="buuofpqjuixvkp"][css~="buuofpqjuixvkp"][css~="buuofpqjuixvkp"][css~="buuofpqjuixvkp"][css~="buuofpqjuixvkp"] {

              overflow: auto;
              flex: 1;
            }

/********************************************************************************/

@media (max-width: 899px) {

[css~="hhawqvjvdgflri"][css~="hhawqvjvdgflri"][css~="hhawqvjvdgflri"][css~="hhawqvjvdgflri"][css~="hhawqvjvdgflri"][css~="hhawqvjvdgflri"] {
                  display: flex;
                  justify-content: center
              }
                }

/********************************************************************************/

[css~="gpkydtngqswuwd"][css~="gpkydtngqswuwd"][css~="gpkydtngqswuwd"][css~="gpkydtngqswuwd"][css~="gpkydtngqswuwd"][css~="gpkydtngqswuwd"] {

                    height: 100%;
                    display: flex;
                  }

/********************************************************************************/

[css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"] {

                    margin: var(--space--4);
                  }

@media (min-width: 900px) {

[css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"][css~="duonumzstkugyb"] {
                      margin-left: var(--space--8)
                  }
                    }

/********************************************************************************/

[css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"] {

                        min-width: var(--width--0);
                        max-width: var(--width--prose);
                        display: flex;
                        flex-direction: column;
                        gap: var(--space--4);
                      }

@media (max-width: 899px) {

[css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"][css~="gzivruukhthple"] {
                          flex: 1
                      }
                        }

/********************************************************************************/

[css~="edpvlprtydatbt"][css~="edpvlprtydatbt"][css~="edpvlprtydatbt"][css~="edpvlprtydatbt"][css~="edpvlprtydatbt"][css~="edpvlprtydatbt"] {

        display: flex;
        flex-direction: column;
        gap: var(--space--1);
      }

/********************************************************************************/

[css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"] {

          font-size: var(--font-size--xs);
          line-height: var(--line-height--xs);
          display: flex;
          flex-wrap: wrap;
          -moz-column-gap: var(--space--4);
               column-gap: var(--space--4);
          row-gap: var(--space--0-5);
        }

[css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"][css~="ehchlzkclezupi"] > * {
            display: flex;
            gap: var(--space--1);
          }

/********************************************************************************/

[css~="fotdsnjolxqxza"][css~="fotdsnjolxqxza"][css~="fotdsnjolxqxza"][css~="fotdsnjolxqxza"][css~="fotdsnjolxqxza"][css~="fotdsnjolxqxza"] {

          font-size: var(--font-size--xs);
          line-height: var(--line-height--xs);
          display: flex;
          flex-wrap: wrap;
          -moz-column-gap: var(--space--3);
               column-gap: var(--space--3);
          row-gap: var(--space--0-5);
        }

/********************************************************************************/

[css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"] {

                font-size: var(--font-size--xs);
                line-height: var(--line-height--xs);
                display: flex;
                flex-wrap: wrap;
                -moz-column-gap: var(--space--4);
                     column-gap: var(--space--4);
                row-gap: var(--space--0-5);
              }

[css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"][css~="figzqroalykscr"] > * {
                  display: flex;
                  gap: var(--space--1);
                }

/********************************************************************************/

[css~="ckpvenfkcrpwrj"][css~="ckpvenfkcrpwrj"][css~="ckpvenfkcrpwrj"][css~="ckpvenfkcrpwrj"][css~="ckpvenfkcrpwrj"][css~="ckpvenfkcrpwrj"] {

                max-height: var(--space--56);
                padding: var(--space--1) var(--space--0);
                overflow: auto;
                gap: var(--space--2);
              }

/********************************************************************************/

[css~="eiullzjcbshuoa"][css~="eiullzjcbshuoa"][css~="eiullzjcbshuoa"][css~="eiullzjcbshuoa"][css~="eiullzjcbshuoa"][css~="eiullzjcbshuoa"] {

                display: flex;
                flex-direction: column;
                gap: var(--space--4);
              }

/********************************************************************************/

[css~="gtamoevqowkdwe"][css~="gtamoevqowkdwe"][css~="gtamoevqowkdwe"][css~="gtamoevqowkdwe"][css~="gtamoevqowkdwe"][css~="gtamoevqowkdwe"] {

                    display: flex;
                    flex-wrap: wrap;
                    -moz-column-gap: var(--space--8);
                         column-gap: var(--space--8);
                    row-gap: var(--space--2);
                  }

/********************************************************************************/

[css~="copbnxqggjzecs"][css~="copbnxqggjzecs"][css~="copbnxqggjzecs"][css~="copbnxqggjzecs"][css~="copbnxqggjzecs"][css~="copbnxqggjzecs"] {

                                  flex: 1;
                                  display: flex;
                                  justify-content: flex-end;
                                }

/********************************************************************************/

[css~="emachkwryfqwbe"][css~="emachkwryfqwbe"][css~="emachkwryfqwbe"][css~="emachkwryfqwbe"][css~="emachkwryfqwbe"][css~="emachkwryfqwbe"] {

                          display: flex;
                          flex-wrap: wrap;
                          -moz-column-gap: var(--space--8);
                               column-gap: var(--space--8);
                          row-gap: var(--space--2);
                        }

/********************************************************************************/

[css~="frantmojgpzvx"][css~="frantmojgpzvx"][css~="frantmojgpzvx"][css~="frantmojgpzvx"][css~="frantmojgpzvx"][css~="frantmojgpzvx"] {

                                    display: flex;
                                    gap: var(--space--2);
                                  }

/********************************************************************************/

[css~="bbfuhcgtcfdcge"][css~="bbfuhcgtcfdcge"][css~="bbfuhcgtcfdcge"][css~="bbfuhcgtcfdcge"][css~="bbfuhcgtcfdcge"][css~="bbfuhcgtcfdcge"] {

                        display: flex;
                        flex-wrap: wrap;
                        -moz-column-gap: var(--space--8);
                             column-gap: var(--space--8);
                        row-gap: var(--space--4);
                      }

/********************************************************************************/

[css~="edpyhrrbwnjxvp"][css~="edpyhrrbwnjxvp"][css~="edpyhrrbwnjxvp"][css~="edpyhrrbwnjxvp"][css~="edpyhrrbwnjxvp"][css~="edpyhrrbwnjxvp"] {

                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--space--2);
                                  }

/********************************************************************************/

[css~="epenliohljqnip"][css~="epenliohljqnip"][css~="epenliohljqnip"][css~="epenliohljqnip"][css~="epenliohljqnip"][css~="epenliohljqnip"] {

                                      display: flex;
                                      flex-direction: column;
                                      gap: var(--space--2);
                                    }

/********************************************************************************/

[css~="etjxuxgmskrisn"][css~="etjxuxgmskrisn"][css~="etjxuxgmskrisn"][css~="etjxuxgmskrisn"][css~="etjxuxgmskrisn"][css~="etjxuxgmskrisn"] {

                                        display: flex;
                                        align-items: center;
                                        padding: var(--space--0) var(--space--2);
                                      }

/********************************************************************************/

[css~="gpygpwhjfdjqjq"][css~="gpygpwhjfdjqjq"][css~="gpygpwhjfdjqjq"][css~="gpygpwhjfdjqjq"][css~="gpygpwhjfdjqjq"][css~="gpygpwhjfdjqjq"] {

                                          flex: 1;
                                        }

/********************************************************************************/

[css~="bwipwlaxryrkyi"][css~="bwipwlaxryrkyi"][css~="bwipwlaxryrkyi"][css~="bwipwlaxryrkyi"][css~="bwipwlaxryrkyi"][css~="bwipwlaxryrkyi"] {

                                              padding: var(--space--0)
                                                var(--space--2) var(--space--2);
                                            }

/********************************************************************************/

[css~="gfsndfdjrdmhwd"][css~="gfsndfdjrdmhwd"][css~="gfsndfdjrdmhwd"][css~="gfsndfdjrdmhwd"][css~="gfsndfdjrdmhwd"][css~="gfsndfdjrdmhwd"] {

                                              padding: var(--space--0)
                                                var(--space--2);
                                            }

/********************************************************************************/

[css~="deczrgufhscdgh"][css~="deczrgufhscdgh"][css~="deczrgufhscdgh"][css~="deczrgufhscdgh"][css~="deczrgufhscdgh"][css~="deczrgufhscdgh"] {

                                                display: flex;
                                                gap: var(--space--2);
                                                align-items: baseline;
                                              }

/********************************************************************************/

[css~="eqvkyxdlgqujzn"][css~="eqvkyxdlgqujzn"][css~="eqvkyxdlgqujzn"][css~="eqvkyxdlgqujzn"][css~="eqvkyxdlgqujzn"][css~="eqvkyxdlgqujzn"] {

                                              height: var(--space--40);
                                              overflow: auto;
                                            }

/********************************************************************************/

[css~="poepesmrkrtac"][css~="poepesmrkrtac"][css~="poepesmrkrtac"][css~="poepesmrkrtac"][css~="poepesmrkrtac"][css~="poepesmrkrtac"] {

                  display: flex;
                  flex-wrap: wrap;
                  -moz-column-gap: var(--space--8);
                       column-gap: var(--space--8);
                  row-gap: var(--space--4);
                }

/********************************************************************************/

[css~="drjvwfmvfvsedi"][css~="drjvwfmvfvsedi"][css~="drjvwfmvfvsedi"][css~="drjvwfmvfvsedi"][css~="drjvwfmvfvsedi"][css~="drjvwfmvfvsedi"] {

                          width: var(--space--44);
                        }

/********************************************************************************/

[css~="qawitsrkjcrar"][css~="qawitsrkjcrar"][css~="qawitsrkjcrar"][css~="qawitsrkjcrar"][css~="qawitsrkjcrar"][css~="qawitsrkjcrar"] {

                            display: flex;
                          }

/********************************************************************************/

[css~="ekqldaljigtyzc"][css~="ekqldaljigtyzc"][css~="ekqldaljigtyzc"][css~="ekqldaljigtyzc"][css~="ekqldaljigtyzc"][css~="ekqldaljigtyzc"] {

                          width: var(--space--24);
                        }

/********************************************************************************/

[css~="gxeynsvlqrqxsq"][css~="gxeynsvlqrqxsq"][css~="gxeynsvlqrqxsq"][css~="gxeynsvlqrqxsq"][css~="gxeynsvlqrqxsq"][css~="gxeynsvlqrqxsq"] {

                          width: var(--space--60);
                        }

/********************************************************************************/

[css~="bhlilhpwvbyrfp"][css~="bhlilhpwvbyrfp"][css~="bhlilhpwvbyrfp"][css~="bhlilhpwvbyrfp"][css~="bhlilhpwvbyrfp"][css~="bhlilhpwvbyrfp"] {

                                    margin-left: var(--space--1);
                                  }

/********************************************************************************/

[css~="cuyjwtxujhxjrn"][css~="cuyjwtxujhxjrn"][css~="cuyjwtxujhxjrn"][css~="cuyjwtxujhxjrn"][css~="cuyjwtxujhxjrn"][css~="cuyjwtxujhxjrn"] {

                  font-size: var(--font-size--xs);
                  line-height: var(--line-height--xs);
                  display: flex;
                  -moz-column-gap: var(--space--8);
                       column-gap: var(--space--8);
                  row-gap: var(--space--2);
                  flex-wrap: wrap;
                }

/********************************************************************************/

[css~="fumjmkhjetvrvo"][css~="fumjmkhjetvrvo"][css~="fumjmkhjetvrvo"][css~="fumjmkhjetvrvo"][css~="fumjmkhjetvrvo"][css~="fumjmkhjetvrvo"] {

                flex: 1;
                display: flex;
                flex-direction: column;
                min-width: var(--space--0);
              }

/********************************************************************************/

[css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"] {

                  padding-bottom: var(--space--2);
                  border-bottom: var(--border-width--1) solid
                    var(--color--zinc--200);
                }

@media (prefers-color-scheme: dark) {

[css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"][css~="erantrsteqblep"] {
                    border-color: var(--color--zinc--700)
                }
                  }

/********************************************************************************/

[css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"] {

                      padding-top: var(--space--4);
                      padding-right: var(--space--4);
                      padding-left: var(--space--4);
                    }

@media (min-width: 900px) {

[css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"] {
                        padding-left: var(--space--8)
                    }
                      }

[css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"] {
                      display: flex;
}

@media (max-width: 899px) {

[css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"][css~="dpmdnkvuazenui"] {
                        justify-content: center
                    }
                      }

/********************************************************************************/

[css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"] {

                        flex: 1;
                        min-width: var(--width--0);
                        max-width: var(--width--prose);
                        display: flex;
                      }

[css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"][css~="bakgufosfxqhn"] > * {
                          flex: 1;
                        }

/********************************************************************************/

[css~="ybfiqpgpqymuf"][css~="ybfiqpgpqymuf"][css~="ybfiqpgpqymuf"][css~="ybfiqpgpqymuf"][css~="ybfiqpgpqymuf"][css~="ybfiqpgpqymuf"] {

                            max-width: calc(100% + var(--space--2));
                            margin-top: var(--space---2);
                          }

/********************************************************************************/

[css~="dgynqkaobscizn"][css~="dgynqkaobscizn"][css~="dgynqkaobscizn"][css~="dgynqkaobscizn"][css~="dgynqkaobscizn"][css~="dgynqkaobscizn"] {

                              flex: 1;
                              text-align: left;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }

/********************************************************************************/

[css~="rzolxixmvlbod"][css~="rzolxixmvlbod"][css~="rzolxixmvlbod"][css~="rzolxixmvlbod"][css~="rzolxixmvlbod"][css~="rzolxixmvlbod"] {

                      display: flex;
                      flex-direction: column;
                      gap: var(--space--1);
                    }

/********************************************************************************/

[css~="cwqtauggjympjz"][css~="cwqtauggjympjz"][css~="cwqtauggjympjz"][css~="cwqtauggjympjz"][css~="cwqtauggjympjz"][css~="cwqtauggjympjz"] {

                        font-size: var(--font-size--xs);
                        line-height: var(--line-height--xs);
                        display: flex;
                        gap: var(--space--4);
                      }

/********************************************************************************/

[css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"] {

                          flex: 1;
                          display: flex;
                          flex-wrap: wrap;
                          -moz-column-gap: var(--space--8);
                               column-gap: var(--space--8);
                          row-gap: var(--space--1);
                        }

[css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"][css~="fkothibfcrlokr"] > * {
                            display: flex;
                            gap: var(--space--1);
                          }

/********************************************************************************/

[css~="mnkqcnbscwvaa"][css~="mnkqcnbscwvaa"][css~="mnkqcnbscwvaa"][css~="mnkqcnbscwvaa"][css~="mnkqcnbscwvaa"][css~="mnkqcnbscwvaa"] {

                                          display: flex;
                                          gap: var(--space--1);
                                        }

/********************************************************************************/

[css~="gvmxslmzcgzqau"][css~="gvmxslmzcgzqau"][css~="gvmxslmzcgzqau"][css~="gvmxslmzcgzqau"][css~="gvmxslmzcgzqau"][css~="gvmxslmzcgzqau"] {

                                                        margin-left: var(
                                                          --space---0-5
                                                        );
                                                      }

/********************************************************************************/

[css~="dzfqjckgiaunrj"][css~="dzfqjckgiaunrj"][css~="dzfqjckgiaunrj"][css~="dzfqjckgiaunrj"][css~="dzfqjckgiaunrj"][css~="dzfqjckgiaunrj"] {

                                                        display: flex;
                                                        gap: var(--space--2);
                                                        align-items: center;
                                                      }

/********************************************************************************/

[css~="bnwfmonvdatoom"][css~="bnwfmonvdatoom"][css~="bnwfmonvdatoom"][css~="bnwfmonvdatoom"][css~="bnwfmonvdatoom"][css~="bnwfmonvdatoom"] {

                                                          padding: var(
                                                            --space--2
                                                          );
                                                          display: flex;
                                                          flex-direction: column;
                                                          gap: var(--space--4);
                                                        }

/********************************************************************************/

[css~="hcfollcmgvnsir"][css~="hcfollcmgvnsir"][css~="hcfollcmgvnsir"][css~="hcfollcmgvnsir"][css~="hcfollcmgvnsir"][css~="hcfollcmgvnsir"] {

                                                              font-weight: var(
                                                                --font-weight--bold
                                                              );
                                                            }

/********************************************************************************/

[css~="fawiixudambvkw"][css~="fawiixudambvkw"][css~="fawiixudambvkw"][css~="fawiixudambvkw"][css~="fawiixudambvkw"][css~="fawiixudambvkw"] {

                        font-size: var(--font-size--lg);
                        line-height: var(--line-height--lg);
                      }

/********************************************************************************/

[css~="qfrxziselsezz"][css~="qfrxziselsezz"][css~="qfrxziselsezz"][css~="qfrxziselsezz"][css~="qfrxziselsezz"][css~="qfrxziselsezz"] {

                              display: flex;
                              gap: var(--space--2);
                              align-items: center;
                            }

/********************************************************************************/

[css~="bjomrliaogdxbi"][css~="bjomrliaogdxbi"][css~="bjomrliaogdxbi"][css~="bjomrliaogdxbi"][css~="bjomrliaogdxbi"][css~="bjomrliaogdxbi"] {

                                flex: 1;
                              }

/********************************************************************************/

[css~="gxiggmrdwsathx"][css~="gxiggmrdwsathx"][css~="gxiggmrdwsathx"][css~="gxiggmrdwsathx"][css~="gxiggmrdwsathx"][css~="gxiggmrdwsathx"] {

                                display: flex;
                                gap: var(--space--2);
                              }

/********************************************************************************/

[css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"] {

                                  color: var(--color--teal--600);
                                }

@media (prefers-color-scheme: dark) {

[css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"] {
                                    color: var(--color--teal--500);
                                }
                                  }

[css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"][css~="clshltbuioqvaw"] {
                                  text-align: left;
}

/********************************************************************************/

[css~="fttajjxlanpqkr"][css~="fttajjxlanpqkr"][css~="fttajjxlanpqkr"][css~="fttajjxlanpqkr"][css~="fttajjxlanpqkr"][css~="fttajjxlanpqkr"] {

                                  text-align: left;
                                }

/********************************************************************************/

[css~="etgvpluwdzavpd"][css~="etgvpluwdzavpd"][css~="etgvpluwdzavpd"][css~="etgvpluwdzavpd"][css~="etgvpluwdzavpd"][css~="etgvpluwdzavpd"] {

                                          max-height: var(--space--40);
                                          overflow: auto;
                                          display: flex;
                                          flex-direction: column;
                                          gap: var(--space--2);
                                        }

/********************************************************************************/

[css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"] {

                                font-size: var(--font-size--xs);
                                line-height: var(--line-height--xs);
                                display: flex;
                                flex-wrap: wrap;
                                -moz-column-gap: var(--space--8);
                                     column-gap: var(--space--8);
                                row-gap: var(--space--1);
                              }

[css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"][css~="dceljjifvggtob"] > * {
                                  display: flex;
                                  gap: var(--space--1);
                                }

/********************************************************************************/

[css~="fwrvjowbspiqga"][css~="fwrvjowbspiqga"][css~="fwrvjowbspiqga"][css~="fwrvjowbspiqga"][css~="fwrvjowbspiqga"][css~="fwrvjowbspiqga"] {

                                font-size: var(--font-size--xs);
                                line-height: var(--line-height--xs);
                                display: flex;
                                flex-direction: column;
                                gap: var(--space--2);
                              }

/********************************************************************************/

[css~="fyxbbpzhrktxwl"][css~="fyxbbpzhrktxwl"][css~="fyxbbpzhrktxwl"][css~="fyxbbpzhrktxwl"][css~="fyxbbpzhrktxwl"][css~="fyxbbpzhrktxwl"] {

                                  max-height: var(--space--24);
                                  padding: var(--space--1);
                                  margin: var(--space---1);
                                  overflow: auto;
                                  display: flex;
                                  flex-wrap: wrap;
                                  -moz-column-gap: var(--space--8);
                                       column-gap: var(--space--8);
                                  row-gap: var(--space--1);
                                }

/********************************************************************************/

[css~="fbaroggqwxugmu"][css~="fbaroggqwxugmu"][css~="fbaroggqwxugmu"][css~="fbaroggqwxugmu"][css~="fbaroggqwxugmu"][css~="fbaroggqwxugmu"] {

                                              display: flex;
                                              flex-direction: column;
                                              gap: var(--space--2);
                                            }

/********************************************************************************/

[css~="ebupplpygxbnjv"][css~="ebupplpygxbnjv"][css~="ebupplpygxbnjv"][css~="ebupplpygxbnjv"][css~="ebupplpygxbnjv"][css~="ebupplpygxbnjv"] {

                                                display: flex;
                                                flex-direction: column;
                                                gap: var(--space--2);
                                              }

/********************************************************************************/

[css~="focucabodxwfeg"][css~="focucabodxwfeg"][css~="focucabodxwfeg"][css~="focucabodxwfeg"][css~="focucabodxwfeg"][css~="focucabodxwfeg"] {

                                                  display: flex;
                                                  align-items: center;
                                                  padding: var(--space--0)
                                                    var(--space--2);
                                                }

/********************************************************************************/

[css~="ebxsmbhvdzcjwv"][css~="ebxsmbhvdzcjwv"][css~="ebxsmbhvdzcjwv"][css~="ebxsmbhvdzcjwv"][css~="ebxsmbhvdzcjwv"][css~="ebxsmbhvdzcjwv"] {

                                                    flex: 1;
                                                  }

/********************************************************************************/

[css~="gdlznrayaoliuf"][css~="gdlznrayaoliuf"][css~="gdlznrayaoliuf"][css~="gdlznrayaoliuf"][css~="gdlznrayaoliuf"][css~="gdlznrayaoliuf"] {

                                                        padding: var(--space--0)
                                                          var(--space--2)
                                                          var(--space--2);
                                                      }

/********************************************************************************/

[css~="dyalcxzrppfpju"][css~="dyalcxzrppfpju"][css~="dyalcxzrppfpju"][css~="dyalcxzrppfpju"][css~="dyalcxzrppfpju"][css~="dyalcxzrppfpju"] {

                                                        padding: var(--space--0)
                                                          var(--space--2);
                                                      }

/********************************************************************************/

[css~="gwcydbikdtykew"][css~="gwcydbikdtykew"][css~="gwcydbikdtykew"][css~="gwcydbikdtykew"][css~="gwcydbikdtykew"][css~="gwcydbikdtykew"] {

                                                          display: flex;
                                                          gap: var(--space--2);
                                                          align-items: baseline;
                                                        }

/********************************************************************************/

[css~="fyxzeszybmgwzp"][css~="fyxzeszybmgwzp"][css~="fyxzeszybmgwzp"][css~="fyxzeszybmgwzp"][css~="fyxzeszybmgwzp"][css~="fyxzeszybmgwzp"] {

                                                        height: var(
                                                          --space--40
                                                        );
                                                        overflow: auto;
                                                      }

/********************************************************************************/

[css~="casszpumxckfaj"][css~="casszpumxckfaj"][css~="casszpumxckfaj"][css~="casszpumxckfaj"][css~="casszpumxckfaj"][css~="casszpumxckfaj"] {

                                  display: flex;
                                }

/********************************************************************************/

[css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"] {

                              font-size: var(--font-size--xs);
                              line-height: var(--line-height--xs);
                              max-height: var(--space--24);
                              padding: var(--space--1);
                              margin: var(--space---1);
                              overflow: auto;
                              display: flex;
                              flex-wrap: wrap;
                              -moz-column-gap: var(--space--8);
                                   column-gap: var(--space--8);
                              row-gap: var(--space--1);
                            }

[css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"][css~="dmkdajsatcxjwo"] > * {
                                display: flex;
                                gap: var(--space--1);
                              }

/********************************************************************************/

[css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"] {

                          flex: 1;
                          padding-right: var(--space--4);
                          padding-left: var(--space--4);
                        }

@media (min-width: 900px) {

[css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"] {
                            padding-left: var(--space--8)
                        }
                          }

[css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"] {
                          overflow: auto;
                          display: flex;
}

@media (max-width: 899px) {

[css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"][css~="fwilwdqkalsets"] {
                            justify-content: center
                        }
                          }

/********************************************************************************/

[css~="hblbkuoeduxeds"][css~="hblbkuoeduxeds"][css~="hblbkuoeduxeds"][css~="hblbkuoeduxeds"][css~="hblbkuoeduxeds"][css~="hblbkuoeduxeds"] {

                            flex: 1;
                            min-width: var(--width--0);
                            max-width: var(--width--prose);
                          }

/********************************************************************************/

[css~="eqhapkbtfjqzjv"][css~="eqhapkbtfjqzjv"][css~="eqhapkbtfjqzjv"][css~="eqhapkbtfjqzjv"][css~="eqhapkbtfjqzjv"][css~="eqhapkbtfjqzjv"] {

                                padding: var(--space--4) var(--space--0);
                                display: flex;
                                flex-direction: column;
                                gap: var(--space--4);
                                align-items: center;
                              }

/********************************************************************************/

[css~="sutlevoacwahg"][css~="sutlevoacwahg"][css~="sutlevoacwahg"][css~="sutlevoacwahg"][css~="sutlevoacwahg"][css~="sutlevoacwahg"] {

                                    padding: var(--space--4) var(--space--0);
                                  }

/********************************************************************************/

[css~="ndzlwmjqpfbpj"][css~="ndzlwmjqpfbpj"][css~="ndzlwmjqpfbpj"][css~="ndzlwmjqpfbpj"][css~="ndzlwmjqpfbpj"][css~="ndzlwmjqpfbpj"] {

                                        display: flex;
                                        justify-content: center;
                                      }

/********************************************************************************/

[css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"] {

                                          border-bottom: var(--border-width--4)
                                            solid var(--color--zinc--200);
                                        }

@media (prefers-color-scheme: dark) {

[css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"][css~="gdqkjhbphfqlww"] {
                                            border-color: var(
                                              --color--zinc--700
                                            )
                                        }
                                          }

/********************************************************************************/

[css~="daokuaahrtowqi"][css~="daokuaahrtowqi"][css~="daokuaahrtowqi"][css~="daokuaahrtowqi"][css~="daokuaahrtowqi"][css~="daokuaahrtowqi"] {

                                              width: calc(
                                                var(--space--2) + 100% +
                                                  var(--space--2)
                                              );
                                              padding: var(--space--1-5)
                                                var(--space--2);
                                              margin: var(--space--0)
                                                var(--space---2);
                                              display: flex;
                                              gap: var(--space--4);
                                              align-items: center;
                                            }

/********************************************************************************/

[css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"] {

                                                flex: 1;
                                                border-color: var(
                                                  --color--rose--600
                                                );
                                              }

@media (prefers-color-scheme: dark) {

[css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"][css~="cbcmtkoeszwqf"] {
                                                  border-color: var(
                                                    --color--rose--500
                                                  )
                                              }
                                                }

/********************************************************************************/

[css~="hkhgcfppjnpilo"][css~="hkhgcfppjnpilo"][css~="hkhgcfppjnpilo"][css~="hkhgcfppjnpilo"][css~="hkhgcfppjnpilo"][css~="hkhgcfppjnpilo"] {

                                              margin: var(--space--2)
                                                var(--space--0);
                                              display: flex;
                                              gap: var(--space--4);
                                              align-items: center;
                                            }

/********************************************************************************/

[css~="poceesjzqlmpt"][css~="poceesjzqlmpt"][css~="poceesjzqlmpt"][css~="poceesjzqlmpt"][css~="poceesjzqlmpt"][css~="poceesjzqlmpt"] {

                                                flex: 1;
                                              }

/********************************************************************************/

[css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"] {

                                        padding: var(--space--2);
                                        border-radius: var(--border-radius--lg);
                                        margin: var(--space--0) var(--space---2);
                                        display: flex;
                                        gap: var(--space--4);
                                        --message--highlight--background-color: var(
                                          --color--amber--200
                                        );
                                      }

@media (prefers-color-scheme: dark) {

[css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"][css~="dpjasevnslrwsm"] {
                                          --message--highlight--background-color: var(
                                            --color--amber--900
                                          )
                                      }
                                        }

@keyframes message--highlight {
                                          from {
                                            background-color: var(
                                              --message--highlight--background-color
                                            );
                                          }
                                          to {
                                            background-color: transparent;
                                          }
                                        }

/********************************************************************************/

[css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"] {

                                            transition-property: var(
                                              --transition-property--colors
                                            );
                                            transition-duration: var(
                                              --transition-duration--150
                                            );
                                            transition-timing-function: var(
                                              --transition-timing-function--in-out
                                            );
                                          }

[css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"]:hover,
                                            [css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"]:focus-within {
                                              background-color: var(
                                                --color--zinc--100
                                              );
                                            }

@media (prefers-color-scheme: dark) {

[css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"]:hover,
                                            [css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"][css~="exikvjnwwhleqw"]:focus-within {
                                                background-color: var(
                                                  --color--zinc--800
                                                )
                                            }
                                              }

/********************************************************************************/

[css~="dvcmuidfsgmmr"][css~="dvcmuidfsgmmr"][css~="dvcmuidfsgmmr"][css~="dvcmuidfsgmmr"][css~="dvcmuidfsgmmr"][css~="dvcmuidfsgmmr"] {

                                            padding-bottom: var(--space--4);
                                          }

/********************************************************************************/

[css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"] {

                                                color: var(--color--rose--700);
                                                background-color: var(
                                                  --color--rose--100
                                                );
                                              }

@media (prefers-color-scheme: dark) {

[css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"] {
                                                  color: var(
                                                    --color--rose--200
                                                  );
                                                  background-color: var(
                                                    --color--rose--950
                                                  )
                                              }
                                                }

[css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"] {
                                                padding: var(--space--2)
                                                  var(--space--0);
                                                border-radius: var(
                                                  --border-radius--base
                                                );
                                                writing-mode: vertical-lr;
                                                transform: rotate(180deg);
                                                justify-content: flex-end;
}

[css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"][css~="dgfmijosvagelw"] .bi {
                                                  transform: rotate(90deg);
                                                }

/********************************************************************************/

[css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"] {

                                                  color: var(
                                                    --color--emerald--700
                                                  );
                                                  background-color: var(
                                                    --color--emerald--100
                                                  );
                                                }

@media (prefers-color-scheme: dark) {

[css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"] {
                                                    color: var(
                                                      --color--emerald--200
                                                    );
                                                    background-color: var(
                                                      --color--emerald--950
                                                    )
                                                }
                                                  }

[css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"] {
                                                  padding: var(--space--2)
                                                    var(--space--0);
                                                  border-radius: var(
                                                    --border-radius--base
                                                  );
                                                  writing-mode: vertical-lr;
                                                  transform: rotate(180deg);
                                                  justify-content: flex-end;
}

[css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"][css~="dhgmnxytqcurrr"] .bi {
                                                    transform: rotate(90deg);
                                                  }

/********************************************************************************/

[css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"] {

                                                    color: var(
                                                      --color--rose--700
                                                    );
                                                    background-color: var(
                                                      --color--rose--100
                                                    );
                                                  }

@media (prefers-color-scheme: dark) {

[css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"] {
                                                      color: var(
                                                        --color--rose--200
                                                      );
                                                      background-color: var(
                                                        --color--rose--950
                                                      )
                                                  }
                                                    }

[css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"] {
                                                    padding: var(--space--2)
                                                      var(--space--0);
                                                    border-radius: var(
                                                      --border-radius--base
                                                    );
                                                    writing-mode: vertical-lr;
                                                    transform: rotate(180deg);
                                                    justify-content: flex-end;
}

[css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"][css~="bnbivlvmnicobx"] .bi {
                                                      transform: rotate(90deg);
                                                    }

/********************************************************************************/

[css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"] {

                                                      color: var(
                                                        --color--sky--700
                                                      );
                                                      background-color: var(
                                                        --color--sky--100
                                                      );
                                                    }

@media (prefers-color-scheme: dark) {

[css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"] {
                                                        color: var(
                                                          --color--sky--200
                                                        );
                                                        background-color: var(
                                                          --color--sky--950
                                                        )
                                                    }
                                                      }

[css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"] {
                                                      padding: var(--space--2)
                                                        var(--space--0);
                                                      border-radius: var(
                                                        --border-radius--base
                                                      );
                                                      writing-mode: vertical-lr;
                                                      transform: rotate(180deg);
                                                      justify-content: flex-end;
}

[css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"][css~="qtushguyfsyid"] .bi {
                                                        transform: rotate(
                                                          90deg
                                                        );
                                                      }

/********************************************************************************/

[css~="dbzmhsulfmlahz"][css~="dbzmhsulfmlahz"][css~="dbzmhsulfmlahz"][css~="dbzmhsulfmlahz"][css~="dbzmhsulfmlahz"][css~="dbzmhsulfmlahz"] {

                                          flex: 1;
                                          max-width: 100%;
                                          display: flex;
                                          flex-direction: column;
                                        }

/********************************************************************************/

[css~="plhdmtzsaufzs"][css~="plhdmtzsaufzs"][css~="plhdmtzsaufzs"][css~="plhdmtzsaufzs"][css~="plhdmtzsaufzs"][css~="plhdmtzsaufzs"] {

                                              gap: var(--space--1);
                                            }

/********************************************************************************/

[css~="nlpntsmspxkmy"][css~="nlpntsmspxkmy"][css~="nlpntsmspxkmy"][css~="nlpntsmspxkmy"][css~="nlpntsmspxkmy"][css~="nlpntsmspxkmy"] {

                                              gap: var(--space--2);
                                            }

/********************************************************************************/

[css~="ezuzgvxtesdhyj"][css~="ezuzgvxtesdhyj"][css~="ezuzgvxtesdhyj"][css~="ezuzgvxtesdhyj"][css~="ezuzgvxtesdhyj"][css~="ezuzgvxtesdhyj"] {

                                                  font-size: var(
                                                    --font-size--xs
                                                  );
                                                  line-height: var(
                                                    --line-height--xs
                                                  );
                                                }

/********************************************************************************/

[css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"] {

                                                      transition-property: var(
                                                        --transition-property--opacity
                                                      );
                                                      transition-duration: var(
                                                        --transition-duration--150
                                                      );
                                                      transition-timing-function: var(
                                                        --transition-timing-function--in-out
                                                      );
                                                    }

[key^="message/"]:not(
                                                          :hover,
                                                          :focus-within
                                                        )
                                                        [css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"][css~="beszyxhlxwoqb"] {
                                                        opacity: var(
                                                          --opacity--0
                                                        );
                                                      }

/********************************************************************************/

[css~="exorutragsxyry"][css~="exorutragsxyry"][css~="exorutragsxyry"][css~="exorutragsxyry"][css~="exorutragsxyry"][css~="exorutragsxyry"] {

                                                              display: flex;
                                                              gap: var(
                                                                --space--2
                                                              );
                                                              align-items: center;
                                                            }

/********************************************************************************/

[css~="dklncgotedsgkn"][css~="dklncgotedsgkn"][css~="dklncgotedsgkn"][css~="dklncgotedsgkn"][css~="dklncgotedsgkn"][css~="dklncgotedsgkn"] {

                                                      font-size: var(
                                                        --font-size--xs
                                                      );
                                                      line-height: var(
                                                        --line-height--xs
                                                      );
                                                      display: flex;
                                                      gap: var(--space--4);
                                                    }

/********************************************************************************/

[css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"] {

                                                        flex: 1;
                                                        display: flex;
                                                        flex-wrap: wrap;
                                                        -moz-column-gap: var(
                                                          --space--8
                                                        );
                                                             column-gap: var(
                                                          --space--8
                                                        );
                                                        row-gap: var(
                                                          --space--1
                                                        );
                                                      }

[css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"][css~="croujvwmvmzhxb"] > * {
                                                          display: flex;
                                                          gap: var(--space--1);
                                                        }

/********************************************************************************/

[css~="hhykyxjuyzmulw"][css~="hhykyxjuyzmulw"][css~="hhykyxjuyzmulw"][css~="hhykyxjuyzmulw"][css~="hhykyxjuyzmulw"][css~="hhykyxjuyzmulw"] {

                                                display: flex;
                                                gap: var(--space--2);
                                              }

/********************************************************************************/

[css~="fuijldqeunbfgg"][css~="fuijldqeunbfgg"][css~="fuijldqeunbfgg"][css~="fuijldqeunbfgg"][css~="fuijldqeunbfgg"][css~="fuijldqeunbfgg"] {

                                                  font-size: var(
                                                    --font-size--xs
                                                  );
                                                  line-height: var(
                                                    --line-height--xs
                                                  );
                                                  flex: 1;
                                                  display: flex;
                                                  flex-wrap: wrap;
                                                  align-items: baseline;
                                                  -moz-column-gap: var(--space--4);
                                                       column-gap: var(--space--4);
                                                  row-gap: var(--space--2);
                                                }

/********************************************************************************/

[css~="gelpkwxgucbedg"][css~="gelpkwxgucbedg"][css~="gelpkwxgucbedg"][css~="gelpkwxgucbedg"][css~="gelpkwxgucbedg"][css~="gelpkwxgucbedg"] {

                                                    font-size: var(
                                                      --font-size--sm
                                                    );
                                                    line-height: var(
                                                      --line-height--sm
                                                    );
                                                  }

/********************************************************************************/

[css~="pahmxujhcolja"][css~="pahmxujhcolja"][css~="pahmxujhcolja"][css~="pahmxujhcolja"][css~="pahmxujhcolja"][css~="pahmxujhcolja"] {

                                            display: flex;
                                            flex-direction: column;
                                            gap: var(--space--2);
                                          }

/********************************************************************************/

[css~="fwgkdjgfhteidl"][css~="fwgkdjgfhteidl"][css~="fwgkdjgfhteidl"][css~="fwgkdjgfhteidl"][css~="fwgkdjgfhteidl"][css~="fwgkdjgfhteidl"] {

                                              position: relative;
                                            }

/********************************************************************************/

[css~="fgpcincillrail"][css~="fgpcincillrail"][css~="fgpcincillrail"][css~="fgpcincillrail"][css~="fgpcincillrail"][css~="fgpcincillrail"] {

                                                width: var(--space--0);
                                                height: var(--line-height--sm);
                                                position: absolute;
                                              }

/********************************************************************************/

[css~="cgtxelkkndswbo"][css~="cgtxelkkndswbo"][css~="cgtxelkkndswbo"][css~="cgtxelkkndswbo"][css~="cgtxelkkndswbo"][css~="cgtxelkkndswbo"] {

                                                    display: flex;
                                                    gap: var(--space--1);
                                                  }

/********************************************************************************/

[css~="dcwagxljlugwze"][css~="dcwagxljlugwze"][css~="dcwagxljlugwze"][css~="dcwagxljlugwze"][css~="dcwagxljlugwze"][css~="dcwagxljlugwze"] {

                                                                        display: flex;
                                                                        gap: var(
                                                                          --space--2
                                                                        );
                                                                        align-items: center;
                                                                      }

/********************************************************************************/

[css~="bkvtqsumdyzhcq"][css~="bkvtqsumdyzhcq"][css~="bkvtqsumdyzhcq"][css~="bkvtqsumdyzhcq"][css~="bkvtqsumdyzhcq"][css~="bkvtqsumdyzhcq"] {

                                                                display: flex;
                                                                gap: var(
                                                                  --space--2
                                                                );
                                                                align-items: center;
                                                              }

/********************************************************************************/

[css~="bzgopsiwtaijaf"][css~="bzgopsiwtaijaf"][css~="bzgopsiwtaijaf"][css~="bzgopsiwtaijaf"][css~="bzgopsiwtaijaf"][css~="bzgopsiwtaijaf"] {

                                                      font-size: var(
                                                        --font-size--xs
                                                      );
                                                      line-height: var(
                                                        --line-height--xs
                                                      );
                                                      display: flex;
                                                      flex-wrap: wrap;
                                                      -moz-column-gap: var(
                                                        --space--8
                                                      );
                                                           column-gap: var(
                                                        --space--8
                                                      );
                                                      row-gap: var(--space--1);
                                                    }

/********************************************************************************/

[css~="dvzelyarnmqtzd"][css~="dvzelyarnmqtzd"][css~="dvzelyarnmqtzd"][css~="dvzelyarnmqtzd"][css~="dvzelyarnmqtzd"][css~="dvzelyarnmqtzd"] {

                                              display: flex;
                                              gap: var(--space--2);
                                              justify-content: center;
                                            }

/********************************************************************************/

[css~="nfijvxorhlymx"][css~="nfijvxorhlymx"][css~="nfijvxorhlymx"][css~="nfijvxorhlymx"][css~="nfijvxorhlymx"][css~="nfijvxorhlymx"] {

                                  opacity: var(--opacity--50);
                                }

/********************************************************************************/

[css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"] {

                                      border-bottom: var(--border-width--4)
                                        solid var(--color--zinc--200);
                                    }

@media (prefers-color-scheme: dark) {

[css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"][css~="eklazqggjnkvfr"] {
                                        border-color: var(--color--zinc--700)
                                    }
                                      }

/********************************************************************************/

[css~="bevgkoaqfthmon"][css~="bevgkoaqfthmon"][css~="bevgkoaqfthmon"][css~="bevgkoaqfthmon"][css~="bevgkoaqfthmon"][css~="bevgkoaqfthmon"] {

                                    padding: var(--space--2);
                                    border-radius: var(--border-radius--lg);
                                    margin: var(--space--0) var(--space---2);
                                    display: flex;
                                    flex-direction: column;
                                  }

/********************************************************************************/

[css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"] {

                                        gap: var(--space--1);
                                        transition-property: var(
                                          --transition-property--colors
                                        );
                                        transition-duration: var(
                                          --transition-duration--150
                                        );
                                        transition-timing-function: var(
                                          --transition-timing-function--in-out
                                        );
                                      }

[css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"]:hover,
                                        [css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"]:focus-within {
                                          background-color: var(
                                            --color--zinc--100
                                          );
                                        }

@media (prefers-color-scheme: dark) {

[css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"]:hover,
                                        [css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"][css~="fnplulpmpsrkch"]:focus-within {
                                            background-color: var(
                                              --color--zinc--800
                                            )
                                        }
                                          }

/********************************************************************************/

[css~="bjcewwkvgfkshc"][css~="bjcewwkvgfkshc"][css~="bjcewwkvgfkshc"][css~="bjcewwkvgfkshc"][css~="bjcewwkvgfkshc"][css~="bjcewwkvgfkshc"] {

                                        padding-bottom: var(--space--4);
                                        gap: var(--space--2);
                                      }

/********************************************************************************/

[css~="cmddavrabkbtmt"][css~="cmddavrabkbtmt"][css~="cmddavrabkbtmt"][css~="cmddavrabkbtmt"][css~="cmddavrabkbtmt"][css~="cmddavrabkbtmt"] {

                                      display: flex;
                                      gap: var(--space--2);
                                    }

/********************************************************************************/

[css~="ejvroiiyxfmvit"][css~="ejvroiiyxfmvit"][css~="ejvroiiyxfmvit"][css~="ejvroiiyxfmvit"][css~="ejvroiiyxfmvit"][css~="ejvroiiyxfmvit"] {

                                        font-size: var(--font-size--xs);
                                        line-height: var(--line-height--xs);
                                        flex: 1;
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: baseline;
                                        -moz-column-gap: var(--space--4);
                                             column-gap: var(--space--4);
                                        row-gap: var(--space--2);
                                      }

/********************************************************************************/

[css~="dipmcbwydmomaa"][css~="dipmcbwydmomaa"][css~="dipmcbwydmomaa"][css~="dipmcbwydmomaa"][css~="dipmcbwydmomaa"][css~="dipmcbwydmomaa"] {

                                          font-size: var(--font-size--sm);
                                          line-height: var(--line-height--sm);
                                        }

/********************************************************************************/

[css~="fqpeaihrppdzua"][css~="fqpeaihrppdzua"][css~="fqpeaihrppdzua"][css~="fqpeaihrppdzua"][css~="fqpeaihrppdzua"][css~="fqpeaihrppdzua"] {

                                                font-size: var(--font-size--sm);
                                                line-height: var(
                                                  --line-height--sm
                                                );
                                              }

/********************************************************************************/

[css~="ezjrwjvhgqcqzb"][css~="ezjrwjvhgqcqzb"][css~="ezjrwjvhgqcqzb"][css~="ezjrwjvhgqcqzb"][css~="ezjrwjvhgqcqzb"][css~="ezjrwjvhgqcqzb"] {

                                      white-space: pre-line;
                                    }

/********************************************************************************/

[css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"] {

                      padding-right: var(--space--4);
                      padding-bottom: var(--space--4);
                      padding-left: var(--space--4);
                    }

@media (min-width: 900px) {

[css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"] {
                        padding-left: var(--space--8)
                    }
                      }

[css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"] {
                      display: flex;
}

@media (max-width: 899px) {

[css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"][css~="bywfdthigvxhvr"] {
                        justify-content: center
                    }
                      }

/********************************************************************************/

[css~="ckozermpnvxqjq"][css~="ckozermpnvxqjq"][css~="ckozermpnvxqjq"][css~="ckozermpnvxqjq"][css~="ckozermpnvxqjq"][css~="ckozermpnvxqjq"] {

                      padding-top: var(--space--4);
                    }

/********************************************************************************/

[css~="hbdozxuxwazji"][css~="hbdozxuxwazji"][css~="hbdozxuxwazji"][css~="hbdozxuxwazji"][css~="hbdozxuxwazji"][css~="hbdozxuxwazji"] {

                    display: flex;
                    flex-direction: column;
                  }

/********************************************************************************/

[css~="eaabfwjgiccou"][css~="eaabfwjgiccou"][css~="eaabfwjgiccou"][css~="eaabfwjgiccou"][css~="eaabfwjgiccou"][css~="eaabfwjgiccou"] {

                        gap: var(--space--2);
                        flex: 1;
                        min-width: var(--width--0);
                        max-width: var(--width--prose);
                      }

/********************************************************************************/

[css~="cgvletrlrhqkpv"][css~="cgvletrlrhqkpv"][css~="cgvletrlrhqkpv"][css~="cgvletrlrhqkpv"][css~="cgvletrlrhqkpv"][css~="cgvletrlrhqkpv"] {

                        gap: var(--space--4);
                      }

/********************************************************************************/

[css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"] {

                        display: grid;
                      }

[css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"][css~="fqmatsobxzhbft"] > * {
                          grid-area: 1 / 1;
                        }

/********************************************************************************/

[css~="ekchwockjxkbjo"][css~="ekchwockjxkbjo"][css~="ekchwockjxkbjo"][css~="ekchwockjxkbjo"][css~="ekchwockjxkbjo"][css~="ekchwockjxkbjo"] {

                                position: relative;
                                place-self: end;
                                width: var(--font-size--2xl);
                                height: var(--font-size--2xl);
                                padding: var(--space--0);
                                border-radius: var(--border-radius--circle);
                                margin: var(--space--1);
                                align-items: center;
                              }

/********************************************************************************/

[css~="vtsfirxfgbeaa"][css~="vtsfirxfgbeaa"][css~="vtsfirxfgbeaa"][css~="vtsfirxfgbeaa"][css~="vtsfirxfgbeaa"][css~="vtsfirxfgbeaa"] {

                                  position: relative;
                                  top: var(--space--px);
                                  right: var(--space--px);
                                }

/********************************************************************************/

[css~="cmsbstsvfqqfhg"][css~="cmsbstsvfqqfhg"][css~="cmsbstsvfqqfhg"][css~="cmsbstsvfqqfhg"][css~="cmsbstsvfqqfhg"][css~="cmsbstsvfqqfhg"] {

                              font-size: var(--font-size--xs);
                              line-height: var(--line-height--xs);
                            }

/********************************************************************************/

[css~="gimvswvtvepetq"][css~="gimvswvtvepetq"][css~="gimvswvtvepetq"][css~="gimvswvtvepetq"][css~="gimvswvtvepetq"][css~="gimvswvtvepetq"] {

                                margin-left: var(--space--1);
                                margin-right: var(--space--2);
                              }

/********************************************************************************/

[css~="biwgniuwbkcntv"][css~="biwgniuwbkcntv"][css~="biwgniuwbkcntv"][css~="biwgniuwbkcntv"][css~="biwgniuwbkcntv"][css~="biwgniuwbkcntv"] {

                              display: flex;
                            }

/********************************************************************************/

[css~="ecdagocecvvybf"][css~="ecdagocecvvybf"][css~="ecdagocecvvybf"][css~="ecdagocecvvybf"][css~="ecdagocecvvybf"][css~="ecdagocecvvybf"] {

                                      margin-left: var(--space--1);
                                    }

/********************************************************************************/

[css~="ctyrvsvcxydsgd"][css~="ctyrvsvcxydsgd"][css~="ctyrvsvcxydsgd"][css~="ctyrvsvcxydsgd"][css~="ctyrvsvcxydsgd"][css~="ctyrvsvcxydsgd"] {

                              display: flex;
                              -moz-column-gap: var(--space--4);
                                   column-gap: var(--space--4);
                              row-gap: var(--space--2);
                              flex-wrap: wrap;
                            }

/********************************************************************************/

[css~="dvbmwhhxqhdlja"][css~="dvbmwhhxqhdlja"][css~="dvbmwhhxqhdlja"][css~="dvbmwhhxqhdlja"][css~="dvbmwhhxqhdlja"][css~="dvbmwhhxqhdlja"] {

                                      display: flex;
                                      gap: var(--space--2);
                                      align-items: center;
                                    }

/********************************************************************************/

[css~="hffanmzlfmpgfs"][css~="hffanmzlfmpgfs"][css~="hffanmzlfmpgfs"][css~="hffanmzlfmpgfs"][css~="hffanmzlfmpgfs"][css~="hffanmzlfmpgfs"] {

                display: flex;
                flex-direction: column;
                gap: var(--space--4);
                align-items: center;
              }

/********************************************************************************/

[css~="ezdrozqlegfzgy"][css~="ezdrozqlegfzgy"][css~="ezdrozqlegfzgy"][css~="ezdrozqlegfzgy"][css~="ezdrozqlegfzgy"][css~="ezdrozqlegfzgy"] {

                                    padding: var(--space--2);
                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--space--4);
                                  }

/********************************************************************************/

[css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"] {

                display: flex;
                gap: var(--space--2);
              }

[css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"][css~="cljiczfjbxqwvr"] > * {
                  flex: 1;
                }

/********************************************************************************/

[css~="gdwficbxtzfhhn"][css~="gdwficbxtzfhhn"][css~="gdwficbxtzfhhn"][css~="gdwficbxtzfhhn"][css~="gdwficbxtzfhhn"][css~="gdwficbxtzfhhn"] {

        display: flex;
        gap: var(--space--2);
        align-items: baseline;
      }

/********************************************************************************/

[css~="cihrttlbkvwkdf"][css~="cihrttlbkvwkdf"][css~="cihrttlbkvwkdf"][css~="cihrttlbkvwkdf"][css~="cihrttlbkvwkdf"][css~="cihrttlbkvwkdf"] {

            cursor: default;
          }

/********************************************************************************/

[css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"] {

                color: var(--color--accent-color--700);
                background-color: var(--color--accent-color--100);
              }

@media (prefers-color-scheme: dark) {

[css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"][css~="gvsrqvhqecxnt"] {
                  color: var(--color--accent-color--200);
                  background-color: var(--color--accent-color--800)
              }
                }

/********************************************************************************/

[css~="hjdexpdnlkmnkr"][css~="hjdexpdnlkmnkr"][css~="hjdexpdnlkmnkr"][css~="hjdexpdnlkmnkr"][css~="hjdexpdnlkmnkr"][css~="hjdexpdnlkmnkr"] {

            font-size: var(--font-size--xs);
            line-height: var(--line-height--xs);
          }

/********************************************************************************/

[css~="cjmoebureduyfd"][css~="cjmoebureduyfd"][css~="cjmoebureduyfd"][css~="cjmoebureduyfd"][css~="cjmoebureduyfd"][css~="cjmoebureduyfd"] {

            font-size: var(--font-size--xs);
            line-height: var(--line-height--xs);
            justify-content: center;
          }

/********************************************************************************/

[css~="banmgpqfitgwbj"][css~="banmgpqfitgwbj"][css~="banmgpqfitgwbj"][css~="banmgpqfitgwbj"][css~="banmgpqfitgwbj"][css~="banmgpqfitgwbj"] {

        font-size: var(--font-size--2xs);
        line-height: var(--line-height--2xs);
        display: inline-flex;
        gap: var(--space--1);
      }

/********************************************************************************/

[css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"] {

                  display: flex;
                  gap: var(--space--2);
                }

[css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"][css~="czvrwcyhvappux"] > * {
                    flex: 1;
                  }

/********************************************************************************/

[css~="fnezksydnshzpv"][css~="fnezksydnshzpv"][css~="fnezksydnshzpv"][css~="fnezksydnshzpv"][css~="fnezksydnshzpv"][css~="fnezksydnshzpv"] {

            width: calc(var(--space--96) + var(--space--12));
          }

/********************************************************************************/

[key^="tag/"].removed [css~="csyssycivjilrs"][css~="csyssycivjilrs"][css~="csyssycivjilrs"][css~="csyssycivjilrs"][css~="csyssycivjilrs"][css~="csyssycivjilrs"] {
              display: none;
            }

/********************************************************************************/

[css~="elgsiggrfniapl"][css~="elgsiggrfniapl"][css~="elgsiggrfniapl"][css~="elgsiggrfniapl"][css~="elgsiggrfniapl"][css~="elgsiggrfniapl"] {

                          padding: var(--space--2) var(--space--0);
                          display: flex;
                          flex-direction: column;
                          gap: var(--space--4);
                        }

/********************************************************************************/

[css~="anmrdwtvoabnx"][css~="anmrdwtvoabnx"][css~="anmrdwtvoabnx"][css~="anmrdwtvoabnx"][css~="anmrdwtvoabnx"][css~="anmrdwtvoabnx"] {

                              font-weight: var(--font-weight--bold);
                            }

/********************************************************************************/

[key^="tag/"]:not(.removed) [css~="cutxfcxbmlbqsu"][css~="cutxfcxbmlbqsu"][css~="cutxfcxbmlbqsu"][css~="cutxfcxbmlbqsu"][css~="cutxfcxbmlbqsu"][css~="cutxfcxbmlbqsu"] {
              display: none;
            }

/********************************************************************************/

[css~="daruidbziehvo"][css~="daruidbziehvo"][css~="daruidbziehvo"][css~="daruidbziehvo"][css~="daruidbziehvo"][css~="daruidbziehvo"] {

                      visibility: hidden;
                    }

/********************************************************************************/

[css~="quwkybsojegoq"][css~="quwkybsojegoq"][css~="quwkybsojegoq"][css~="quwkybsojegoq"][css~="quwkybsojegoq"][css~="quwkybsojegoq"] {

                    display: flex;
                    flex-direction: column;
                    gap: var(--space--2);
                    align-items: center;
                  }

/********************************************************************************/

[css~="egzftebtjeagkr"][css~="egzftebtjeagkr"][css~="egzftebtjeagkr"][css~="egzftebtjeagkr"][css~="egzftebtjeagkr"][css~="egzftebtjeagkr"] {

                display: flex;
                flex-direction: column;
                gap: var(--space--2);
              }

/********************************************************************************/

[css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"] {

                  display: flex;
                  flex-direction: column;
                  gap: var(--space--2);
                }

[css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"] [key^="tag/"] {
                    display: flex;
                    gap: var(--space--2);
                    align-items: center;

                    transition-property: var(--transition-property--opacity);
                    transition-duration: var(--transition-duration--150);
                    transition-timing-function: var(
                      --transition-timing-function--in-out
                    );
                  }

[css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"] [key^="tag/"].grabbed {
                      opacity: var(--opacity--50);
                    }

[css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"][css~="cjzukplhtemihu"] [key^="tag/"] [key="tag--grab--handle"]:not(.disabled) {
                      cursor: grab;
                    }

/********************************************************************************/

[css~="byfovtywvosdoc"][css~="byfovtywvosdoc"][css~="byfovtywvosdoc"][css~="byfovtywvosdoc"][css~="byfovtywvosdoc"][css~="byfovtywvosdoc"] {

                  display: flex;
                  justify-content: center;
                }

/********************************************************************************/

[css~="fvevlbkrqtfoxs"][css~="fvevlbkrqtfoxs"][css~="fvevlbkrqtfoxs"][css~="fvevlbkrqtfoxs"][css~="fvevlbkrqtfoxs"][css~="fvevlbkrqtfoxs"] {

                                padding: var(--space--2);
                                display: flex;
                                flex-direction: column;
                                gap: var(--space--2);
                              }

/********************************************************************************/

[css~="ellkrtlmcvgdii"][css~="ellkrtlmcvgdii"][css~="ellkrtlmcvgdii"][css~="ellkrtlmcvgdii"][css~="ellkrtlmcvgdii"][css~="ellkrtlmcvgdii"] {

                    height: var(--space--32);
                  }

/********************************************************************************/

[css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"] {

                          padding-top: var(--space--4);
                          border-top: var(--border-width--1) solid
                            var(--color--zinc--200);
                        }

@media (prefers-color-scheme: dark) {

[css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"] {
                            border-color: var(--color--zinc--700);
                        }
                          }

[css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"][css~="ephbigrlamqxfm"] {
                          display: flex;
                          gap: var(--space--2);
}

/********************************************************************************/

[css~="cksmkerxbdpxfe"][css~="cksmkerxbdpxfe"][css~="cksmkerxbdpxfe"][css~="cksmkerxbdpxfe"][css~="cksmkerxbdpxfe"][css~="cksmkerxbdpxfe"] {

                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            gap: var(--space--2);
                          }

/********************************************************************************/

[css~="evuzstfjgkvznn"][css~="evuzstfjgkvznn"][css~="evuzstfjgkvznn"][css~="evuzstfjgkvznn"][css~="evuzstfjgkvznn"][css~="evuzstfjgkvznn"] {

                                                  display: flex;
                                                  flex-direction: column;
                                                  gap: var(--space--2);
                                                }

/********************************************************************************/

[css~="gorrwqrzrybahu"][css~="gorrwqrzrybahu"][css~="gorrwqrzrybahu"][css~="gorrwqrzrybahu"][css~="gorrwqrzrybahu"][css~="gorrwqrzrybahu"] {

                                                          display: flex;
                                                          gap: var(--space--2);
                                                          justify-content: center;
                                                        }

/********************************************************************************/

[css~="epdddeefkcbztg"][css~="epdddeefkcbztg"][css~="epdddeefkcbztg"][css~="epdddeefkcbztg"][css~="epdddeefkcbztg"][css~="epdddeefkcbztg"] {

                                                    display: flex;
                                                    gap: var(--space--2);
                                                    align-items: center;
                                                  }

/********************************************************************************/

[css~="bwkvsjpxuljqli"][css~="bwkvsjpxuljqli"][css~="bwkvsjpxuljqli"][css~="bwkvsjpxuljqli"][css~="bwkvsjpxuljqli"][css~="bwkvsjpxuljqli"] {

                                                      flex: 1;
                                                    }

/********************************************************************************/

[css~="goitgzfnvdmsbb"][css~="goitgzfnvdmsbb"][css~="goitgzfnvdmsbb"][css~="goitgzfnvdmsbb"][css~="goitgzfnvdmsbb"][css~="goitgzfnvdmsbb"] {

                                      text-align: left;
                                      display: flex;
                                      flex-direction: column;
                                      align-items: flex-start;
                                      gap: var(--space--0);
                                    }

/********************************************************************************/

[css~="eqtkfwuqoetxhi"][css~="eqtkfwuqoetxhi"][css~="eqtkfwuqoetxhi"][css~="eqtkfwuqoetxhi"][css~="eqtkfwuqoetxhi"][css~="eqtkfwuqoetxhi"] {

                                        display: flex;
                                        align-items: baseline;
                                        gap: var(--space--2);
                                      }

/********************************************************************************/

[css~="bzcdlrmjizkefn"][css~="bzcdlrmjizkefn"][css~="bzcdlrmjizkefn"][css~="bzcdlrmjizkefn"][css~="bzcdlrmjizkefn"][css~="bzcdlrmjizkefn"] {

                              display: flex;
                              flex-wrap: wrap;
                              gap: var(--space--2);
                            }

/********************************************************************************/

[css~="dafezomfyoyues"][css~="dafezomfyoyues"][css~="dafezomfyoyues"][css~="dafezomfyoyues"][css~="dafezomfyoyues"][css~="dafezomfyoyues"] {

                                width: var(--space--36);
                                display: flex;
                                justify-content: flex-start;
                              }

/********************************************************************************/

[css~="fmhcluzwdbifdk"][css~="fmhcluzwdbifdk"][css~="fmhcluzwdbifdk"][css~="fmhcluzwdbifdk"][css~="fmhcluzwdbifdk"][css~="fmhcluzwdbifdk"] {

                                width: var(--space--40);
                                display: flex;
                                justify-content: flex-start;
                              }

/********************************************************************************/

[css~="eplyzaqmwuibgp"][css~="eplyzaqmwuibgp"][css~="eplyzaqmwuibgp"][css~="eplyzaqmwuibgp"][css~="eplyzaqmwuibgp"][css~="eplyzaqmwuibgp"] {

                                      gap: var(--space--2);
                                    }

/********************************************************************************/

[css~="hciuuqckgfecuw"][css~="hciuuqckgfecuw"][css~="hciuuqckgfecuw"][css~="hciuuqckgfecuw"][css~="hciuuqckgfecuw"][css~="hciuuqckgfecuw"] {

                                            cursor: default;
                                          }

/********************************************************************************/

[css~="cjplwtvlsqapkg"][css~="cjplwtvlsqapkg"][css~="cjplwtvlsqapkg"][css~="cjplwtvlsqapkg"][css~="cjplwtvlsqapkg"][css~="cjplwtvlsqapkg"] {

                                                      display: flex;
                                                      flex-direction: column;
                                                      gap: var(--space--2);
                                                    }

/********************************************************************************/

[css~="ewkzkfrjhckwyi"][css~="ewkzkfrjhckwyi"][css~="ewkzkfrjhckwyi"][css~="ewkzkfrjhckwyi"][css~="ewkzkfrjhckwyi"][css~="ewkzkfrjhckwyi"] {

                                                      padding-top: var(
                                                        --space--2
                                                      );
                                                      display: flex;
                                                      flex-direction: column;
                                                      gap: var(--space--2);
                                                    }

/********************************************************************************/

[css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"] {

                  display: flex;
                  gap: var(--space--4);
                }

[css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"][css~="qcwjqpwdxhlvx"] > * {
                    flex: 1;
                  }

/********************************************************************************/

[css~="gugcjbrjblawny"][css~="gugcjbrjblawny"][css~="gugcjbrjblawny"][css~="gugcjbrjblawny"][css~="gugcjbrjblawny"][css~="gugcjbrjblawny"] {

                    justify-content: start;
                  }

/********************************************************************************/

[css~="zhsfsqqqncplf"][css~="zhsfsqqqncplf"][css~="zhsfsqqqncplf"][css~="zhsfsqqqncplf"][css~="zhsfsqqqncplf"][css~="zhsfsqqqncplf"] {

                            display: flex;
                            gap: var(--space--2);
                            align-items: baseline;
                          }

/********************************************************************************/

[css~="fubnqujmyujpew"][css~="fubnqujmyujpew"][css~="fubnqujmyujpew"][css~="fubnqujmyujpew"][css~="fubnqujmyujpew"][css~="fubnqujmyujpew"] {

                              flex: 1;
                            }

/********************************************************************************/

[css~="czbmkilovkipak"][css~="czbmkilovkipak"][css~="czbmkilovkipak"][css~="czbmkilovkipak"][css~="czbmkilovkipak"][css~="czbmkilovkipak"] {

                  width: 100%;
                }

/********************************************************************************/

[css~="bxbmymgopgecsd"][css~="bxbmymgopgecsd"][css~="bxbmymgopgecsd"][css~="bxbmymgopgecsd"][css~="bxbmymgopgecsd"][css~="bxbmymgopgecsd"] {

                display: flex;
                gap: var(--space--2);
                align-items: baseline;
              }

/********************************************************************************/

[css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"] {

                    padding-top: var(--space--2);
                    border-top: var(--border-width--1) solid
                      var(--color--zinc--200);
                  }

@media (prefers-color-scheme: dark) {

[css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"] {
                      border-color: var(--color--zinc--700);
                  }
                    }

[css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"][css~="gdnfxyuycutcta"] {
                    display: flex;
                    gap: var(--space--2);
}

/********************************************************************************/

[css~="dtiomfmmtjdcon"][css~="dtiomfmmtjdcon"][css~="dtiomfmmtjdcon"][css~="dtiomfmmtjdcon"][css~="dtiomfmmtjdcon"][css~="dtiomfmmtjdcon"] {

                      flex: 1;
                      margin-top: var(--space--0-5);
                      display: flex;
                      flex-direction: column;
                      gap: var(--space--2);
                      min-width: var(--space--0);
                    }

/********************************************************************************/

[css~="ccfwjjsadbxcrh"][css~="ccfwjjsadbxcrh"][css~="ccfwjjsadbxcrh"][css~="ccfwjjsadbxcrh"][css~="ccfwjjsadbxcrh"][css~="ccfwjjsadbxcrh"] {

                            margin-right: var(--space--2);
                          }

/********************************************************************************/

[css~="fzxwkislbfzxsd"][css~="fzxwkislbfzxsd"][css~="fzxwkislbfzxsd"][css~="fzxwkislbfzxsd"][css~="fzxwkislbfzxsd"][css~="fzxwkislbfzxsd"] {

                            font-size: var(--font-size--xs);
                            line-height: var(--line-height--xs);
                            display: inline-flex;
                          }

/********************************************************************************/

[css~="gpfuowcamqvrvy"][css~="gpfuowcamqvrvy"][css~="gpfuowcamqvrvy"][css~="gpfuowcamqvrvy"][css~="gpfuowcamqvrvy"][css~="gpfuowcamqvrvy"] {

                          font-size: var(--font-size--xs);
                        }

/********************************************************************************/

[css~="qddcovhwrnxzr"][css~="qddcovhwrnxzr"][css~="qddcovhwrnxzr"][css~="qddcovhwrnxzr"][css~="qddcovhwrnxzr"][css~="qddcovhwrnxzr"] {

                        display: flex;
                        flex-wrap: wrap;
                        gap: var(--space--2);
                      }

/********************************************************************************/

[css~="bfxysvgdrvowmf"][css~="bfxysvgdrvowmf"][css~="bfxysvgdrvowmf"][css~="bfxysvgdrvowmf"][css~="bfxysvgdrvowmf"][css~="bfxysvgdrvowmf"] {

                          width: var(--space--36);
                          display: flex;
                          justify-content: flex-start;
                        }

/********************************************************************************/

[css~="cnlawjtmwitdum"][css~="cnlawjtmwitdum"][css~="cnlawjtmwitdum"][css~="cnlawjtmwitdum"][css~="cnlawjtmwitdum"][css~="cnlawjtmwitdum"] {

                                                                  padding: var(
                                                                    --space--2
                                                                  );
                                                                  display: flex;
                                                                  flex-direction: column;
                                                                  gap: var(
                                                                    --space--4
                                                                  );
                                                                }

/********************************************************************************/

[css~="bkrxkxjlyampyd"][css~="bkrxkxjlyampyd"][css~="bkrxkxjlyampyd"][css~="bkrxkxjlyampyd"][css~="bkrxkxjlyampyd"][css~="bkrxkxjlyampyd"] {

                                                                      font-weight: var(
                                                                        --font-weight--bold
                                                                      );
                                                                    }

/********************************************************************************/

[css~="zugyqxkogrrzq"][css~="zugyqxkogrrzq"][css~="zugyqxkogrrzq"][css~="zugyqxkogrrzq"][css~="zugyqxkogrrzq"][css~="zugyqxkogrrzq"] {

                          width: var(--space--8);
                          display: flex;
                          justify-content: flex-start;
                        }

/********************************************************************************/

[css~="frkoqijtcqfyjf"][css~="frkoqijtcqfyjf"][css~="frkoqijtcqfyjf"][css~="frkoqijtcqfyjf"][css~="frkoqijtcqfyjf"][css~="frkoqijtcqfyjf"] {

                                        padding: var(--space--2);
                                        display: flex;
                                        flex-direction: column;
                                        gap: var(--space--4);
                                      }

/********************************************************************************/

[css~="hizidqolfnpugu"][css~="hizidqolfnpugu"][css~="hizidqolfnpugu"][css~="hizidqolfnpugu"][css~="hizidqolfnpugu"][css~="hizidqolfnpugu"] {

                                            font-weight: var(
                                              --font-weight--bold
                                            );
                                          }

/********************************************************************************/

[css~="gjcqwgiuudmbvp"][css~="gjcqwgiuudmbvp"][css~="gjcqwgiuudmbvp"][css~="gjcqwgiuudmbvp"][css~="gjcqwgiuudmbvp"][css~="gjcqwgiuudmbvp"] {

                  font-size: var(--font-size--xs);
                  line-height: var(--line-height--xs);
                }

/********************************************************************************/

[css~="brmsogrysekwnz"][css~="brmsogrysekwnz"][css~="brmsogrysekwnz"][css~="brmsogrysekwnz"][css~="brmsogrysekwnz"][css~="brmsogrysekwnz"] {

                display: flex;
                flex-direction: column;
                gap: var(--space--1);
              }

/********************************************************************************/

[css~="deoxjxarajtrmm"][css~="deoxjxarajtrmm"][css~="deoxjxarajtrmm"][css~="deoxjxarajtrmm"][css~="deoxjxarajtrmm"][css~="deoxjxarajtrmm"] {

                        font-size: var(--font-size--xs);
                        line-height: var(--line-height--xs);
                      }

/********************************************************************************/

[css~="gmzevarjrazwto"][css~="gmzevarjrazwto"][css~="gmzevarjrazwto"][css~="gmzevarjrazwto"][css~="gmzevarjrazwto"][css~="gmzevarjrazwto"] {

                font-size: var(--font-size--xs);
                line-height: var(--line-height--xs);
              }

/********************************************************************************/

[css~="dkstkmfnmvibij"][css~="dkstkmfnmvibij"][css~="dkstkmfnmvibij"][css~="dkstkmfnmvibij"][css~="dkstkmfnmvibij"][css~="dkstkmfnmvibij"] {

                    margin-top: var(--space--1);
                    display: flex;
                    gap: var(--space--2);
                  }

/********************************************************************************/

[css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"] {

                          background-color: var(--color--accent-color--500);
                        }

[css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:hover,
                          [css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:focus-within {
                            background-color: var(--color--accent-color--400);
                          }

[css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:active {
                            background-color: var(--color--accent-color--600);
                          }

@media (prefers-color-scheme: dark) {

[css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"] {
                            background-color: var(--color--accent-color--600)
                        }
                            [css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:hover,
                            [css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:focus-within {
                              background-color: var(--color--accent-color--500);
                            }
                            [css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"][css~="ecsmpdqbbyhypn"]:active {
                              background-color: var(--color--accent-color--700);
                            }
                          }

/********************************************************************************/

[css~="bllozqreaktwmm"][css~="bllozqreaktwmm"][css~="bllozqreaktwmm"][css~="bllozqreaktwmm"][css~="bllozqreaktwmm"][css~="bllozqreaktwmm"] {

                              padding: var(--space--2);
                              display: flex;
                              flex-direction: column;
                              gap: var(--space--4);
                            }

/********************************************************************************/

[css~="weodynddozchn"][css~="weodynddozchn"][css~="weodynddozchn"][css~="weodynddozchn"][css~="weodynddozchn"][css~="weodynddozchn"] {

                                  font-weight: var(--font-weight--bold);
                                }

/********************************************************************************/

[css~="ftbfxlvzrvxyke"][css~="ftbfxlvzrvxyke"][css~="ftbfxlvzrvxyke"][css~="ftbfxlvzrvxyke"][css~="ftbfxlvzrvxyke"][css~="ftbfxlvzrvxyke"] {

                      font-weight: var(--font-weight--bold);
                    }

/********************************************************************************/

[css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"] {

          font-family: "Public Sans Variable", var(--font-family--sans-serif);
          font-size: var(--font-size--sm);
          line-height: var(--line-height--sm);
          color: var(--color--zinc--700);
          background-color: var(--color--zinc--50);
        }

::-webkit-scrollbar {
              width: var(--space--2);
              height: var(--space--2);
            }

::-webkit-scrollbar-button {
              display: none;
            }

::-webkit-scrollbar-track {
              display: none;
            }

::-webkit-scrollbar-track-piece {
              display: none;
            }

::-webkit-scrollbar-thumb {
              background-color: var(--color--zinc--400);
            }

@media (prefers-color-scheme: dark) {

::-webkit-scrollbar-thumb {
                background-color: var(--color--zinc--500);
            }
              }

::-webkit-scrollbar-thumb {
              border-radius: var(--border-radius--full);
}

::-webkit-scrollbar-corner {
              display: none;
            }

::-webkit-resizer {
              display: none;
            }

*,
            ::before,
            ::after {
              scrollbar-color: var(--color--zinc--400) transparent;
            }

@media (prefers-color-scheme: dark) {

*,
            ::before,
            ::after {
                scrollbar-color: var(--color--zinc--500) transparent
            }
              }

.grabbing,
              .grabbing::before,
              .grabbing::after,
              .grabbing *,
              .grabbing *::before,
              .grabbing *::after {
                cursor: grabbing !important;
                touch-action: none !important;
                -webkit-user-select: none !important;
                   -moz-user-select: none !important;
                        user-select: none !important;
              }

.label {
              display: flex;
              flex-direction: column;
              gap: var(--space--1);
            }

.label .label--text {
                font-size: var(--font-size--xs);
                line-height: var(--line-height--xs);
                font-weight: var(--font-weight--bold);
                display: flex;
                gap: var(--space--2);
              }

.input--text {
              background-color: var(--color--zinc--200);
              --color--box-shadow: var(--color--blue--400);
            }

.input--text::-moz-placeholder {
                color: var(--color--zinc--400);
              }

.input--text::placeholder {
                color: var(--color--zinc--400);
              }

.input--text:disabled,
              .input--text.disabled {
                color: var(--color--zinc--500);
                -webkit-text-fill-color: var(--color--zinc--500);
                background-color: var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {

.input--text {
                background-color: var(--color--zinc--700);
                --color--box-shadow: var(--color--blue--600)
            }
                .input--text::-moz-placeholder {
                  color: var(--color--zinc--500);
                }
                .input--text::placeholder {
                  color: var(--color--zinc--500);
                }
                .input--text:disabled,
                .input--text.disabled {
                  color: var(--color--zinc--400);
                  -webkit-text-fill-color: var(--color--zinc--400);
                  background-color: var(--color--zinc--600);
                }
              }

.input--text {
              width: 100%;
              display: block;
              padding: var(--space--2) var(--space--4);
              border-radius: var(--border-radius--md);
}

.input--text:focus-within {
                box-shadow: var(--border-width--0) var(--border-width--0)
                  var(--border-width--0) var(--border-width--2)
                  var(--color--box-shadow);
              }

.input--text {
              transition-property: var(--transition-property--box-shadow);
              transition-duration: var(--transition-duration--150);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
}

.input--text.input--text--textarea {
                border-radius: var(--border-radius--lg);
              }

.input--radio {
              background-color: var(--color--zinc--200);
            }

.input--radio:hover,
              .input--radio:focus-within {
                background-color: var(--color--zinc--300);
              }

.input--radio:active {
                background-color: var(--color--zinc--400);
              }

.input--radio:disabled,
              .input--radio.disabled {
                background-color: var(--color--zinc--300);
              }

.input--radio:checked {
                background-color: var(--color--blue--600);
              }

.input--radio:checked:hover,
                .input--radio:checked:focus-within {
                  background-color: var(--color--blue--500);
                }

.input--radio:checked:active {
                  background-color: var(--color--blue--700);
                }

.input--radio:checked:disabled,
                .input--radio:checked.disabled {
                  background-color: var(--color--blue--300);
                }

@media (prefers-color-scheme: dark) {

.input--radio {
                background-color: var(--color--zinc--700)
            }
                .input--radio:hover,
                .input--radio:focus-within {
                  background-color: var(--color--zinc--600);
                }
                .input--radio:active {
                  background-color: var(--color--zinc--500);
                }
                .input--radio:disabled,
                .input--radio.disabled {
                  background-color: var(--color--zinc--600);
                }
                .input--radio:checked {
                  background-color: var(--color--blue--700);
                }
                  .input--radio:checked:hover,
                  .input--radio:checked:focus-within {
                    background-color: var(--color--blue--600);
                  }
                  .input--radio:checked:active {
                    background-color: var(--color--blue--800);
                  }
                  .input--radio:checked:disabled,
                  .input--radio:checked.disabled {
                    background-color: var(--color--blue--500);
                  }
              }

.input--radio {
              min-width: var(--space--3-5);
              width: var(--space--3-5);
              min-height: var(--space--3-5);
              height: var(--space--3-5);
              border-radius: var(--border-radius--circle);
              position: relative;
              top: var(--space---0-5);
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition-property: var(--transition-property--colors);
              transition-duration: var(--transition-duration--150);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
}

.input--radio::before {
                content: "";
                background-color: var(--color--zinc--50);
              }

@media (prefers-color-scheme: dark) {

.input--radio::before {
                  background-color: var(--color--zinc--200);
              }
                }

.input--radio::before {
                display: block;
                width: var(--space--1-5);
                height: var(--space--1-5);
                border-radius: var(--border-radius--circle);
                transition-property: var(--transition-property--transform);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
}

.input--radio:not(:checked)::before {
                transform: scale(var(--scale--0));
              }

.input--checkbox {
              background-color: var(--color--zinc--200);
            }

.input--checkbox:hover,
              .input--checkbox:focus-within {
                background-color: var(--color--zinc--300);
              }

.input--checkbox:active {
                background-color: var(--color--zinc--400);
              }

.input--checkbox:disabled,
              .input--checkbox.disabled {
                background-color: var(--color--zinc--300);
              }

.input--checkbox:checked {
                background-color: var(--color--blue--600);
              }

.input--checkbox:checked:hover,
                .input--checkbox:checked:focus-within {
                  background-color: var(--color--blue--500);
                }

.input--checkbox:checked:active {
                  background-color: var(--color--blue--700);
                }

.input--checkbox:checked:disabled,
                .input--checkbox:checked.disabled {
                  background-color: var(--color--blue--300);
                }

@media (prefers-color-scheme: dark) {

.input--checkbox {
                background-color: var(--color--zinc--700)
            }
                .input--checkbox:hover,
                .input--checkbox:focus-within {
                  background-color: var(--color--zinc--600);
                }
                .input--checkbox:active {
                  background-color: var(--color--zinc--500);
                }
                .input--checkbox:disabled,
                .input--checkbox.disabled {
                  background-color: var(--color--zinc--600);
                }
                .input--checkbox:checked {
                  background-color: var(--color--blue--700);
                }
                  .input--checkbox:checked:hover,
                  .input--checkbox:checked:focus-within {
                    background-color: var(--color--blue--600);
                  }
                  .input--checkbox:checked:active {
                    background-color: var(--color--blue--800);
                  }
                  .input--checkbox:checked:disabled,
                  .input--checkbox:checked.disabled {
                    background-color: var(--color--blue--500);
                  }
              }

.input--checkbox {
              min-width: var(--space--8);
              width: var(--space--8);
              padding: var(--space--0-5);
              border-radius: var(--border-radius--full);
              position: relative;
              top: calc(var(--space--0-5) * 1.5);
              cursor: pointer;
              transition-property: var(--transition-property--colors);
              transition-duration: var(--transition-duration--150);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
}

.input--checkbox::after {
                content: "";
                background-color: var(--color--zinc--50);
              }

@media (prefers-color-scheme: dark) {

.input--checkbox::after {
                  background-color: var(--color--zinc--200);
              }
                }

.input--checkbox::after {
                display: block;
                width: var(--space--3);
                height: var(--space--3);
                border-radius: var(--border-radius--circle);
                transition-property: var(--transition-property--all);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
}

.input--checkbox:checked::after {
                margin-left: var(--space--4);
              }

.input--radio-or-checkbox--multilabel ~ * {
                display: flex;
                gap: var(--space--2);
              }

.input--radio-or-checkbox--multilabel:not(:checked) + * + *,
              .input--radio-or-checkbox--multilabel:checked + * {
                display: none;
              }

.input--visible-when-enabled-and-checked[disabled] + *,
              .input--visible-when-enabled-and-checked:not(:checked) + * {
                display: none;
              }

.button {
              padding: var(--space--1) var(--space--4);
              border-radius: var(--border-radius--md);
              display: flex;
              gap: var(--space--2);
              justify-content: center;
              align-items: baseline;
              transition-property: var(--transition-property--colors);
              transition-duration: var(--transition-duration--150);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
              cursor: pointer;
                
            }

.button:disabled,
              .button.disabled {
                color: var(--color--zinc--500);
              }

@media (prefers-color-scheme: dark) {

.button:disabled,
              .button.disabled {
                  color: var(--color--zinc--400)
              }
                }

.button.button--tight {
                padding: var(--space--0-5) var(--space--1);
              }

.button.button--tight.button--tight--inline {
                  margin: var(--space---0-5) var(--space---1);
                }

.button.button--tight-gap {
                gap: var(--space--1);
              }

@media (max-width: 400px) {

.button.button--full-width-on-small-screen {
                  width: 100%
              }
                }

.button.button--justify-start {
                justify-content: flex-start;
              }

.button.button--inline {
                display: inline-flex;
              }

.button.button--transparent:not(:disabled):not(.disabled):hover,
                  .button.button--transparent:not(:disabled):not(.disabled):focus-within,
                  .button.button--transparent:not(:disabled):not(.disabled).hover {
                    background-color: var(--color--zinc--200);
                  }

.button.button--transparent:not(:disabled):not(.disabled):active {
                    background-color: var(--color--zinc--300);
                  }

@media (prefers-color-scheme: dark) {
                    .button.button--transparent:not(:disabled):not(.disabled):hover,
                    .button.button--transparent:not(:disabled):not(.disabled):focus-within,
                    .button.button--transparent:not(:disabled):not(.disabled).hover {
                      background-color: var(--color--zinc--700);
                    }
                    .button.button--transparent:not(:disabled):not(.disabled):active {
                      background-color: var(--color--zinc--600);
                    }
                  }

.button.button--blue {
                    color: var(--color--blue--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--blue {
                      color: var(--color--blue--100)
                  }
                    }

.button.button--blue:not(:disabled):not(.disabled) {
                      background-color: var(--color--blue--600);
                    }

.button.button--blue:not(:disabled):not(.disabled):hover,
                      .button.button--blue:not(:disabled):not(.disabled):focus-within,
                      .button.button--blue:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--blue--500);
                      }

.button.button--blue:not(:disabled):not(.disabled):active {
                        background-color: var(--color--blue--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--blue:not(:disabled):not(.disabled) {
                        background-color: var(--color--blue--800)
                    }
                        .button.button--blue:not(:disabled):not(.disabled):hover,
                        .button.button--blue:not(:disabled):not(.disabled):focus-within,
                        .button.button--blue:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--blue--700);
                        }
                        .button.button--blue:not(:disabled):not(.disabled):active {
                          background-color: var(--color--blue--900);
                        }
                      }

.button.button--blue:disabled,
                    .button.button--blue.disabled {
                      background-color: var(--color--blue--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--blue:disabled,
                    .button.button--blue.disabled {
                        background-color: var(--color--blue--500)
                    }
                      }

.button.button--blue .strong {
                      color: var(--color--blue--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--blue .strong {
                        color: var(--color--blue--100)
                    }
                      }

.button.button--blue .secondary,
                    .button.button--blue [class^="text--"] {
                      color: var(--color--blue--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--blue .secondary,
                    .button.button--blue [class^="text--"] {
                        color: var(--color--blue--200)
                    }
                      }

.button.button--green {
                    color: var(--color--green--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--green {
                      color: var(--color--green--100)
                  }
                    }

.button.button--green:not(:disabled):not(.disabled) {
                      background-color: var(--color--green--600);
                    }

.button.button--green:not(:disabled):not(.disabled):hover,
                      .button.button--green:not(:disabled):not(.disabled):focus-within,
                      .button.button--green:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--green--500);
                      }

.button.button--green:not(:disabled):not(.disabled):active {
                        background-color: var(--color--green--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--green:not(:disabled):not(.disabled) {
                        background-color: var(--color--green--800)
                    }
                        .button.button--green:not(:disabled):not(.disabled):hover,
                        .button.button--green:not(:disabled):not(.disabled):focus-within,
                        .button.button--green:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--green--700);
                        }
                        .button.button--green:not(:disabled):not(.disabled):active {
                          background-color: var(--color--green--900);
                        }
                      }

.button.button--green:disabled,
                    .button.button--green.disabled {
                      background-color: var(--color--green--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--green:disabled,
                    .button.button--green.disabled {
                        background-color: var(--color--green--500)
                    }
                      }

.button.button--green .strong {
                      color: var(--color--green--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--green .strong {
                        color: var(--color--green--100)
                    }
                      }

.button.button--green .secondary,
                    .button.button--green [class^="text--"] {
                      color: var(--color--green--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--green .secondary,
                    .button.button--green [class^="text--"] {
                        color: var(--color--green--200)
                    }
                      }

.button.button--rose {
                    color: var(--color--rose--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--rose {
                      color: var(--color--rose--100)
                  }
                    }

.button.button--rose:not(:disabled):not(.disabled) {
                      background-color: var(--color--rose--600);
                    }

.button.button--rose:not(:disabled):not(.disabled):hover,
                      .button.button--rose:not(:disabled):not(.disabled):focus-within,
                      .button.button--rose:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--rose--500);
                      }

.button.button--rose:not(:disabled):not(.disabled):active {
                        background-color: var(--color--rose--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--rose:not(:disabled):not(.disabled) {
                        background-color: var(--color--rose--800)
                    }
                        .button.button--rose:not(:disabled):not(.disabled):hover,
                        .button.button--rose:not(:disabled):not(.disabled):focus-within,
                        .button.button--rose:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--rose--700);
                        }
                        .button.button--rose:not(:disabled):not(.disabled):active {
                          background-color: var(--color--rose--900);
                        }
                      }

.button.button--rose:disabled,
                    .button.button--rose.disabled {
                      background-color: var(--color--rose--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--rose:disabled,
                    .button.button--rose.disabled {
                        background-color: var(--color--rose--500)
                    }
                      }

.button.button--rose .strong {
                      color: var(--color--rose--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--rose .strong {
                        color: var(--color--rose--100)
                    }
                      }

.button.button--rose .secondary,
                    .button.button--rose [class^="text--"] {
                      color: var(--color--rose--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--rose .secondary,
                    .button.button--rose [class^="text--"] {
                        color: var(--color--rose--200)
                    }
                      }

.button.button--amber {
                    color: var(--color--amber--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--amber {
                      color: var(--color--amber--100)
                  }
                    }

.button.button--amber:not(:disabled):not(.disabled) {
                      background-color: var(--color--amber--600);
                    }

.button.button--amber:not(:disabled):not(.disabled):hover,
                      .button.button--amber:not(:disabled):not(.disabled):focus-within,
                      .button.button--amber:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--amber--500);
                      }

.button.button--amber:not(:disabled):not(.disabled):active {
                        background-color: var(--color--amber--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--amber:not(:disabled):not(.disabled) {
                        background-color: var(--color--amber--800)
                    }
                        .button.button--amber:not(:disabled):not(.disabled):hover,
                        .button.button--amber:not(:disabled):not(.disabled):focus-within,
                        .button.button--amber:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--amber--700);
                        }
                        .button.button--amber:not(:disabled):not(.disabled):active {
                          background-color: var(--color--amber--900);
                        }
                      }

.button.button--amber:disabled,
                    .button.button--amber.disabled {
                      background-color: var(--color--amber--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--amber:disabled,
                    .button.button--amber.disabled {
                        background-color: var(--color--amber--500)
                    }
                      }

.button.button--amber .strong {
                      color: var(--color--amber--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--amber .strong {
                        color: var(--color--amber--100)
                    }
                      }

.button.button--amber .secondary,
                    .button.button--amber [class^="text--"] {
                      color: var(--color--amber--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--amber .secondary,
                    .button.button--amber [class^="text--"] {
                        color: var(--color--amber--200)
                    }
                      }

.button.button--emerald {
                    color: var(--color--emerald--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--emerald {
                      color: var(--color--emerald--100)
                  }
                    }

.button.button--emerald:not(:disabled):not(.disabled) {
                      background-color: var(--color--emerald--600);
                    }

.button.button--emerald:not(:disabled):not(.disabled):hover,
                      .button.button--emerald:not(:disabled):not(.disabled):focus-within,
                      .button.button--emerald:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--emerald--500);
                      }

.button.button--emerald:not(:disabled):not(.disabled):active {
                        background-color: var(--color--emerald--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--emerald:not(:disabled):not(.disabled) {
                        background-color: var(--color--emerald--800)
                    }
                        .button.button--emerald:not(:disabled):not(.disabled):hover,
                        .button.button--emerald:not(:disabled):not(.disabled):focus-within,
                        .button.button--emerald:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--emerald--700);
                        }
                        .button.button--emerald:not(:disabled):not(.disabled):active {
                          background-color: var(--color--emerald--900);
                        }
                      }

.button.button--emerald:disabled,
                    .button.button--emerald.disabled {
                      background-color: var(--color--emerald--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--emerald:disabled,
                    .button.button--emerald.disabled {
                        background-color: var(--color--emerald--500)
                    }
                      }

.button.button--emerald .strong {
                      color: var(--color--emerald--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--emerald .strong {
                        color: var(--color--emerald--100)
                    }
                      }

.button.button--emerald .secondary,
                    .button.button--emerald [class^="text--"] {
                      color: var(--color--emerald--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--emerald .secondary,
                    .button.button--emerald [class^="text--"] {
                        color: var(--color--emerald--200)
                    }
                      }

.button.button--sky {
                    color: var(--color--sky--50);
                  }

@media (prefers-color-scheme: dark) {

.button.button--sky {
                      color: var(--color--sky--100)
                  }
                    }

.button.button--sky:not(:disabled):not(.disabled) {
                      background-color: var(--color--sky--600);
                    }

.button.button--sky:not(:disabled):not(.disabled):hover,
                      .button.button--sky:not(:disabled):not(.disabled):focus-within,
                      .button.button--sky:not(:disabled):not(.disabled).hover {
                        background-color: var(--color--sky--500);
                      }

.button.button--sky:not(:disabled):not(.disabled):active {
                        background-color: var(--color--sky--700);
                      }

@media (prefers-color-scheme: dark) {

.button.button--sky:not(:disabled):not(.disabled) {
                        background-color: var(--color--sky--800)
                    }
                        .button.button--sky:not(:disabled):not(.disabled):hover,
                        .button.button--sky:not(:disabled):not(.disabled):focus-within,
                        .button.button--sky:not(:disabled):not(.disabled).hover {
                          background-color: var(--color--sky--700);
                        }
                        .button.button--sky:not(:disabled):not(.disabled):active {
                          background-color: var(--color--sky--900);
                        }
                      }

.button.button--sky:disabled,
                    .button.button--sky.disabled {
                      background-color: var(--color--sky--300);
                    }

@media (prefers-color-scheme: dark) {

.button.button--sky:disabled,
                    .button.button--sky.disabled {
                        background-color: var(--color--sky--500)
                    }
                      }

.button.button--sky .strong {
                      color: var(--color--sky--50);
                    }

@media (prefers-color-scheme: dark) {

.button.button--sky .strong {
                        color: var(--color--sky--100)
                    }
                      }

.button.button--sky .secondary,
                    .button.button--sky [class^="text--"] {
                      color: var(--color--sky--100);
                    }

@media (prefers-color-scheme: dark) {

.button.button--sky .secondary,
                    .button.button--sky [class^="text--"] {
                        color: var(--color--sky--200)
                    }
                      }

.link {
              text-decoration: underline;
              color: var(--color--blue--600);
            }

.link:hover,
              .link:focus-within {
                color: var(--color--blue--500);
              }

.link:active {
                color: var(--color--blue--700);
              }

.link.text--rose:hover,
                    .link.text--rose:focus-within {
                      color: var(--color--rose--500);
                    }

.link.text--rose:active {
                      color: var(--color--rose--700);
                    }

@media (prefers-color-scheme: dark) {

.link {
                color: var(--color--blue--500)
            }
                .link:hover,
                .link:focus-within {
                  color: var(--color--blue--400);
                }
                .link:active {
                  color: var(--color--blue--600);
                }
                      .link.text--rose:hover,
                      .link.text--rose:focus-within {
                        color: var(--color--rose--400);
                      }
                      .link.text--rose:active {
                        color: var(--color--rose--600);
                      }
                  
              }

.link {
              transition-property: var(--transition-property--colors);
              transition-duration: var(--transition-duration--150);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
              cursor: pointer;
}

.link .bi {
                text-decoration: none;
              }

:disabled,
            .disabled {
              cursor: not-allowed;
            }

.heading {
              font-size: var(--font-size--2xs);
              line-height: var(--line-height--2xs);
              font-weight: var(--font-weight--bold);
              text-transform: uppercase;
              letter-spacing: var(--letter-spacing--widest);
              color: var(--color--zinc--600);
            }

@media (prefers-color-scheme: dark) {

.heading {
                color: var(--color--zinc--400);
            }
              }

.heading {
              display: flex;
              gap: var(--space--1);
}

.heading--display {
              font-size: var(--font-size--xl);
              line-height: var(--line-height--xl);
              font-weight: var(--font-weight--bold);
              text-align: center;
              color: var(--color--zinc--800);
            }

@media (prefers-color-scheme: dark) {

.heading--display {
                color: var(--color--zinc--100)
            }
              }

.strong {
              font-weight: var(--font-weight--bold);
              color: var(--color--zinc--800);
            }

@media (prefers-color-scheme: dark) {

.strong {
                color: var(--color--zinc--100)
            }
              }

.secondary {
              color: var(--color--zinc--500);
            }

@media (prefers-color-scheme: dark) {

.secondary {
                color: var(--color--zinc--400)
            }
              }

.text--blue {
                  color: var(--color--blue--600);
                }

@media (prefers-color-scheme: dark) {

.text--blue {
                    color: var(--color--blue--500)
                }
                  }

.text--green {
                  color: var(--color--green--600);
                }

@media (prefers-color-scheme: dark) {

.text--green {
                    color: var(--color--green--500)
                }
                  }

.text--rose {
                  color: var(--color--rose--600);
                }

@media (prefers-color-scheme: dark) {

.text--rose {
                    color: var(--color--rose--500)
                }
                  }

.text--sky {
                  color: var(--color--sky--600);
                }

@media (prefers-color-scheme: dark) {

.text--sky {
                    color: var(--color--sky--500)
                }
                  }

.text--amber {
                  color: var(--color--amber--600);
                }

@media (prefers-color-scheme: dark) {

.text--amber {
                    color: var(--color--amber--500)
                }
                  }

.text--teal {
                  color: var(--color--teal--600);
                }

@media (prefers-color-scheme: dark) {

.text--teal {
                    color: var(--color--teal--500)
                }
                  }

.text--lime {
                  color: var(--color--lime--600);
                }

@media (prefers-color-scheme: dark) {

.text--lime {
                    color: var(--color--lime--500)
                }
                  }

.text--emerald {
                  color: var(--color--emerald--600);
                }

@media (prefers-color-scheme: dark) {

.text--emerald {
                    color: var(--color--emerald--500)
                }
                  }

.text--fuchsia {
                  color: var(--color--fuchsia--600);
                }

@media (prefers-color-scheme: dark) {

.text--fuchsia {
                    color: var(--color--fuchsia--500)
                }
                  }

.text--cyan {
                  color: var(--color--cyan--600);
                }

@media (prefers-color-scheme: dark) {

.text--cyan {
                    color: var(--color--cyan--500)
                }
                  }

.text--purple {
                  color: var(--color--purple--600);
                }

@media (prefers-color-scheme: dark) {

.text--purple {
                    color: var(--color--purple--500)
                }
                  }

.text--orange {
                  color: var(--color--orange--600);
                }

@media (prefers-color-scheme: dark) {

.text--orange {
                    color: var(--color--orange--500)
                }
                  }

.mark {
              color: var(--color--amber--700);
              background-color: var(--color--amber--200);
            }

@media (prefers-color-scheme: dark) {

.mark {
                color: var(--color--amber--200);
                background-color: var(--color--amber--700);
            }
              }

.mark {
              padding: var(--space--0) var(--space--0-5);
              border-radius: var(--border-radius--base);
}

.code,
            .pre > code {
              font-family: "JetBrains Mono Variable",
                var(--font-family--monospace);
              font-variant-ligatures: none;
            }

.pre > code {
              font-size: var(--font-size--xs);
              line-height: var(--line-height--xs);
            }

.img {
              background-color: var(--color--zinc--50);
            }

@media (prefers-color-scheme: dark) {

.img {
                background-color: var(--color--zinc--50);
                filter: brightness(var(--brightness--90));
            }
              }

.img {
              max-width: 100%;
              height: auto;
              border-radius: var(--border-radius--xl);
}

.details {
              background-color: var(--color--zinc--200);
            }

@media (prefers-color-scheme: dark) {

.details {
                background-color: var(--color--zinc--700)
            }
              }

.details {
              border-radius: var(--border-radius--xl);
}

.details summary:hover,
                .details summary:focus-within {
                  background-color: var(--color--zinc--300);
                }

@media (prefers-color-scheme: dark) {
                  .details summary:hover,
                  .details summary:focus-within {
                    background-color: var(--color--zinc--600);
                  }
                }

.details summary {
                padding: var(--space--2) var(--space--4);
                border-radius: var(--border-radius--xl);
                transition-property: var(--transition-property--colors);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
                cursor: pointer;
}

.details summary::before {
                  content: "\f275";
                  font-family: "bootstrap-icons" !important;
                  font-size: var(--font-size--xs);
                  line-height: var(--line-height--xs);
                  margin-right: var(--space--2);
                }

.details[open] > summary::before {
                content: "\f273";
              }

.details > div:last-child {
                padding: var(--space--4);
              }

.decorative-icon {
              font-size: var(--font-size--9xl);
              line-height: var(--line-height--9xl);
              color: var(--color--zinc--300);
              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

.decorative-icon {
                color: var(--color--zinc--600);
                background-color: var(--color--zinc--800);
            }
              }

.decorative-icon {
              width: var(--space--48);
              height: var(--space--48);
              border-radius: var(--border-radius--circle);
              display: flex;
              justify-content: center;
              align-items: center;
}

.separator {
              border-top: var(--border-width--1) solid var(--color--zinc--200);
                
            }

@media (prefers-color-scheme: dark) {

.separator {
                border-color: var(--color--zinc--700)
                
            }
              }

.separator.separator--amber {
                    border-color: var(--color--amber--200);
                  }

@media (prefers-color-scheme: dark) {

.separator.separator--amber {
                      border-color: var(--color--amber--700)
                  }
                    }

.menu-box {
              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

.menu-box {
                background-color: var(--color--zinc--800)
            }
              }

.menu-box {
              width: 100%;
              max-width: var(--space--64);
              padding: var(--space--2);
              border-radius: var(--border-radius--lg);
              display: flex;
              flex-direction: column;
              gap: var(--space--2);
}

.menu-box .menu-box--item {
                justify-content: flex-start;
              }

.tippy-box {
              font-size: var(--font-size--sm);
              line-height: var(--line-height--sm);
              color: var(--color--zinc--700);
              --background-color: var(--color--zinc--100);
              background-color: var(--background-color);
              --border-color: var(--color--zinc--400);
              border: var(--border-width--1) solid var(--border-color);
              border-radius: var(--border-radius--md);
                
            }

@media (prefers-color-scheme: dark) {

.tippy-box {
                color: var(--color--zinc--200);
                --background-color: var(--color--zinc--800);
                --border-color: var(--color--zinc--400)
                
            }
              }

.tippy-box > .tippy-svg-arrow > svg:first-child {
                  fill: var(--border-color);
                }

.tippy-box > .tippy-svg-arrow > svg:last-child {
                  fill: var(--background-color);
                }

.tippy-box .tippy-content {
                padding: var(--space--1) var(--space--2);
              }

.tippy-box .heading {
                padding: var(--space--1) var(--space--2);
              }

.tippy-box .keyboard-shortcut {
                font-size: var(--font-size--xs);
                line-height: var(--line-height--xs);
                color: var(--color--zinc--500);
              }

@media (prefers-color-scheme: dark) {

.tippy-box .keyboard-shortcut {
                  color: var(--color--zinc--400)
              }
                }

.tippy-box .keyboard-shortcut {
                flex: 1;
                text-align: right;
}

.tippy-box .keyboard-shortcut .keyboard-shortcut--cluster {
                  letter-spacing: var(--letter-spacing--widest);
                }

.tippy-box .dropdown--menu {
                display: flex;
                flex-direction: column;
              }

.tippy-box .dropdown--menu .dropdown--menu--item {
                  text-align: left;
                  width: 100%;
                  padding-left: var(--space--2);
                  padding-right: var(--space--2);
                  justify-content: flex-start;
                }

.tippy-box .dropdown--separator {
                border-top: var(--border-width--1) solid var(--color--zinc--200);
              }

@media (prefers-color-scheme: dark) {

.tippy-box .dropdown--separator {
                  border-color: var(--color--zinc--700);
              }
                }

.tippy-box .dropdown--separator {
                margin: var(--space--0) var(--space--2);
}

.tippy-box[data-theme~="green"] {
                    color: var(--color--green--700);
                    --background-color: var(--color--green--100);
                    --border-color: var(--color--green--200);
                  }

.tippy-box[data-theme~="green"] .button.button--transparent:hover,
                      .tippy-box[data-theme~="green"] .button.button--transparent:focus-within {
                        background-color: var(--color--green--200);
                      }

.tippy-box[data-theme~="green"] .button.button--transparent:active {
                        background-color: var(--color--green--300);
                      }

.tippy-box[data-theme~="green"] .link {
                      color: var(--color--green--600);
                    }

.tippy-box[data-theme~="green"] .link:hover,
                      .tippy-box[data-theme~="green"] .link:focus-within {
                        color: var(--color--green--500);
                      }

.tippy-box[data-theme~="green"] .link:active {
                        color: var(--color--green--700);
                      }

.tippy-box[data-theme~="green"] .keyboard-shortcut {
                      color: var(--color--green--500);
                    }

@media (prefers-color-scheme: dark) {

.tippy-box[data-theme~="green"] {
                      color: var(--color--green--200);
                      --background-color: var(--color--green--950);
                      --border-color: var(--color--green--900)
                  }
                        .tippy-box[data-theme~="green"] .button.button--transparent:hover,
                        .tippy-box[data-theme~="green"] .button.button--transparent:focus-within {
                          background-color: var(--color--green--800);
                        }
                        .tippy-box[data-theme~="green"] .button.button--transparent:active {
                          background-color: var(--color--green--700);
                        }
                      .tippy-box[data-theme~="green"] .link {
                        color: var(--color--green--100);
                      }
                        .tippy-box[data-theme~="green"] .link:hover,
                        .tippy-box[data-theme~="green"] .link:focus-within {
                          color: var(--color--green--50);
                        }
                        .tippy-box[data-theme~="green"] .link:active {
                          color: var(--color--green--200);
                        }
                      .tippy-box[data-theme~="green"] .keyboard-shortcut {
                        color: var(--color--green--400);
                      }
                    }

.tippy-box[data-theme~="amber"] {
                    color: var(--color--amber--700);
                    --background-color: var(--color--amber--100);
                    --border-color: var(--color--amber--200);
                  }

.tippy-box[data-theme~="amber"] .button.button--transparent:hover,
                      .tippy-box[data-theme~="amber"] .button.button--transparent:focus-within {
                        background-color: var(--color--amber--200);
                      }

.tippy-box[data-theme~="amber"] .button.button--transparent:active {
                        background-color: var(--color--amber--300);
                      }

.tippy-box[data-theme~="amber"] .link {
                      color: var(--color--amber--600);
                    }

.tippy-box[data-theme~="amber"] .link:hover,
                      .tippy-box[data-theme~="amber"] .link:focus-within {
                        color: var(--color--amber--500);
                      }

.tippy-box[data-theme~="amber"] .link:active {
                        color: var(--color--amber--700);
                      }

.tippy-box[data-theme~="amber"] .keyboard-shortcut {
                      color: var(--color--amber--500);
                    }

@media (prefers-color-scheme: dark) {

.tippy-box[data-theme~="amber"] {
                      color: var(--color--amber--200);
                      --background-color: var(--color--amber--950);
                      --border-color: var(--color--amber--900)
                  }
                        .tippy-box[data-theme~="amber"] .button.button--transparent:hover,
                        .tippy-box[data-theme~="amber"] .button.button--transparent:focus-within {
                          background-color: var(--color--amber--800);
                        }
                        .tippy-box[data-theme~="amber"] .button.button--transparent:active {
                          background-color: var(--color--amber--700);
                        }
                      .tippy-box[data-theme~="amber"] .link {
                        color: var(--color--amber--100);
                      }
                        .tippy-box[data-theme~="amber"] .link:hover,
                        .tippy-box[data-theme~="amber"] .link:focus-within {
                          color: var(--color--amber--50);
                        }
                        .tippy-box[data-theme~="amber"] .link:active {
                          color: var(--color--amber--200);
                        }
                      .tippy-box[data-theme~="amber"] .keyboard-shortcut {
                        color: var(--color--amber--400);
                      }
                    }

.tippy-box[data-theme~="rose"] {
                    color: var(--color--rose--700);
                    --background-color: var(--color--rose--100);
                    --border-color: var(--color--rose--200);
                  }

.tippy-box[data-theme~="rose"] .button.button--transparent:hover,
                      .tippy-box[data-theme~="rose"] .button.button--transparent:focus-within {
                        background-color: var(--color--rose--200);
                      }

.tippy-box[data-theme~="rose"] .button.button--transparent:active {
                        background-color: var(--color--rose--300);
                      }

.tippy-box[data-theme~="rose"] .link {
                      color: var(--color--rose--600);
                    }

.tippy-box[data-theme~="rose"] .link:hover,
                      .tippy-box[data-theme~="rose"] .link:focus-within {
                        color: var(--color--rose--500);
                      }

.tippy-box[data-theme~="rose"] .link:active {
                        color: var(--color--rose--700);
                      }

.tippy-box[data-theme~="rose"] .keyboard-shortcut {
                      color: var(--color--rose--500);
                    }

@media (prefers-color-scheme: dark) {

.tippy-box[data-theme~="rose"] {
                      color: var(--color--rose--200);
                      --background-color: var(--color--rose--950);
                      --border-color: var(--color--rose--900)
                  }
                        .tippy-box[data-theme~="rose"] .button.button--transparent:hover,
                        .tippy-box[data-theme~="rose"] .button.button--transparent:focus-within {
                          background-color: var(--color--rose--800);
                        }
                        .tippy-box[data-theme~="rose"] .button.button--transparent:active {
                          background-color: var(--color--rose--700);
                        }
                      .tippy-box[data-theme~="rose"] .link {
                        color: var(--color--rose--100);
                      }
                        .tippy-box[data-theme~="rose"] .link:hover,
                        .tippy-box[data-theme~="rose"] .link:focus-within {
                          color: var(--color--rose--50);
                        }
                        .tippy-box[data-theme~="rose"] .link:active {
                          color: var(--color--rose--200);
                        }
                      .tippy-box[data-theme~="rose"] .keyboard-shortcut {
                        color: var(--color--rose--400);
                      }
                    }

.tippy-box[data-theme~="error"] {
                    color: var(--color--rose--700);
                    --background-color: var(--color--rose--100);
                    --border-color: var(--color--rose--200);
                  }

.tippy-box[data-theme~="error"] .button.button--transparent:hover,
                      .tippy-box[data-theme~="error"] .button.button--transparent:focus-within {
                        background-color: var(--color--rose--200);
                      }

.tippy-box[data-theme~="error"] .button.button--transparent:active {
                        background-color: var(--color--rose--300);
                      }

.tippy-box[data-theme~="error"] .link {
                      color: var(--color--rose--600);
                    }

.tippy-box[data-theme~="error"] .link:hover,
                      .tippy-box[data-theme~="error"] .link:focus-within {
                        color: var(--color--rose--500);
                      }

.tippy-box[data-theme~="error"] .link:active {
                        color: var(--color--rose--700);
                      }

.tippy-box[data-theme~="error"] .keyboard-shortcut {
                      color: var(--color--rose--500);
                    }

@media (prefers-color-scheme: dark) {

.tippy-box[data-theme~="error"] {
                      color: var(--color--rose--200);
                      --background-color: var(--color--rose--950);
                      --border-color: var(--color--rose--900)
                  }
                        .tippy-box[data-theme~="error"] .button.button--transparent:hover,
                        .tippy-box[data-theme~="error"] .button.button--transparent:focus-within {
                          background-color: var(--color--rose--800);
                        }
                        .tippy-box[data-theme~="error"] .button.button--transparent:active {
                          background-color: var(--color--rose--700);
                        }
                      .tippy-box[data-theme~="error"] .link {
                        color: var(--color--rose--100);
                      }
                        .tippy-box[data-theme~="error"] .link:hover,
                        .tippy-box[data-theme~="error"] .link:focus-within {
                          color: var(--color--rose--50);
                        }
                        .tippy-box[data-theme~="error"] .link:active {
                          color: var(--color--rose--200);
                        }
                      .tippy-box[data-theme~="error"] .keyboard-shortcut {
                        color: var(--color--rose--400);
                      }
                    }

.dark {
              display: none !important;
            }

@media (prefers-color-scheme: dark) {
              .light {
                display: none !important;
              }
              .dark {
                display: block !important;
              }
            }

.content h1,
              .content h2,
              .content h3,
              .content h4,
              .content h5,
              .content h6 {
                margin-top: var(--space--6);
                margin-bottom: var(--space--2);
              }

.content h1 {
                color: var(--color--zinc--800);
              }

@media (prefers-color-scheme: dark) {

.content h1 {
                  color: var(--color--zinc--100)
              }
                }

.content h1,
              .content h2,
              .content h3 {
                font-size: var(--font-size--base);
                line-height: var(--line-height--base);
              }

.content h1,
              .content h4,
              .content h5,
              .content h6 {
                font-weight: var(--font-weight--bold);
              }

.content h2 {
                font-style: italic;
              }

.content hr {
                border-top: var(--border-width--1) solid var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {

.content hr {
                  border-color: var(--color--zinc--600);
              }
                }

.content hr {
                margin: var(--space--2) var(--space--0);
}

.content p + p {
                margin-top: var(--space--2);
              }

.content strong {
                font-weight: var(--font-weight--bold);
                color: var(--color--zinc--800);
              }

@media (prefers-color-scheme: dark) {

.content strong {
                  color: var(--color--zinc--100)
              }
                }

.content em {
                font-style: italic;
                color: var(--color--zinc--800);
              }

@media (prefers-color-scheme: dark) {

.content em {
                  color: var(--color--zinc--100)
              }
                }

.content u {
                text-decoration: underline;
              }

.content a {
                text-decoration: underline;
                color: var(--color--blue--600);
              }

.content a:hover,
                .content a:focus-within {
                  color: var(--color--blue--500);
                }

.content a:active {
                  color: var(--color--blue--700);
                }

@media (prefers-color-scheme: dark) {

.content a {
                  color: var(--color--blue--500);
              }
                  .content a:hover,
                  .content a:focus-within {
                    color: var(--color--blue--400);
                  }
                  .content a:active {
                    color: var(--color--blue--600);
                  }
                }

.content a {
                transition-property: var(--transition-property--colors);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
                cursor: pointer;
}

.content code {
                font-family: "JetBrains Mono Variable",
                  var(--font-family--monospace);
                font-variant-ligatures: none;
                background-color: var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {

.content code {
                  background-color: var(--color--zinc--600);
              }
                }

.content code {
                padding: var(--space--0) var(--space--0-5);
                border-radius: var(--border-radius--base);
}

.content ins {
                color: var(--color--green--600);
              }

@media (prefers-color-scheme: dark) {

.content ins {
                  color: var(--color--green--500)
              }
                }

.content del {
                text-decoration: line-through;
                color: var(--color--rose--600);
              }

@media (prefers-color-scheme: dark) {

.content del {
                  color: var(--color--rose--500)
              }
                }

.content sup,
              .content sub {
                position: relative;
                font-size: var(--font-size--2xs);
                line-height: var(--space--0);
                vertical-align: baseline;
              }

.content sup {
                top: var(--space---1);
              }

.content sub {
                bottom: var(--space---1);
              }

.content video,
              .content ul,
              .content ol,
              .content blockquote,
              .content table,
              .content details,
              .content .math-display,
              .content pre {
                margin: var(--space--4) var(--space--0);
              }

.content img,
              .content video {
                background-color: var(--color--zinc--50);
              }

@media (prefers-color-scheme: dark) {

.content img,
              .content video {
                  background-color: var(--color--zinc--50);
                  filter: brightness(var(--brightness--90));
              }
                }

.content img,
              .content video {
                max-width: 100%;
                height: auto;
                border-radius: var(--border-radius--xl);
}

.content video {
                display: block;
              }

.content ul {
                padding-left: var(--space--8);
              }

.content ul > li {
                  list-style: disc;
                }

.content ul > li::marker {
                    color: var(--color--zinc--500);
                  }

@media (prefers-color-scheme: dark) {

.content ul > li::marker {
                      color: var(--color--zinc--400)
                  }
                    }

.content ol {
                padding-left: var(--space--8);
              }

.content ol > li {
                  list-style: decimal;
                }

.content ol > li::marker {
                    color: var(--color--zinc--500);
                  }

@media (prefers-color-scheme: dark) {

.content ol > li::marker {
                      color: var(--color--zinc--400)
                  }
                    }

.content li + li {
                margin-top: var(--space--2);
              }

.content input[type="radio"] {
                background-color: var(--color--zinc--200);
              }

.content input[type="radio"]:hover,
                .content input[type="radio"]:focus-within {
                  background-color: var(--color--zinc--300);
                }

.content input[type="radio"]:active {
                  background-color: var(--color--zinc--400);
                }

.content input[type="radio"]:disabled,
                .content input[type="radio"].disabled {
                  background-color: var(--color--zinc--300);
                }

.content input[type="radio"]:checked {
                  background-color: var(--color--blue--600);
                }

.content input[type="radio"]:checked:hover,
                  .content input[type="radio"]:checked:focus-within {
                    background-color: var(--color--blue--500);
                  }

.content input[type="radio"]:checked:active {
                    background-color: var(--color--blue--700);
                  }

.content input[type="radio"]:checked:disabled,
                  .content input[type="radio"]:checked.disabled {
                    background-color: var(--color--blue--300);
                  }

@media (prefers-color-scheme: dark) {

.content input[type="radio"] {
                  background-color: var(--color--zinc--700)
              }
                  .content input[type="radio"]:hover,
                  .content input[type="radio"]:focus-within {
                    background-color: var(--color--zinc--600);
                  }
                  .content input[type="radio"]:active {
                    background-color: var(--color--zinc--500);
                  }
                  .content input[type="radio"]:disabled,
                  .content input[type="radio"].disabled {
                    background-color: var(--color--zinc--600);
                  }
                  .content input[type="radio"]:checked {
                    background-color: var(--color--blue--700);
                  }
                    .content input[type="radio"]:checked:hover,
                    .content input[type="radio"]:checked:focus-within {
                      background-color: var(--color--blue--600);
                    }
                    .content input[type="radio"]:checked:active {
                      background-color: var(--color--blue--800);
                    }
                    .content input[type="radio"]:checked:disabled,
                    .content input[type="radio"]:checked.disabled {
                      background-color: var(--color--blue--500);
                    }
                }

.content input[type="radio"] {
                min-width: var(--space--3-5);
                width: var(--space--3-5);
                min-height: var(--space--3-5);
                height: var(--space--3-5);
                border-radius: var(--border-radius--circle);
                display: flex;
                justify-content: center;
                align-items: center;
                transition-property: var(--transition-property--colors);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
}

.content input[type="radio"]::before {
                  content: "";
                  background-color: var(--color--zinc--50);
                }

@media (prefers-color-scheme: dark) {

.content input[type="radio"]::before {
                    background-color: var(--color--zinc--200);
                }
                  }

.content input[type="radio"]::before {
                  display: block;
                  width: var(--space--1-5);
                  height: var(--space--1-5);
                  border-radius: var(--border-radius--circle);
                  transition-property: var(--transition-property--transform);
                  transition-duration: var(--transition-duration--150);
                  transition-timing-function: var(
                    --transition-timing-function--in-out
                  );
}

.content input[type="radio"]:not(:checked)::before {
                  transform: scale(var(--scale--0));
                }

.content input[type="checkbox"] {
                font-size: var(--font-size--2xs);
                line-height: var(--line-height--2xs);
                background-color: var(--color--zinc--200);
              }

.content input[type="checkbox"]:hover,
                .content input[type="checkbox"]:focus-within {
                  background-color: var(--color--zinc--300);
                }

.content input[type="checkbox"]:active {
                  background-color: var(--color--zinc--400);
                }

.content input[type="checkbox"]:disabled,
                .content input[type="checkbox"].disabled {
                  background-color: var(--color--zinc--300);
                }

.content input[type="checkbox"]:checked {
                  background-color: var(--color--blue--600);
                }

.content input[type="checkbox"]:checked:hover,
                  .content input[type="checkbox"]:checked:focus-within {
                    background-color: var(--color--blue--500);
                  }

.content input[type="checkbox"]:checked:active {
                    background-color: var(--color--blue--700);
                  }

.content input[type="checkbox"]:checked:disabled,
                  .content input[type="checkbox"]:checked.disabled {
                    background-color: var(--color--blue--300);
                  }

@media (prefers-color-scheme: dark) {

.content input[type="checkbox"] {
                  background-color: var(--color--zinc--700)
              }
                  .content input[type="checkbox"]:hover,
                  .content input[type="checkbox"]:focus-within {
                    background-color: var(--color--zinc--600);
                  }
                  .content input[type="checkbox"]:active {
                    background-color: var(--color--zinc--500);
                  }
                  .content input[type="checkbox"]:disabled,
                  .content input[type="checkbox"].disabled {
                    background-color: var(--color--zinc--600);
                  }
                  .content input[type="checkbox"]:checked {
                    background-color: var(--color--blue--700);
                  }
                    .content input[type="checkbox"]:checked:hover,
                    .content input[type="checkbox"]:checked:focus-within {
                      background-color: var(--color--blue--600);
                    }
                    .content input[type="checkbox"]:checked:active {
                      background-color: var(--color--blue--800);
                    }
                    .content input[type="checkbox"]:checked:disabled,
                    .content input[type="checkbox"]:checked.disabled {
                      background-color: var(--color--blue--500);
                    }
                }

.content input[type="checkbox"] {
                width: var(--space--3-5);
                height: var(--space--3-5);
                border-radius: var(--border-radius--base);
                margin-right: var(--space--1);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transition-property: var(--transition-property--colors);
                transition-duration: var(--transition-duration--150);
                transition-timing-function: var(
                  --transition-timing-function--in-out
                );
}

.content input[type="checkbox"]::before {
                  content: "\f633";
                  font-family: "bootstrap-icons" !important;
                  color: var(--color--zinc--50);
                }

@media (prefers-color-scheme: dark) {

.content input[type="checkbox"]::before {
                    color: var(--color--zinc--200);
                }
                  }

.content input[type="checkbox"]::before {
                  transition-property: var(--transition-property--transform);
                  transition-duration: var(--transition-duration--150);
                  transition-timing-function: var(
                    --transition-timing-function--in-out
                  );
}

.content input[type="checkbox"]:not(:checked)::before {
                  transform: scale(var(--scale--0));
                }

.content li > input[type="checkbox"]:first-child {
                position: absolute;
                margin-left: var(--space---5);
                margin-top: var(--space--1);
              }

.content blockquote {
                padding-left: var(--space--4);
                border-left: var(--border-width--4) solid
                  var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {

.content blockquote {
                  border-color: var(--color--zinc--600);
              }
                }

.content blockquote {
                opacity: var(--opacity--75);
}

.content table {
                border-collapse: collapse;
                display: block;
                overflow-x: auto;
              }

.content table tbody tr {
                  border-top: var(--border-width--1) solid
                    var(--color--zinc--300);
                }

@media (prefers-color-scheme: dark) {

.content table tbody tr {
                    border-color: var(--color--zinc--600)
                }
                  }

.content table th,
                .content table td {
                  padding: var(--space--1) var(--space--3);
                }

.content table th {
                  font-weight: var(--font-weight--bold);
                  text-align: left;
                  color: var(--color--zinc--800);
                }

@media (prefers-color-scheme: dark) {

.content table th {
                    color: var(--color--zinc--100)
                }
                  }

.content details {
                background-color: var(--color--zinc--300);
              }

@media (prefers-color-scheme: dark) {

.content details {
                  background-color: var(--color--zinc--600)
              }
                }

.content details {
                border-radius: var(--border-radius--xl);
                padding: var(--space--0) var(--space--4);
}

.content details > summary:hover,
                  .content details > summary:focus-within {
                    background-color: var(--color--zinc--400);
                  }

@media (prefers-color-scheme: dark) {
                    .content details > summary:hover,
                    .content details > summary:focus-within {
                      background-color: var(--color--zinc--500);
                    }
                  }

.content details > summary {
                  padding: var(--space--2) var(--space--4);
                  border-radius: var(--border-radius--xl);
                  margin: var(--space--0) var(--space---4);
                  transition-property: var(--transition-property--colors);
                  transition-duration: var(--transition-duration--150);
                  transition-timing-function: var(
                    --transition-timing-function--in-out
                  );
                  cursor: pointer;
}

.content details > summary::before {
                    content: "\f275";
                    font-family: "bootstrap-icons" !important;
                    font-size: var(--font-size--xs);
                    line-height: var(--line-height--xs);
                    margin-right: var(--space--2);
                  }

.content details[open] {
                  padding-bottom: var(--space--4);
                }

.content details[open] > summary {
                    margin-bottom: var(--space--4);
                  }

.content details[open] > summary::before {
                      content: "\f273";
                    }

.content .katex {
                overflow-x: auto;
              }

.content pre {
                background-color: #ffffff;
              }

@media (prefers-color-scheme: dark) {

.content pre {
                  background-color: #1e1e1e
              }
                }

.content pre {
                padding: var(--space--4);
                border-radius: var(--border-radius--xl);
                overflow-x: auto;
}

.content pre > code {
                  font-size: var(--font-size--xs);
                  line-height: var(--line-height--xs);
                  background-color: transparent;
                  padding: var(--space--0);
                  overflow-wrap: normal;
                }

@media (prefers-color-scheme: dark) {

[css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"][css~="bhsyaiipakawtw"] {
            color: var(--color--zinc--200);
            background-color: var(--color--zinc--900)
        }
          }

/********************************************************************************/

[css~="eohtwsllkawanq"][css~="eohtwsllkawanq"][css~="eohtwsllkawanq"][css~="eohtwsllkawanq"][css~="eohtwsllkawanq"][css~="eohtwsllkawanq"] {

                        padding: var(--space--1) var(--space--2);
                        display: flex;
                        gap: var(--space--2);
                        align-items: flex-start;
                      }

/********************************************************************************/

[css~="cvcjixgkqeesnt"][css~="cvcjixgkqeesnt"][css~="cvcjixgkqeesnt"][css~="cvcjixgkqeesnt"][css~="cvcjixgkqeesnt"][css~="cvcjixgkqeesnt"] {

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }

/********************************************************************************/

[css~="bgbyqvuwppalci"][css~="bgbyqvuwppalci"][css~="bgbyqvuwppalci"][css~="bgbyqvuwppalci"][css~="bgbyqvuwppalci"][css~="bgbyqvuwppalci"] {

                    height: var(--border-width--8);
                    display: flex;
                  }

/********************************************************************************/

[css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"] {

                      background-color: var(--color--accent-color--500);
                    }

@media (prefers-color-scheme: dark) {

[css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"] {
                        background-color: var(--color--accent-color--600);
                    }
                      }

[css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"][css~="ddmyzkgexylxov"] {
                      border-radius: var(--border-radius--none);
                      flex: 1;
}

/********************************************************************************/

[css~="ljrmegwmbqkoy"][css~="ljrmegwmbqkoy"][css~="ljrmegwmbqkoy"][css~="ljrmegwmbqkoy"][css~="ljrmegwmbqkoy"][css~="ljrmegwmbqkoy"] {

                                padding: var(--space--2);
                                display: flex;
                                flex-direction: column;
                                gap: var(--space--4);
                              }

/********************************************************************************/

[css~="dzpzadsooglvjm"][css~="dzpzadsooglvjm"][css~="dzpzadsooglvjm"][css~="dzpzadsooglvjm"][css~="dzpzadsooglvjm"][css~="dzpzadsooglvjm"] {

                                  width: 100%;
                                }

/********************************************************************************/

[css~="donyphugygwjwy"][css~="donyphugygwjwy"][css~="donyphugygwjwy"][css~="donyphugygwjwy"][css~="donyphugygwjwy"][css~="donyphugygwjwy"] {

                                    width: 100%;
                                  }

/********************************************************************************/

[css~="gathqshuuewqrp"][css~="gathqshuuewqrp"][css~="gathqshuuewqrp"][css~="gathqshuuewqrp"][css~="gathqshuuewqrp"][css~="gathqshuuewqrp"] {

                                display: flex;
                                flex-direction: column;
                                gap: var(--space--2);
                              }

/********************************************************************************/

[css~="ffhlfposzuygpo"][css~="ffhlfposzuygpo"][css~="ffhlfposzuygpo"][css~="ffhlfposzuygpo"][css~="ffhlfposzuygpo"][css~="ffhlfposzuygpo"] {

                    justify-content: center;
                    flex-wrap: wrap;
                  }

/********************************************************************************/

[css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"][css~="crghudrvktkfus"] {
                      padding-top: var(--space--1);
                      padding-bottom: var(--space--1);
                      gap: var(--space--4);
                      align-items: center;
                    }

/********************************************************************************/

[css~="clbrbwqrczycle"][css~="clbrbwqrczycle"][css~="clbrbwqrczycle"][css~="clbrbwqrczycle"][css~="clbrbwqrczycle"][css~="clbrbwqrczycle"] {

                      font-size: var(--font-size--sm);
                      line-height: var(--line-height--sm);
                      flex: 1;
                      min-width: var(--width--0);
                    }

/********************************************************************************/

[css~="rkrvkdbvjpixv"][css~="rkrvkdbvjpixv"][css~="rkrvkdbvjpixv"][css~="rkrvkdbvjpixv"][css~="rkrvkdbvjpixv"][css~="rkrvkdbvjpixv"] {

                                max-width: 100%;
                              }

/********************************************************************************/

[css~="euxpoiwrjdfbxg"][css~="euxpoiwrjdfbxg"][css~="euxpoiwrjdfbxg"][css~="euxpoiwrjdfbxg"][css~="euxpoiwrjdfbxg"][css~="euxpoiwrjdfbxg"] {

                                          max-height: var(--space--80);
                                          overflow: auto;
                                          display: flex;
                                          flex-direction: column;
                                          gap: var(--space--2);
                                        }

/********************************************************************************/

[css~="giojhpgjcmkyfz"][css~="giojhpgjcmkyfz"][css~="giojhpgjcmkyfz"][css~="giojhpgjcmkyfz"][css~="giojhpgjcmkyfz"][css~="giojhpgjcmkyfz"] {

                                                    padding: var(--space--0)
                                                      var(--space--2)
                                                      var(--space--1);
                                                    margin-top: var(
                                                      --space---2
                                                    );
                                                  }

/********************************************************************************/

[css~="xzwtywnnysdcq"][css~="xzwtywnnysdcq"][css~="xzwtywnnysdcq"][css~="xzwtywnnysdcq"][css~="xzwtywnnysdcq"][css~="xzwtywnnysdcq"] {

                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                }

/********************************************************************************/

[css~="bazgwijqniylur"][css~="bazgwijqniylur"][css~="bazgwijqniylur"][css~="bazgwijqniylur"][css~="bazgwijqniylur"][css~="bazgwijqniylur"] {

                                  max-width: 100%;
                                }

/********************************************************************************/

[css~="cjdexiqvifccwc"][css~="cjdexiqvifccwc"][css~="cjdexiqvifccwc"][css~="cjdexiqvifccwc"][css~="cjdexiqvifccwc"][css~="cjdexiqvifccwc"] {

                                          max-height: var(--space--80);
                                          overflow: auto;
                                        }

/********************************************************************************/

[css~="eimhzycghprqow"][css~="eimhzycghprqow"][css~="eimhzycghprqow"][css~="eimhzycghprqow"][css~="eimhzycghprqow"][css~="eimhzycghprqow"] {

                                        display: flex;
                                        flex-direction: column;
                                        gap: var(--space--2);
                                      }

/********************************************************************************/

[css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"] {

                                display: grid;
                              }

[css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"][css~="equywklryzkkjs"] > * {
                                  grid-area: 1 / 1;
                                }

/********************************************************************************/

[css~="ftahducftinkry"][css~="ftahducftinkry"][css~="ftahducftinkry"][css~="ftahducftinkry"][css~="ftahducftinkry"][css~="ftahducftinkry"] {

                                  font-size: var(--font-size--xl);
                                  line-height: var(--line-height--xl);
                                  font-weight: var(--font-weight--bold);
                                  padding: var(--space--0) var(--space--1);
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                }

/********************************************************************************/

[css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"] {

                                        background-color: var(
                                          --color--rose--500
                                        );
                                      }

@media (prefers-color-scheme: dark) {

[css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"] {
                                          background-color: var(
                                            --color--rose--600
                                          );
                                      }
                                        }

[css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"][css~="bqkmujenniockf"] {
                                        width: var(--space--1-5);
                                        height: var(--space--1-5);
                                        border-radius: var(
                                          --border-radius--circle
                                        );
                                        justify-self: end;
                                        transform: translateY(50%);
}

/********************************************************************************/

[css~="gabcrpbyidywsw"][css~="gabcrpbyidywsw"][css~="gabcrpbyidywsw"][css~="gabcrpbyidywsw"][css~="gabcrpbyidywsw"][css~="gabcrpbyidywsw"] {

                        padding: var(--space--1);
                        border-radius: var(--border-radius--circle);
                      }

/********************************************************************************/

[css~="gmjrpuoxjbfljq"][css~="gmjrpuoxjbfljq"][css~="gmjrpuoxjbfljq"][css~="gmjrpuoxjbfljq"][css~="gmjrpuoxjbfljq"][css~="gmjrpuoxjbfljq"] {

                                  display: flex;
                                  flex-direction: column;
                                  gap: var(--space--2);
                                }

/********************************************************************************/

[css~="dtilvzjgldvwdw"][css~="dtilvzjgldvwdw"][css~="dtilvzjgldvwdw"][css~="dtilvzjgldvwdw"][css~="dtilvzjgldvwdw"][css~="dtilvzjgldvwdw"] {

                                    padding: var(--space--0) var(--space--2);
                                  }

/********************************************************************************/

[css~="gbecpptnylvuer"][css~="gbecpptnylvuer"][css~="gbecpptnylvuer"][css~="gbecpptnylvuer"][css~="gbecpptnylvuer"][css~="gbecpptnylvuer"] {

                      font-size: var(--font-size--base);
                      line-height: var(--line-height--base);
                      align-items: center;
                    }

/********************************************************************************/

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] {

                      font-size: var(--font-size--xs);
                      line-height: var(--line-height--xs);
                      background-color: var(--color--zinc--100);
                    }

@media (prefers-color-scheme: dark) {

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] {
                        background-color: var(--color--zinc--800)
                    }
                      }

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] {
                      display: flex;
                      flex-direction: column;
}

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] > * {
                        padding: var(--space--0) var(--space--4);
                        border-bottom: var(--border-width--1) solid
                          var(--color--zinc--200);
                      }

@media (prefers-color-scheme: dark) {

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] > * {
                          border-color: var(--color--zinc--700);
                      }
                        }

[css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"][css~="cfvytfscgruev"] > * {
                        display: flex;
}

/********************************************************************************/

[css~="bbqkipskuqsdvj"][css~="bbqkipskuqsdvj"][css~="bbqkipskuqsdvj"][css~="bbqkipskuqsdvj"][css~="bbqkipskuqsdvj"][css~="bbqkipskuqsdvj"] {

              flex: 1;
              overflow: auto;
            }

/********************************************************************************/

[css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"] {

              font-size: var(--font-size--xs);
              line-height: var(--line-height--xs);
              background-color: var(--color--zinc--100);
            }

@media (prefers-color-scheme: dark) {

[css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"] {
                background-color: var(--color--zinc--800);
            }
              }

[css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"] {
              padding: var(--space--0) var(--space--4);
              border-top: var(--border-width--1) solid var(--color--zinc--200);
}

@media (prefers-color-scheme: dark) {

[css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"] {
                border-color: var(--color--zinc--700);
            }
              }

[css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"][css~="enugkmlxeydajc"] {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
}

/********************************************************************************/

[css~="errkqzperzxzfs"][css~="errkqzperzxzfs"][css~="errkqzperzxzfs"][css~="errkqzperzxzfs"][css~="errkqzperzxzfs"][css~="errkqzperzxzfs"] {

                  align-items: center;
                }

/********************************************************************************/

[css~="fuezdctyweywyz"][css~="fuezdctyweywyz"][css~="fuezdctyweywyz"][css~="fuezdctyweywyz"][css~="fuezdctyweywyz"][css~="fuezdctyweywyz"] {

                                font-size: var(--font-size--2xs);
                                line-height: var(--line-height--2xs);
                              }

/********************************************************************************/

[css~="fqslgudkbizxis"][css~="fqslgudkbizxis"][css~="fqslgudkbizxis"][css~="fqslgudkbizxis"][css~="fqslgudkbizxis"][css~="fqslgudkbizxis"] {

                              align-items: center;
                            }

/********************************************************************************/

[css~="gbgftiqihcagnm"][css~="gbgftiqihcagnm"][css~="gbgftiqihcagnm"][css~="gbgftiqihcagnm"][css~="gbgftiqihcagnm"][css~="gbgftiqihcagnm"] {

                            max-height: var(--space--80);
                            overflow: auto;
                          }

/********************************************************************************/

[css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"] {

                              padding: var(--space--2);
                              display: flex;
                              flex-direction: column;
                              gap: var(--space--2);
                            }

[css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"][css~="gadgdtbeuakqbo"] video {
                                max-width: 100%;
                                height: auto;
                                border-radius: var(--border-radius--xl);
                                display: block;
                              }

/********************************************************************************/

[css~="gzezphfxxeciaw"][css~="gzezphfxxeciaw"][css~="gzezphfxxeciaw"][css~="gzezphfxxeciaw"][css~="gzezphfxxeciaw"][css~="gzezphfxxeciaw"] {

                          display: flex;
                          gap: var(--space--2);
                          animation: bounce 1s 3;
                        }

/********************************************************************************/

[css~="fybakygkxftqll"][css~="fybakygkxftqll"][css~="fybakygkxftqll"][css~="fybakygkxftqll"][css~="fybakygkxftqll"][css~="fybakygkxftqll"] {

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
          }

/********************************************************************************/

[css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"] {

              height: var(--border-width--4);
              background-color: var(--color--blue--500);
            }

@media (prefers-color-scheme: dark) {

[css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"] {
                background-color: var(--color--blue--600);
            }
              }

[css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"] {
              border: var(--border-width--1) solid var(--color--blue--600);
              border-top-width: var(--border-width--0);
              border-left-width: var(--border-width--0);
}

@media (prefers-color-scheme: dark) {

[css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"] {
                border-color: var(--color--blue--700);
            }
              }

[css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"][css~="chepznmmfvchmh"] {
              transition-property: width;
              transition-duration: var(--transition-duration--500);
              transition-timing-function: var(
                --transition-timing-function--in-out
              );
}

/********************************************************************************/

[css~="geddnjoqiahlts"][css~="geddnjoqiahlts"][css~="geddnjoqiahlts"][css~="geddnjoqiahlts"][css~="geddnjoqiahlts"][css~="geddnjoqiahlts"] {

            min-width: 100%;
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

/********************************************************************************/

[css~="degxnknpgkdxmc"][css~="degxnknpgkdxmc"][css~="degxnknpgkdxmc"][css~="degxnknpgkdxmc"][css~="degxnknpgkdxmc"][css~="degxnknpgkdxmc"] {

              flex: 1;
              max-width: var(--width--sm);
              margin: var(--space--4);
              display: flex;
              flex-direction: column;
              gap: var(--space--4);
            }

/********************************************************************************/

[css~="fzzjscxugpcjgk"][css~="fzzjscxugpcjgk"][css~="fzzjscxugpcjgk"][css~="fzzjscxugpcjgk"][css~="fzzjscxugpcjgk"][css~="fzzjscxugpcjgk"] {

                display: flex;
                justify-content: center;
              }

/********************************************************************************/

[css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"] {

                background-color: var(--color--zinc--100);
              }

@media (prefers-color-scheme: dark) {

[css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"] {
                  background-color: var(--color--zinc--800);
              }
                }

[css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"][css~="bjhiugckaoswlm"] {
                padding: var(--space--4);
                border-radius: var(--border-radius--lg);
                display: flex;
                flex-direction: column;
                gap: var(--space--4);
}

/********************************************************************************/

[css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"] {

                      color: var(--color--amber--700);
                      background-color: var(--color--amber--100);
                    }

@media (prefers-color-scheme: dark) {

[css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"] {
                        color: var(--color--amber--200);
                        background-color: var(--color--amber--950);
                    }
                      }

[css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"][css~="hbiqytuhdzqdol"] {
                      padding: var(--space--4);
                      border-radius: var(--border-radius--lg);
                      display: flex;
                      gap: var(--space--4);
}

/********************************************************************************/

[css~="oznoisvrqzgsp"][css~="oznoisvrqzgsp"][css~="oznoisvrqzgsp"][css~="oznoisvrqzgsp"][css~="oznoisvrqzgsp"][css~="oznoisvrqzgsp"] {

                        font-size: var(--font-size--4xl);
                        line-height: var(--line-height--4xl);
                      }

/********************************************************************************/

[css~="wauirokxvybru"][css~="wauirokxvybru"][css~="wauirokxvybru"][css~="wauirokxvybru"][css~="wauirokxvybru"][css~="wauirokxvybru"] {

                        display: flex;
                        flex-direction: column;
                        gap: var(--space--4);
                      }

/********************************************************************************/

[css~="eibhehvwhrmumi"][css~="eibhehvwhrmumi"][css~="eibhehvwhrmumi"][css~="eibhehvwhrmumi"][css~="eibhehvwhrmumi"][css~="eibhehvwhrmumi"] {

                            width: 100%;
                          }

/********************************************************************************/

[css~="foiiyahfbltnyl"][css~="foiiyahfbltnyl"][css~="foiiyahfbltnyl"][css~="foiiyahfbltnyl"][css~="foiiyahfbltnyl"][css~="foiiyahfbltnyl"] {

                          width: 100%;
                        }

/********************************************************************************/

[css~="gwptynxkqadswv"][css~="gwptynxkqadswv"][css~="gwptynxkqadswv"][css~="gwptynxkqadswv"][css~="gwptynxkqadswv"][css~="gwptynxkqadswv"] {

            display: flex;
            justify-content: center;
          }

/********************************************************************************/

[css~="dgkxqgbcgiqsbv"][css~="dgkxqgbcgiqsbv"][css~="dgkxqgbcgiqsbv"][css~="dgkxqgbcgiqsbv"][css~="dgkxqgbcgiqsbv"][css~="dgkxqgbcgiqsbv"] {

              flex: 1;
              min-width: var(--width--0);
              max-width: var(--width--prose);
              margin: var(--space--4);
              display: flex;
              flex-direction: column;
              gap: var(--space--4);
            }

/********************************************************************************/

[css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"] {
                    justify-content: center;
                  }

@media (min-width: 700px) {

[css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"][css~="fxbewrhewfvzdj"] {
                      display: none
                  }
                    }

/********************************************************************************/

[css~="ecfmwbzabqstrh"][css~="ecfmwbzabqstrh"][css~="ecfmwbzabqstrh"][css~="ecfmwbzabqstrh"][css~="ecfmwbzabqstrh"][css~="ecfmwbzabqstrh"] {

            padding: var(--space--4);
            display: flex;
            justify-content: center;
            gap: var(--space--8);
          }

/********************************************************************************/

[css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"] {

                    flex: 1;
                    max-width: var(--space--64);
                  }

@media (max-width: 699px) {

[css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"][css~="qdobjgbrisujw"] {
                      display: none
                  }
                    }

/********************************************************************************/

[css~="fbgcuayhcoled"][css~="fbgcuayhcoled"][css~="fbgcuayhcoled"][css~="fbgcuayhcoled"][css~="fbgcuayhcoled"][css~="fbgcuayhcoled"] {

              flex: 1;
              min-width: var(--width--0);
              max-width: var(--width--prose);
              display: flex;
              flex-direction: column;
              gap: var(--space--4);
            }

/********************************************************************************/

[css~="daetnlufyfhbut"][css~="daetnlufyfhbut"][css~="daetnlufyfhbut"][css~="daetnlufyfhbut"][css~="daetnlufyfhbut"][css~="daetnlufyfhbut"] {

        animation: var(--animation--spin);
      }

/********************************************************************************/

[css~="cyguvnifsqysjc"][css~="cyguvnifsqysjc"][css~="cyguvnifsqysjc"][css~="cyguvnifsqysjc"][css~="cyguvnifsqysjc"][css~="cyguvnifsqysjc"] {

          opacity: var(--opacity--25);
        }

/********************************************************************************/

[css~="erbivhuryyuknj"][css~="erbivhuryyuknj"][css~="erbivhuryyuknj"][css~="erbivhuryyuknj"][css~="erbivhuryyuknj"][css~="erbivhuryyuknj"] {

          opacity: var(--opacity--75);
        }

/********************************************************************************/

[css~="motyewdiompkc"][css~="motyewdiompkc"][css~="motyewdiompkc"][css~="motyewdiompkc"][css~="motyewdiompkc"][css~="motyewdiompkc"] {

                                  display: flex;
                                  gap: var(--space--2);
                                  align-items: center;
                                }

/********************************************************************************/

[css~="foloydmfkrzsc"][css~="foloydmfkrzsc"][css~="foloydmfkrzsc"][css~="foloydmfkrzsc"][css~="foloydmfkrzsc"][css~="foloydmfkrzsc"] {

                                  margin-left: var(--space---0-5);
                                }

/********************************************************************************/

[css~="btjqaadjgjejgb"][css~="btjqaadjgjejgb"][css~="btjqaadjgjejgb"][css~="btjqaadjgjejgb"][css~="btjqaadjgjejgb"][css~="btjqaadjgjejgb"] {

                                        font-weight: var(--font-weight--bold);
                                      }

/********************************************************************************/

[css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"] {

                  display: flex;
                  gap: var(--space--2);
                }

@media (max-width: 400px) {

[css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"][css~="cvmyzmlmylypxa"] {
                    flex-direction: column
                }
                  }

/********************************************************************************/

[css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"] {

                  color: var(--color--rose--700);
                  background-color: var(--color--rose--200);
                }

@media (prefers-color-scheme: dark) {

[css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"] {
                    color: var(--color--rose--200);
                    background-color: var(--color--rose--700);
                }
                  }

[css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"][css~="pstypkjlhndxy"] {
                  border-radius: var(--border-radius--circle);
}

/********************************************************************************/

[css~="bqstfyxlkuokze"][css~="bqstfyxlkuokze"][css~="bqstfyxlkuokze"][css~="bqstfyxlkuokze"][css~="bqstfyxlkuokze"][css~="bqstfyxlkuokze"] {

                    width: var(--space--4);
                    height: var(--space--4);
                    vertical-align: var(--space---1);
                  }

/********************************************************************************/

[css~="ejdggiigcxunpa"][css~="ejdggiigcxunpa"][css~="ejdggiigcxunpa"][css~="ejdggiigcxunpa"][css~="ejdggiigcxunpa"][css~="ejdggiigcxunpa"] {

                    width: var(--space--6);
                    height: var(--space--6);
                    vertical-align: var(--space---1-5);
                  }

/********************************************************************************/

[css~="csxyrwribonnmc"][css~="csxyrwribonnmc"][css~="csxyrwribonnmc"][css~="csxyrwribonnmc"][css~="csxyrwribonnmc"][css~="csxyrwribonnmc"] {

                    width: var(--space--32);
                    height: var(--space--32);
                  }

/********************************************************************************/

[css~="bvuybhhhqnfgx"][css~="bvuybhhhqnfgx"][css~="bvuybhhhqnfgx"][css~="bvuybhhhqnfgx"][css~="bvuybhhhqnfgx"][css~="bvuybhhhqnfgx"] {

                      font-size: var(--font-size--xl);
                      line-height: var(--line-height--xl);
                    }

/********************************************************************************/

[css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"] {

                    border-radius: var(--border-radius--circle);
                  }

@media (prefers-color-scheme: dark) {

[css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"][css~="dlikbjqhdxxrg"] {
                      filter: brightness(var(--brightness--90))
                  }
                    }

/********************************************************************************/

[css~="crvcdupbyhqzzq"][css~="crvcdupbyhqzzq"][css~="crvcdupbyhqzzq"][css~="crvcdupbyhqzzq"][css~="crvcdupbyhqzzq"][css~="crvcdupbyhqzzq"] {

                      width: var(--space--4);
                      height: var(--space--4);
                      vertical-align: var(--space---1);
                    }

/********************************************************************************/

[css~="gmgddnastyumoj"][css~="gmgddnastyumoj"][css~="gmgddnastyumoj"][css~="gmgddnastyumoj"][css~="gmgddnastyumoj"][css~="gmgddnastyumoj"] {

                      width: var(--space--6);
                      height: var(--space--6);
                      vertical-align: var(--space---1-5);
                    }

/********************************************************************************/

[css~="esrnolwtikanuw"][css~="esrnolwtikanuw"][css~="esrnolwtikanuw"][css~="esrnolwtikanuw"][css~="esrnolwtikanuw"][css~="esrnolwtikanuw"] {

                      width: var(--space--32);
                      height: var(--space--32);
                    }

/********************************************************************************/

[css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"] {

                    color: var(--color--avatarless-background-color--700);
                    background-color: var(
                      --color--avatarless-background-color--200
                    );
                  }

@media (prefers-color-scheme: dark) {

[css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"] {
                      color: var(--color--avatarless-background-color--200);
                      background-color: var(
                        --color--avatarless-background-color--700
                      );
                  }
                    }

[css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"][css~="tfwectnsresqc"] {
                    border-radius: var(--border-radius--circle);
}

/********************************************************************************/

[css~="cadeewjiwqkfwg"][css~="cadeewjiwqkfwg"][css~="cadeewjiwqkfwg"][css~="cadeewjiwqkfwg"][css~="cadeewjiwqkfwg"][css~="cadeewjiwqkfwg"] {

                      font-size: var(--font-size--2xs);
                      line-height: var(--line-height--2xs);
                      font-weight: var(--font-weight--black);
                      fill: currentColor;
                    }

/********************************************************************************/

[css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"] {

              display: inline-grid;
            }

[css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"][css~="eonzsrxcewpvnz"] > * {
                grid-area: 1 / 1;
                position: relative;
              }

/********************************************************************************/

[css~="gmdgervsbjzmmg"][css~="gmdgervsbjzmmg"][css~="gmdgervsbjzmmg"][css~="gmdgervsbjzmmg"][css~="gmdgervsbjzmmg"][css~="gmdgervsbjzmmg"] {

                vertical-align: var(--space---1);
              }

/********************************************************************************/

[css~="gibydaemwbfree"][css~="gibydaemwbfree"][css~="gibydaemwbfree"][css~="gibydaemwbfree"][css~="gibydaemwbfree"][css~="gibydaemwbfree"] {

                vertical-align: var(--space---1-5);
              }

/********************************************************************************/

[css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"] {

                      background-color: var(--color--green--500);
                    }

@media (prefers-color-scheme: dark) {

[css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"] {
                        background-color: var(--color--green--600);
                    }
                      }

[css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"][css~="klvezqfhxspgy"] {
                      border-radius: var(--border-radius--circle);
                      place-self: end;
}

/********************************************************************************/

[css~="fuxrykixdbnjxm"][css~="fuxrykixdbnjxm"][css~="fuxrykixdbnjxm"][css~="fuxrykixdbnjxm"][css~="fuxrykixdbnjxm"][css~="fuxrykixdbnjxm"] {

                        width: var(--space--1);
                        height: var(--space--1);
                      }

/********************************************************************************/

[css~="onbavpupwunss"][css~="onbavpupwunss"][css~="onbavpupwunss"][css~="onbavpupwunss"][css~="onbavpupwunss"][css~="onbavpupwunss"] {

                        width: var(--space--1-5);
                        height: var(--space--1-5);
                      }

/********************************************************************************/

[css~="dpdncrmenkdpnm"][css~="dpdncrmenkdpnm"][css~="dpdncrmenkdpnm"][css~="dpdncrmenkdpnm"][css~="dpdncrmenkdpnm"][css~="dpdncrmenkdpnm"] {

                        width: var(--space--3);
                        height: var(--space--3);
                        transform: translate(-100%, -100%);
                      }

/********************************************************************************/

[css~="hkdrcujuwkfndt"][css~="hkdrcujuwkfndt"][css~="hkdrcujuwkfndt"][css~="hkdrcujuwkfndt"][css~="hkdrcujuwkfndt"][css~="hkdrcujuwkfndt"] {

                  font-weight: var(--font-weight--bold);
                }

/********************************************************************************/

[css~="bgdrzwloehsgls"][css~="bgdrzwloehsgls"][css~="bgdrzwloehsgls"][css~="bgdrzwloehsgls"][css~="bgdrzwloehsgls"][css~="bgdrzwloehsgls"] {

                    max-height: var(--space--56);
                    padding: var(--space--1) var(--space--2);
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    gap: var(--space--4);
                  }

/********************************************************************************/

[css~="cdpnhswwcqddvc"][css~="cdpnhswwcqddvc"][css~="cdpnhswwcqddvc"][css~="cdpnhswwcqddvc"][css~="cdpnhswwcqddvc"][css~="cdpnhswwcqddvc"] {

                      display: flex;
                      gap: var(--space--4);
                      align-items: center;
                    }

/********************************************************************************/

[css~="cmhyrswnvxrcon"][css~="cmhyrswnvxrcon"][css~="cmhyrswnvxrcon"][css~="cmhyrswnvxrcon"][css~="cmhyrswnvxrcon"][css~="cmhyrswnvxrcon"] {

                        padding-top: var(--space--0-5);
                        display: flex;
                        flex-direction: column;
                        gap: var(--space--2);
                      }

/********************************************************************************/

[css~="dqynmskubpoodl"][css~="dqynmskubpoodl"][css~="dqynmskubpoodl"][css~="dqynmskubpoodl"][css~="dqynmskubpoodl"][css~="dqynmskubpoodl"] {

                                    margin-right: var(--space--2);
                                  }

/********************************************************************************/

[css~="cfevrdsahrbmbf"][css~="cfevrdsahrbmbf"][css~="cfevrdsahrbmbf"][css~="cfevrdsahrbmbf"][css~="cfevrdsahrbmbf"][css~="cfevrdsahrbmbf"] {

                                    font-size: var(--font-size--xs);
                                    line-height: var(--line-height--xs);
                                    display: inline-flex;
                                  }

/********************************************************************************/

[css~="foomnddztamsnh"][css~="foomnddztamsnh"][css~="foomnddztamsnh"][css~="foomnddztamsnh"][css~="foomnddztamsnh"][css~="foomnddztamsnh"] {

                                  font-size: var(--font-size--xs);
                                  line-height: var(--line-height--xs);
                                }

/********************************************************************************/

[css~="eqsuirjjsptsoy"][css~="eqsuirjjsptsoy"][css~="eqsuirjjsptsoy"][css~="eqsuirjjsptsoy"][css~="eqsuirjjsptsoy"][css~="eqsuirjjsptsoy"] {

                                  font-size: var(--font-size--xs);
                                  line-height: var(--line-height--xs);
                                  display: flex;
                                  gap: var(--space--2);
                                }

/********************************************************************************/

[css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"] {

            color: var(--color--violet--700);
            background-color: var(--color--violet--200);
          }

@media (prefers-color-scheme: dark) {

[css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"] {
              color: var(--color--violet--200);
              background-color: var(--color--violet--700);
          }
            }

[css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"][css~="dfiyndsvqlwcid"] {
            border-radius: var(--border-radius--circle);
}

/********************************************************************************/

[css~="epdlxpecypwbbw"][css~="epdlxpecypwbbw"][css~="epdlxpecypwbbw"][css~="epdlxpecypwbbw"][css~="epdlxpecypwbbw"][css~="epdlxpecypwbbw"] {

              width: var(--space--4);
              height: var(--space--4);
              vertical-align: var(--space---1);
            }

/********************************************************************************/

[css~="dqaweseknqnkoy"][css~="dqaweseknqnkoy"][css~="dqaweseknqnkoy"][css~="dqaweseknqnkoy"][css~="dqaweseknqnkoy"][css~="dqaweseknqnkoy"] {

              width: var(--space--6);
              height: var(--space--6);
              vertical-align: var(--space---1-5);
            }

/********************************************************************************/

[css~="ekbarhnppienkr"][css~="ekbarhnppienkr"][css~="ekbarhnppienkr"][css~="ekbarhnppienkr"][css~="ekbarhnppienkr"][css~="ekbarhnppienkr"] {

              width: var(--space--32);
              height: var(--space--32);
            }

/********************************************************************************/

[css~="ctlkpvvuzzuuer"][css~="ctlkpvvuzzuuer"][css~="ctlkpvvuzzuuer"][css~="ctlkpvvuzzuuer"][css~="ctlkpvvuzzuuer"][css~="ctlkpvvuzzuuer"] {

                font-size: var(--font-size--xl);
                line-height: var(--line-height--xl);
              }

/********************************************************************************/

[css~="elfmsefqtwjvna"][css~="elfmsefqtwjvna"][css~="elfmsefqtwjvna"][css~="elfmsefqtwjvna"][css~="elfmsefqtwjvna"][css~="elfmsefqtwjvna"] {

                font-weight: var(--font-weight--bold);
              }

/********************************************************************************/

[css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"] {

                display: flex;
                gap: var(--space--4);
              }

@media (max-width: 400px) {

[css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"][css~="imcbsfkzuczfm"] {
                  flex-direction: column
              }
                }

/********************************************************************************/

[css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"] {

                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

[css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"].drag {
                    opacity: var(--opacity--50);
                  }

[css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"] > * {
                    width: var(--space--32);
                    height: var(--space--32);
                    display: grid;
                  }

[css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"] > * > * {
                      grid-area: 1 / 1;
                      position: relative;
                    }

[css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"][css~="hkplcvyyygydxb"] > * > *:first-child {
                        padding: var(--space--2);
                        margin: var(--space---2);
                        border-radius: var(--border-radius--circle);
                        align-items: center;
                      }

/********************************************************************************/

[css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"] {

                        width: var(--space--4);
                        height: var(--space--4);
                        transform: scale(8);
                      }

[css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"][css~="bqjjinzanwjooa"] svg {
                          vertical-align: var(--space--0);
                        }

/********************************************************************************/

[css~="diwgrgsocruyfs"][css~="diwgrgsocruyfs"][css~="diwgrgsocruyfs"][css~="diwgrgsocruyfs"][css~="diwgrgsocruyfs"][css~="diwgrgsocruyfs"] {

                        width: 100%;
                        height: 100%;
                        border-radius: var(--border-radius--circle);
                      }

/********************************************************************************/

[css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"][css~="coeitawlwgzywu"] {
                        font-size: var(--font-size--xs);
                        line-height: var(--line-height--xs);
                        place-self: end;
                        width: var(--font-size--2xl);
                        height: var(--font-size--2xl);
                        padding: var(--space--0);
                        border-radius: var(--border-radius--circle);
                        transform: translate(-20%, -20%);
                        align-items: center;
                      }

/********************************************************************************/

[css~="gyilyreenfbrsi"][css~="gyilyreenfbrsi"][css~="gyilyreenfbrsi"][css~="gyilyreenfbrsi"][css~="gyilyreenfbrsi"][css~="gyilyreenfbrsi"] {

                              display: flex;
                              gap: var(--space--2);
                            }

/********************************************************************************/

[css~="evaoplwtrqmzmc"][css~="evaoplwtrqmzmc"][css~="evaoplwtrqmzmc"][css~="evaoplwtrqmzmc"][css~="evaoplwtrqmzmc"][css~="evaoplwtrqmzmc"] {

                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  gap: var(--space--4);
                }

/********************************************************************************/

[css~="bvzpfegkzqjhaw"][css~="bvzpfegkzqjhaw"][css~="bvzpfegkzqjhaw"][css~="bvzpfegkzqjhaw"][css~="bvzpfegkzqjhaw"][css~="bvzpfegkzqjhaw"] {

                    display: flex;
                    flex-direction: column;
                    gap: var(--space--1);
                  }

/********************************************************************************/

[css~="glwbuxhcxfwofv"][css~="glwbuxhcxfwofv"][css~="glwbuxhcxfwofv"][css~="glwbuxhcxfwofv"][css~="glwbuxhcxfwofv"][css~="glwbuxhcxfwofv"] {

                    display: flex;
                    flex-direction: column;
                    gap: var(--space--4);
                  }

/********************************************************************************/

[css~="ywirizohmvvam"][css~="ywirizohmvvam"][css~="ywirizohmvvam"][css~="ywirizohmvvam"][css~="ywirizohmvvam"][css~="ywirizohmvvam"] {

                  margin-left: var(--space--10);
                  display: flex;
                  flex-wrap: wrap;
                  -moz-column-gap: var(--space--8);
                       column-gap: var(--space--8);
                  row-gap: var(--space--1);
                }

/********************************************************************************/

[css~="bfptpajkojmlqz"][css~="bfptpajkojmlqz"][css~="bfptpajkojmlqz"][css~="bfptpajkojmlqz"][css~="bfptpajkojmlqz"][css~="bfptpajkojmlqz"] {

                                  padding: var(--space--2);
                                  display: flex;
                                  flex-direction: column;
                                  gap: var(--space--4);
                                }

/********************************************************************************/

[css~="gevgrefeegmigc"][css~="gevgrefeegmigc"][css~="gevgrefeegmigc"][css~="gevgrefeegmigc"][css~="gevgrefeegmigc"][css~="gevgrefeegmigc"] {

                                      font-weight: var(--font-weight--bold);
                                    }

